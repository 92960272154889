import React, { useState, useEffect, useCallback, Suspense } from 'react';
import { Slider } from '@/components/ui/slider';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { baseUrl } from '@/services/api-config';
import { StepStatus } from '@/utils/Interfaces';
import { useBudgetContext } from '@/contexts/OnboardingContext';
import { useSuggestions } from '@/contexts/SuggestionsContext';
import AISuggestion from '@/components/AiSuggestion';
import { Loader2 } from 'lucide-react';

// Initialize Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_DETAILS!);

interface PaymentFormProps {
  onPaymentMethodStored: (paymentMethodId: string) => void;
}

const appearance = {
  theme: 'stripe' as const,
  variables: {
    colorPrimary: '#0066cc',
    colorBackground: '#ffffff',
    colorText: '#30313d',
  }
};

interface SetupIntentResponse {
  client_secret: string;
  setup_intent_id: string;
  status: string;
  payment_method_id: string | null;
}

// Loading skeleton component for payment form
const PaymentFormSkeleton = () => (
  <div className="animate-pulse">
    <div className="w-full h-12 bg-gray-200 rounded mb-4"></div>
    <div className="w-full h-12 bg-gray-200 rounded mb-4"></div>
    <div className="w-3/4 h-12 bg-gray-200 rounded mb-4"></div>
    <div className="w-full h-10 bg-gray-300 rounded mt-4"></div>
    <div className="text-center text-gray-500 mt-2 text-sm">Loading payment form...</div>
  </div>
);

// Memoize the PaymentForm component to prevent unnecessary rerenders
const PaymentForm: React.FC<PaymentFormProps> = React.memo(({ onPaymentMethodStored }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [formReady, setFormReady] = useState(false);

  // Set form ready when stripe and elements are ready
  useEffect(() => {
    if (stripe && elements) {
      setFormReady(true);
    }
  }, [stripe, elements]);

  // Memoize this function to prevent recreating on every render
  const createSetupIntent = useCallback(async () => {
    const response = await fetch(`${baseUrl}/api/create-setup-intent`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to create setup intent');
    }

    return await response.json() as SetupIntentResponse;
  }, []);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    setLoading(true);
    try {
      // First validate and collect the payment method details
      const { error: elementsError } = await elements.submit();
      
      if (elementsError) {
        setError(elementsError.message || 'Something went wrong');
        return;
      }

      // Create a payment method from the card details
      const { error: createError, paymentMethod } = await stripe.createPaymentMethod({
        elements,
        params: {
          type: 'card',
        },
      });

      if (createError) {
        setError(createError.message || 'Failed to create payment method');
        return;
      }

      if (!paymentMethod) {
        setError('Failed to create payment method');
        return;
      }

      // Create the SetupIntent using your backend
      const setupIntent = await createSetupIntent();
      
      // Store in localStorage
      const paymentData = localStorage.getItem('payment_setup_data');
      const storedData = paymentData ? JSON.parse(paymentData) : {};
      
      const updatedData = {
        ...storedData,
        setupIntentId: setupIntent.setup_intent_id,
        clientSecret: setupIntent.client_secret,
        paymentMethodId: paymentMethod.id,
        validationComplete: true
      };
      
      localStorage.setItem('payment_setup_data', JSON.stringify(updatedData));
      
      // Notify parent component with the payment method ID
      onPaymentMethodStored(paymentMethod.id);
      
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to save payment details');
      console.error('Payment setup error:', err);
    } finally {
      setLoading(false);
    }
  };

  // Render a loading skeleton when form isn't ready yet
  if (!formReady) {
    return <PaymentFormSkeleton />;
  }

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      {error && <div className="text-red-500 mt-2">{error}</div>}
      <button
        type="submit"
        disabled={!stripe || loading}
        className="mt-4 w-full bg-blue-600 text-white py-2 px-4 rounded disabled:opacity-50"
      >
        {loading ? 'Saving...' : 'Save payment details'}
      </button>
    </form>
  );
});

PaymentForm.displayName = 'PaymentForm';

// Stripe Elements wrapper component with loading state handling
const StripePaymentWrapper = ({ onPaymentMethodStored }: { onPaymentMethodStored: (id: string) => void }) => {
  const [isStripeLoading, setIsStripeLoading] = useState(true);

  useEffect(() => {
    // Set a timeout to detect if Stripe is taking too long
    const timeout = setTimeout(() => {
      setIsStripeLoading(false); // Force end loading state after timeout
    }, 5000);
    
    return () => clearTimeout(timeout);
  }, []);

  // Clean-up loading state when Stripe is ready
  useEffect(() => {
    if (stripePromise) {
      stripePromise.then(() => {
        // Give a little extra time for elements to initialize
        setTimeout(() => {
          setIsStripeLoading(false);
        }, 500);
      });
    }
  }, []);

  return (
    <div className="mt-6 border border-gray-200 rounded-md p-4">
      <h3 className="text-lg font-medium mb-3">Payment Method</h3>
      
      {isStripeLoading ? (
        <PaymentFormSkeleton />
      ) : (
        <Elements
          stripe={stripePromise}
          options={{ 
            mode: 'setup',
            appearance,
            paymentMethodTypes: ['card'],
            paymentMethodCreation: 'manual'
          }}>
          <PaymentForm onPaymentMethodStored={onPaymentMethodStored} />
        </Elements>
      )}
    </div>
  );
};

// Content skeleton while page data is loading
const PageLoadingSkeleton = () => (
  <div className="flex flex-col md:flex-row gap-8 p-6 max-w-6xl mx-auto animate-pulse">
    <div className="flex-1">
      <div className="h-8 w-48 bg-gray-200 rounded mb-4"></div>
      <div className="h-6 w-full bg-gray-200 rounded mb-4"></div>
      <div className="h-4 w-3/4 bg-gray-200 rounded mb-2"></div>
      <div className="h-4 w-4/5 bg-gray-200 rounded mb-4"></div>
      
      <div className="space-y-2 mt-4">
        {[1, 2, 3, 4, 5, 6].map(i => (
          <div key={i} className="h-5 w-full bg-gray-200 rounded"></div>
        ))}
      </div>
    </div>
    
    <div className="flex-1">
      <div className="bg-white p-6 rounded-lg shadow">
        <div className="h-8 w-56 bg-gray-200 rounded mb-4"></div>
        <div className="h-6 w-full bg-gray-200 rounded mb-4"></div>
        <div className="h-10 w-full bg-gray-200 rounded mb-4"></div>
        <div className="h-8 w-32 bg-gray-200 rounded mb-6"></div>
        
        <div className="space-y-2 mb-6">
          <div className="h-5 w-full bg-gray-200 rounded"></div>
          <div className="h-5 w-full bg-gray-200 rounded"></div>
        </div>
        
        <div className="h-12 w-full bg-gray-300 rounded-md"></div>
      </div>
    </div>
  </div>
);

interface PageProps {
  url: string;
  onStepComplete?: (status: StepStatus) => void;
}

const SetBudgetPage: React.FC<PageProps> = ({ url, onStepComplete }) => {
  const MINIMUM_BUDGET = 10;
  // Use our selective context hook instead of the full context
  const { budgetData, updateBudgetData } = useBudgetContext();
  
  // Get suggestion data from suggestions context
  const {
    hasSuggestions,
    isFetching: isFetchingSuggestions,
    getBudgetSuggestion,
    markBudgetSuggestionApplied,
  } = useSuggestions();
  
  const [pageLoading, setPageLoading] = useState(true);
  const [weeklyInvoice, setWeeklyInvoice] = useState(0);
  const [budgetError, setBudgetError] = useState<string | null>(null);
  const [suggestedBudget, setSuggestedBudget] = useState<number | null>(null);
  const [showSuggestion, setShowSuggestion] = useState(hasSuggestions);
  const [isSuggestionVisible, setIsSuggestionVisible] = useState(false);

  // Handle initial page loading state
  useEffect(() => {
    // Show loading state while we fetch initial data
    const timer = setTimeout(() => {
      setPageLoading(false);
    }, 1000);
    
    return () => clearTimeout(timer);
  }, []);

  // Initialize state from context or defaults
  useEffect(() => {
    // Use budget from context if available
    if (budgetData.dailyBudget >= MINIMUM_BUDGET) {
      // Update weekly invoice based on the budget in context
      setWeeklyInvoice(budgetData.dailyBudget * 0.1 * 7);
    } else {
      // If no valid budget in context, set to minimum
      updateBudgetData({ dailyBudget: MINIMUM_BUDGET });
    }
  }, [budgetData.dailyBudget, updateBudgetData]);

  useEffect(() => {
    console.log(`Suggestion: [${hasSuggestions}] Budget: [${suggestedBudget}]`);
    if (hasSuggestions && suggestedBudget) {
      setIsSuggestionVisible(true);
    }
  }, [hasSuggestions, suggestedBudget]);

  // Get and auto-apply the suggested budget from context
  useEffect(() => {
    const budgetSuggestion = getBudgetSuggestion();
    
    if (budgetSuggestion) {
      setSuggestedBudget(Math.max(budgetSuggestion, MINIMUM_BUDGET));
      updateBudgetData({ dailyBudget: budgetSuggestion });
      markBudgetSuggestionApplied();
    }
  }, [getBudgetSuggestion, updateBudgetData, markBudgetSuggestionApplied]);

  // Update completion status
  useEffect(() => {
    if (!onStepComplete) return;

    const isBudgetValid = budgetData.dailyBudget >= MINIMUM_BUDGET;
    const isSetupIdValid = !!budgetData.paymentMethodId;

    onStepComplete({
      isComplete: isBudgetValid && isSetupIdValid,
      message: !isSetupIdValid
        ? 'Please fix payment method'
        : !isBudgetValid
          ? 'Please set a valid daily budget'
          : undefined
    });
  }, [budgetData, onStepComplete, MINIMUM_BUDGET]);

  // Update weekly invoice when budget changes
  useEffect(() => {
    const multiplyFee = budgetData.dailyBudget * 0.1;
    setWeeklyInvoice(multiplyFee * 7);
  }, [budgetData.dailyBudget]);

  // Memoize the handler functions
  const handlePaymentMethodStored = useCallback((paymentMethodId: string) => {
    // Update context with new payment method
    updateBudgetData({ 
      paymentMethodId,
      setupIntentId: paymentMethodId // Using paymentMethodId as setupIntentId for now
    });
  }, [updateBudgetData]);

  const handleSetDailyBudget = useCallback((item: number) => {
    const newBudget = Math.max(MINIMUM_BUDGET, item); // Ensure minimum budget
    
    // Update context
    updateBudgetData({ dailyBudget: newBudget });
  }, [updateBudgetData, MINIMUM_BUDGET]);

  // These functions are still required by the AISuggestion component
  // but we make them no-ops since we auto-apply the budget
  const handleApplySuggestion = useCallback(() => {
    // This is now a no-op as we auto-apply
    setShowSuggestion(false);
  }, []);

  const handleDismissSuggestion = useCallback(() => {
    // Just hide the suggestion
    setShowSuggestion(false);
  }, []);

  // Render content for the suggestion component
  const renderSuggestionContent = useCallback((suggestion: any) => {
    return (
      <div className="mb-3">
        <span className="text-sm text-gray-700">We recommend a minimum budget of </span>
        <span className="font-medium">${suggestion.suggestedBudget?.toFixed(2)} per day</span>
        <span className="text-sm text-gray-700"> and have set it for you.</span>
      </div>
    );
  }, []);

  // Show loading skeleton while page data is loading
  if (pageLoading) {
    return <PageLoadingSkeleton />;
  }

  return (
    <div className="flex flex-col md:flex-row gap-8 p-6 max-w-6xl mx-auto">
      {/* Left Column */}
      <div className="flex-1 flex flex-col justify-between">
        {/* Top content area */}
        <div>
          {/* Logo and Header */}
          <div className="flex items-center gap-2 mb-4">
            <div className="bg-white text-black p-2 rounded">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M18 8h1a4 4 0 0 1 0 8h-1"></path>
                <path d="M2 8h16v9a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4V8z"></path>
                <line x1="6" y1="1" x2="6" y2="4"></line>
                <line x1="10" y1="1" x2="10" y2="4"></line>
                <line x1="14" y1="1" x2="14" y2="4"></line>
              </svg>
            </div>
            <h1 className="text-2xl font-bold">Multiply</h1>
          </div>
    
          {/* Pricing Info */}
          <h2 className="text-xl font-semibold mb-2">How our pricing works</h2>
          <p className="text-gray-600 mb-4">
            Google will bill you directly for your ad spend. Each week,
            we'll charge a simple 10% fee based on your spend. No
            surprises, just great results!
          </p>
    
          {/* Feature List */}
          <ul className="space-y-2 mb-6">
            {[
              'Google Ads campaign run by Multiply',
              'No hidden fees',
              'Automatic ad optimizations',
              'AI-generated ad content',
              'Customized dashboard',
              '24/7 support'
            ].map((feature, index) => (
              <li key={index} className="flex items-center gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                </svg>
                {feature}
              </li>
            ))}
          </ul>

          {isSuggestionVisible && (
          <div className="mb-4">
            <AISuggestion
              title="AI Budget Recommendation"
              isLoading={isFetchingSuggestions}
              suggestion={{ suggestedBudget }}
              renderContent={renderSuggestionContent}
              onApply={handleApplySuggestion}
              onDismiss={handleDismissSuggestion}
              description="This recommendation is based on your business profile."
              showButtons={false}
              autoApply={true}
            />
          </div>
          )}
        </div>
      </div>
  
      {/* Right Column */}
      <div className="flex-1">
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-2">Set your daily ad budget</h2>
  
          {/* Error Alert */}
          {budgetError && (
            <div className="mb-4 p-4 bg-red-50 border border-red-200 rounded-md">
              <p className="text-red-700">{budgetError}</p>
            </div>
          )}
  
          <p className="text-gray-600 mb-4">You can adjust your budget at any time.</p>
  
          {/* Budget Slider */}
          <Slider
            min={10}
            max={100}
            step={1}
            value={[budgetData.dailyBudget]}
            onValueChange={(value) => handleSetDailyBudget(value[0])}
            className="mb-4"
          />
  
          {/* Slider Labels */}
          <div className="flex justify-between text-sm text-gray-600 mb-6">
            <span>$10</span>
            <span>$50</span>
            <span>$100</span>
          </div>
  
          {/* Budget Display */}
          <div className="mb-4">
            <span className="text-2xl font-bold">${budgetData.dailyBudget.toFixed(2)}/day</span>
            <span className="text-sm text-gray-600 ml-2">USD</span>
          </div>
  
          {/* Cost Breakdown */}
          <div className="space-y-2 mb-6">
            <div className="flex justify-between">
              <span className="text-gray-600">Google Ads (Billed separately)</span>
              <span>${budgetData.dailyBudget.toFixed(2)}/day</span>
            </div>
            <div className="flex justify-between">
              <span className="text-gray-600">Multiply fees (10%)</span>
              <span>${(budgetData.dailyBudget * 0.1).toFixed(2)}/day</span>
            </div>
          </div>
  
          {/* Weekly Total */}
          <div className="flex justify-between font-semibold mb-6">
            <span>Estimated weekly invoice</span>
            <span>${weeklyInvoice.toFixed(2)}</span>
          </div>
  
          {/* Payment Form with proper loading state management */}
          <StripePaymentWrapper onPaymentMethodStored={handlePaymentMethodStored} />
  
          {/* Success Message */}
          {budgetData.paymentMethodId && (
            <div className="mt-4 p-3 bg-green-50 text-green-700 rounded">
              Payment method saved successfully! You can continue with the next step.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SetBudgetPage;