import React, { useEffect, useState } from 'react';
import { Card } from '@/components/ui/card';
import { cn } from "@/utils/utils";
import { useCampaign } from '@/contexts/CampaignContext';
import { baseUrl } from '@/services/api-config';
import { useAuth } from '@/utils/AuthProvider';
import { 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  Tooltip, 
  ResponsiveContainer,
  Cell 
} from 'recharts';

// Type definitions

const SearchTermsComponent: React.FC = () => {
  const { 
    activeCampaign,
    searchTerms,
    searchTermsLoading,
    fetchSearchTermsIfNeeded
} = useCampaign();
  const [error, setError] = useState<string | null>(null);
  const [sortBy, setSortBy] = useState<string>('clicks');
  const [sortDir, setSortDir] = useState<'asc' | 'desc'>('desc');

  

  useEffect(() => {
    fetchSearchTermsIfNeeded();
  }, [activeCampaign?.id]);

  const handleSort = (column: string) => {
    if (sortBy === column) {
      setSortDir(sortDir === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortDir('desc'); // Default to descending when changing column
    }
  };

  const getSortedTerms = () => {
    if (!searchTerms?.items) return [];
    
    return [...searchTerms.items].sort((a: any, b: any) => {
      if (sortDir === 'asc') {
        return a[sortBy] > b[sortBy] ? 1 : -1;
      }
      return a[sortBy] < b[sortBy] ? 1 : -1;
    });
  };

  // Prepare chart data
  const chartData = searchTerms?.items
    ? searchTerms.items
        .slice(0, 5)
        .map(item => ({ 
          name: item.search_term.length > 15 
            ? item.search_term.substring(0, 15) + '...' 
            : item.search_term,
          clicks: item.clicks
        }))
    : [];

  if (searchTermsLoading) {
    return (
      <Card className="p-6">
        <h3 className="text-lg font-semibold mb-2">Ad search terms</h3>
        <div className="space-y-4">
          <div className="h-8 w-full bg-gray-200 animate-pulse rounded"></div>
          <div className="h-24 w-full bg-gray-200 animate-pulse rounded"></div>
          <div className="h-36 w-full bg-gray-200 animate-pulse rounded"></div>
        </div>
      </Card>
    );
  }

  if (error || !searchTerms?.items?.length) {
    return (
      <Card className="p-6">
        <h3 className="text-lg font-semibold mb-2">Ad search terms</h3>
        <div className="border p-4 rounded min-h-[200px] flex items-center justify-center">
          <div className="text-center space-y-2">
            <p className="text-gray-500">No search terms data available yet</p>
            <p className="text-sm text-gray-400">Search terms will appear here once your ads start receiving impressions</p>
          </div>
        </div>
      </Card>
    );
  }

  const sortedTerms = getSortedTerms();
  
  return (
    <Card className="p-6">
      <h3 className="text-lg font-semibold mb-4">Ad search terms</h3>
      
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
        <div className="bg-gray-50 rounded-lg p-4">
          <h4 className="text-sm font-medium text-gray-500 mb-2">Top search terms by clicks</h4>
          <div className="h-48">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={chartData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                <XAxis dataKey="name" fontSize={12} />
                <YAxis fontSize={12} />
                <Tooltip />
                <Bar dataKey="clicks" fill="#4f46e5" radius={[4, 4, 0, 0]}>
                  {chartData.map((_, index) => (
                    <Cell key={`cell-${index}`} fill={`rgba(79, 70, 229, ${1 - (index * 0.15)})`} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        
        <div className="bg-gray-50 rounded-lg p-4">
          <h4 className="text-sm font-medium text-gray-500 mb-2">Summary</h4>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-sm text-gray-500">Total clicks</p>
              <p className="text-lg font-semibold">{searchTerms.total_clicks}</p>
            </div>
            <div>
              <p className="text-sm text-gray-500">Total impressions</p>
              <p className="text-lg font-semibold">{searchTerms.total_impressions}</p>
            </div>
            <div>
              <p className="text-sm text-gray-500">Avg. CTR</p>
              <p className="text-lg font-semibold">{(searchTerms.average_ctr * 100).toFixed(2)}%</p>
            </div>
            <div>
              <p className="text-sm text-gray-500">Total conversions</p>
              <p className="text-lg font-semibold">{searchTerms.total_conversions}</p>
            </div>
            <div className="col-span-2">
              <p className="text-sm text-gray-500">Total cost</p>
              <p className="text-lg font-semibold">${searchTerms.total_cost.toFixed(2)}</p>
            </div>
          </div>
        </div>
      </div>
      
      <div className="overflow-x-auto">
        <table className="min-w-full border-collapse">
          <thead>
            <tr className="bg-gray-50">
              <th 
                className={cn(
                  "px-3 py-2 text-left text-xs font-medium text-gray-500 cursor-pointer",
                  sortBy === 'search_term' && 'text-blue-600'
                )}
                onClick={() => handleSort('search_term')}
              >
                Search Term {sortBy === 'search_term' && (sortDir === 'asc' ? '↑' : '↓')}
              </th>
              <th 
                className={cn(
                  "px-3 py-2 text-left text-xs font-medium text-gray-500 cursor-pointer",
                  sortBy === 'clicks' && 'text-blue-600'
                )}
                onClick={() => handleSort('clicks')}
              >
                Clicks {sortBy === 'clicks' && (sortDir === 'asc' ? '↑' : '↓')}
              </th>
              <th 
                className={cn(
                  "px-3 py-2 text-left text-xs font-medium text-gray-500 cursor-pointer",
                  sortBy === 'impressions' && 'text-blue-600'
                )}
                onClick={() => handleSort('impressions')}
              >
                Impr. {sortBy === 'impressions' && (sortDir === 'asc' ? '↑' : '↓')}
              </th>
              <th 
                className={cn(
                  "px-3 py-2 text-left text-xs font-medium text-gray-500 cursor-pointer",
                  sortBy === 'ctr' && 'text-blue-600'
                )}
                onClick={() => handleSort('ctr')}
              >
                CTR {sortBy === 'ctr' && (sortDir === 'asc' ? '↑' : '↓')}
              </th>
              <th 
                className={cn(
                  "px-3 py-2 text-left text-xs font-medium text-gray-500 cursor-pointer",
                  sortBy === 'conversion_rate' && 'text-blue-600'
                )}
                onClick={() => handleSort('conversion_rate')}
              >
                Conv. Rate {sortBy === 'conversion_rate' && (sortDir === 'asc' ? '↑' : '↓')}
              </th>
              <th 
                className={cn(
                  "px-3 py-2 text-left text-xs font-medium text-gray-500 cursor-pointer",
                  sortBy === 'cost' && 'text-blue-600'
                )}
                onClick={() => handleSort('cost')}
              >
                Cost {sortBy === 'cost' && (sortDir === 'asc' ? '↑' : '↓')}
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedTerms.map((term, index) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                <td className="px-3 py-2 text-sm">{term.search_term}</td>
                <td className="px-3 py-2 text-sm">{term.clicks}</td>
                <td className="px-3 py-2 text-sm">{term.impressions}</td>
                <td className="px-3 py-2 text-sm">{(term.ctr * 100).toFixed(2)}%</td>
                <td className="px-3 py-2 text-sm">{(term.conversion_rate * 100).toFixed(2)}%</td>
                <td className="px-3 py-2 text-sm">${term.cost.toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default SearchTermsComponent;