const TermsOfService = () => {
  return (
    <div className="prose max-w-none">
      <h1 className="text-2xl font-bold mb-4">Terms of Use</h1>
      <p className="text-sm text-gray-600 mb-4"><strong>Effective Date:</strong> 03/04/2025</p>
      
      <p className="mb-4">
        These Terms of Use ("Terms") govern your access to and use of the Multiply website (https://trymultiply.com/) and services ("Services"). 
        By using our Services, you agree to comply with and be bound by these Terms. If you do not agree with these Terms, please do not use the Services.
      </p>

      <h2 className="text-xl font-bold mt-6 mb-2">1. Description of Services</h2>
      <p className="mb-4">
        Multiply is an AI-powered digital marketing tool that helps users manage their advertising campaigns. 
        Our Services include the management of Google Ads campaigns, for which users are responsible for setting up a Google Ads account and billing directly with Google.
      </p>
      <p className="mb-4">
        Our Services utilize artificial intelligence to generate advertising creative content and keywords based on the website data you provide. This content is cached to improve system performance and service delivery.
      </p>

      <h2 className="text-xl font-bold mt-6 mb-2">2. Eligibility</h2>
      <p className="mb-4">
        You must be at least 18 years old to create an account and use our Services. 
        By creating an account, you confirm that you meet this age requirement.
      </p>

      <h2 className="text-xl font-bold mt-6 mb-2">3. Account Registration and OAuth Authentication</h2>
      <p className="mb-4">To use Multiply, users must:</p>
      <ul className="list-disc ml-6 mb-4">
        <li className="mb-2">Register an account on our platform.</li>
        <li className="mb-2">Connect their existing Google Ads account, which includes having billing set up directly with Google.</li>
        <li className="mb-2">You are solely responsible for the security of your login credentials and account information.</li>
      </ul>
      <p className="mb-4">
        When you connect your Google account through OAuth authentication:
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li className="mb-2">You authorize Multiply to access specific information from your Google account as outlined during the authorization process.</li>
        <li className="mb-2">Multiply will securely store provider tokens and refresh tokens in an encrypted state on our servers.</li>
        <li className="mb-2">These tokens are used solely for billing purposes and to fetch/update data to be displayed when you log in.</li>
        <li className="mb-2">Tokens are retained indefinitely to maintain billing functionality and service continuity.</li>
        <li className="mb-2">You may revoke access at any time through your Google account settings.</li>
      </ul>

      <h2 className="text-xl font-bold mt-6 mb-2">4. Data Processing and AI Content Generation</h2>
      <p className="mb-4">
        By using our Services, you acknowledge and agree that:
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li className="mb-2">We use third-party AI models to generate ad creative and keywords for websites you provide.</li>
        <li className="mb-2">Generated content is cached on our servers to improve performance.</li>
        <li className="mb-2">You are responsible for reviewing all AI-generated content before publishing.</li>
        <li className="mb-2">We do not guarantee specific results from using AI-generated content.</li>
        <li className="mb-2">You grant us permission to process website data you submit for the purpose of generating advertising content.</li>
      </ul>

      <h2 className="text-xl font-bold mt-6 mb-2">5. Fees and Payments</h2>
      <p className="mb-2">
        Multiply charges a 10% fee on the total ad spend managed through our platform. 
        Google charges you separately for the ad spend itself, for which you are responsible for setting up payment with Google.
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li className="mb-2">The 10% management fee is charged weekly through <strong>Stripe</strong>. 
        During account setup, you will be required to provide a valid payment method.</li>
        <li className="mb-2">You authorize us to charge this 10% fee directly to your provided payment method.</li>
        <li className="mb-2">The OAuth tokens we store are used in part to calculate billing based on your ad spend.</li>
      </ul>

      <h2 className="text-xl font-bold mt-6 mb-2">6. Intellectual Property</h2>
      <p className="mb-4">
        Multiply retains all intellectual property rights in any content, tools, or resources generated as part of the ad management process. 
        You may not claim ownership of any of the IP generated by Multiply's Services.
      </p>
      <p className="mb-4">
        While you retain ownership of your website data and the final advertising campaigns we generate for you, we retain ownership of:
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li className="mb-2">Our proprietary systems and processes</li>
        <li className="mb-2">The technology used to generate ad content</li>
        <li className="mb-2">Aggregate, anonymized data derived from service usage</li>
      </ul>

      <h2 className="text-xl font-bold mt-6 mb-2">7. User Content Submissions</h2>
      <p className="mb-4">
        When you provide us with website content, domain information, or other data for processing by our services, you:
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li className="mb-2">Warrant that you have the right to provide such content to us</li>
        <li className="mb-2">Grant us permission to process this data to provide our services</li>
        <li className="mb-2">Understand that we may cache this content for service improvement</li>
        <li className="mb-2">Agree that your content does not infringe on any third-party rights</li>
      </ul>

      <h2 className="text-xl font-bold mt-6 mb-2">8. Termination of Accounts</h2>
      <p className="mb-4">
        Multiply reserves the right to terminate your account at any time and for any reason. 
        If you wish to delete your account, you must contact us at <strong>support@trymultiply.com</strong>. 
        Upon account deletion, all personal data will be permanently removed in accordance with our data retention policies.
      </p>

      <h2 className="text-xl font-bold mt-6 mb-2">9. Limitation of Liability</h2>
      <ul className="list-disc ml-6 mb-4">
        <li className="mb-2">Multiply accepts no responsibility for service interruptions, data loss, or third-party activities.</li>
        <li className="mb-2">Multiply does not guarantee the performance of any ad campaigns managed through our Services and is not liable for any financial outcomes related to ad performance.</li>
        <li className="mb-2">In no event will Multiply be liable for any direct, indirect, incidental, or consequential damages, except as limited to the amount paid to Multiply for its management services, not including Google ad spend.</li>
        <li className="mb-2">Multiply is not responsible for the accuracy or quality of AI-generated content, and you agree to review all content before use.</li>
      </ul>

      <h2 className="text-xl font-bold mt-6 mb-2">10. Data Security</h2>
      <p className="mb-4">
        We implement reasonable security measures to protect your information:
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li className="mb-2">OAuth tokens and refresh tokens are stored in an encrypted state</li>
        <li className="mb-2">Website data and generated content are protected with appropriate safeguards</li>
        <li className="mb-2">Access to your data is limited to authorized personnel</li>
        <li className="mb-2">We regularly review and update our security practices</li>
      </ul>
      <p className="mb-4">
        However, no method of data transmission or storage is 100% secure. While we strive to protect your information, we cannot guarantee absolute security.
      </p>

      <h2 className="text-xl font-bold mt-6 mb-2">11. Dispute Resolution</h2>
      <p className="mb-2">
        All disputes arising out of or relating to these Terms will be resolved through binding arbitration in New York County, New York State, United States.
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li className="mb-2">Users waive the right to participate in any class-action lawsuits.</li>
        <li className="mb-2">Liability is limited to the management fees paid to Multiply. Multiply will not cover arbitration costs.</li>
      </ul>

      <h2 className="text-xl font-bold mt-6 mb-2">12. Governing Law</h2>
      <p className="mb-4">
        These Terms will be governed by and construed in accordance with the laws of New York State, United States, without regard to its conflict of law provisions.
      </p>

      <h2 className="text-xl font-bold mt-6 mb-2">13. Third-Party Links and Services</h2>
      <p className="mb-4">
        Our website may contain links to third-party sites. Multiply is not responsible for the content, privacy policies, or practices of these third-party websites. 
        Use of these websites is at your own risk.
      </p>
      <p className="mb-4">
        Our Services integrate with third-party platforms such as Google Ads. By connecting these services:
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li className="mb-2">You authorize us to access and interact with your accounts on these platforms</li>
        <li className="mb-2">You agree to comply with the terms of service of these third-party platforms</li>
        <li className="mb-2">You understand that these third-party services have their own terms and privacy policies</li>
      </ul>

      <h2 className="text-xl font-bold mt-6 mb-2">14. Changes to Terms of Use</h2>
      <p className="mb-4">
        Multiply reserves the right to modify or update these Terms at any time. 
        You will be notified of any changes via email. 
        Your continued use of the Services after such modifications constitutes your acceptance of the updated Terms.
      </p>

      <h2 className="text-xl font-bold mt-6 mb-2">15. Contact Us</h2>
      <p className="mb-4">
        For any questions or concerns regarding these Terms, please contact us at <strong>support@trymultiply.com</strong>.
      </p>
    </div>
  );
};

export default TermsOfService;
