import React, { useCallback, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Pencil, Loader2, Save, AlertCircle } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useCampaign } from '@/contexts/CampaignContext';

const GoogleAdsBudgetPage: React.FC = () => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [isEditingBudget, setIsEditingBudget] = useState(false);
  const [newDailyBudget, setNewDailyBudget] = useState<number | string>('');
  const [budgetError, setBudgetError] = useState<string | null>(null);

  const {
    budgetLoading,
    budgetData,
    budgetHistory,
    updateBudget,
    fetchBudgetIfNeeded
  } = useCampaign();

  useEffect(() => {
    fetchBudgetIfNeeded();
  }, [fetchBudgetIfNeeded]);

  useEffect(() => {
    if (budgetData && isEditingBudget && newDailyBudget === '') {
      setNewDailyBudget(budgetData.daily_budget);
    }
  }, [budgetData, isEditingBudget, newDailyBudget]);

  const getNextSunday = () => {
    const today = new Date();
    const daysUntilSunday = 7 - today.getDay();
    const nextSunday = new Date(today);
    nextSunday.setDate(today.getDate() + daysUntilSunday);
    return nextSunday.toLocaleDateString('en-US', {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });
  };

  // Move useCallback before any conditional returns
  const updateBudgetInternal = useCallback(async () => {
    const id = toast.loading('Updating Budget...');
    setIsUpdating(true);
    
    try {
      const numericBudget = Number(newDailyBudget);
      
      // Validate budget amount
      if (isNaN(numericBudget)) {
        throw new Error('Please enter a valid budget amount');
      }
      
      if (numericBudget < 10) {
        throw new Error('Minimum daily budget is $10.00');
      }
      
      if (numericBudget > 100000) {
        throw new Error('Maximum daily budget is $100,000.00');
      }
      
      const result = await updateBudget(numericBudget * 100);
      
      if (result) {
        toast.update(id, {
          render: 'Budget updated successfully',
          type: 'success',
          isLoading: false,
          autoClose: 3000
        });
        setIsEditingBudget(false);
      } else {
        toast.update(id, {
          render: 'Failed to update budget',
          type: 'error',
          isLoading: false,
          autoClose: 5000
        });
      }
    } catch(error) {
      toast.update(id, {
        render: error instanceof Error ? error.message : 'Failed to update budget',
        type: 'error',
        isLoading: false,
        autoClose: 5000
      });
    } finally {
      setIsUpdating(false);
    }
  }, [newDailyBudget, updateBudget]);

  if (budgetLoading) {
    return (
      <div className="flex justify-center items-center p-8">
        <Loader2 className="animate-spin" size={32} />
      </div>
    );
  }

  if (!budgetData) {
    return (
      <div className="flex justify-center items-center p-8">
        <div className="text-lg text-gray-600">Budget data not available</div>
      </div>
    );
  }

  return (
    <div className="flex-1 p-8">
      <h1 className="text-2xl font-bold mb-4">Budget Overview</h1>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <div className="bg-white p-6 rounded shadow">
          <div className="flex justify-between items-center mb-2">
            <h3 className="text-lg font-semibold">Daily Budget</h3>
            {!isEditingBudget && (
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setIsEditingBudget(true)}
                className="text-blue-500 hover:text-blue-600"
              >
                <Pencil size={20} />
              </motion.button>
            )}
          </div>
          <AnimatePresence mode="wait">
            {isEditingBudget ? (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 10 }}
                className="space-y-2"
              >
                <input
                  type="number"
                  value={newDailyBudget}
                  onChange={(e) => {
                    setNewDailyBudget(e.target.value);
                    
                    // Validate input as user types
                    const value = Number(e.target.value);
                    if (isNaN(value)) {
                      setBudgetError('Please enter a valid number');
                    } else if (value < 10) {
                      setBudgetError('Minimum daily budget is $10.00');
                    } else if (value > 100000) {
                      setBudgetError('Maximum daily budget is $100,000.00');
                    } else {
                      setBudgetError(null);
                    }
                  }}
                  placeholder="Enter new daily budget"
                  className="w-full border rounded p-2"
                  min="10.00"
                  max="100000.00"
                  step="0.01"
                />
                {newDailyBudget && (
                  <>
                    <div className="flex items-start gap-2 p-3 mb-4 rounded bg-yellow-50 border border-yellow-200 text-yellow-800">
                      <AlertCircle className="h-5 w-5 flex-shrink-0 mt-0.5" />
                      <span>
                        Changes will take effect next billing cycle. A charge of ${(Number(newDailyBudget) * 0.1).toFixed(2)} (10% of new budget) will be processed on {getNextSunday()}.
                      </span>
                    </div>
                    <div className={`text-sm ${budgetError ? 'text-red-600' : 'text-gray-600'} mb-2`}>
                      {budgetError || 'Daily budget must be between $10.00 and $100,000.00'}
                    </div>
                  </>
                )}
                <div className="flex gap-2">
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={updateBudgetInternal}
                    disabled={isUpdating || !!budgetError || !newDailyBudget}
                    className="flex items-center gap-2 bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700 disabled:bg-gray-400"
                  >
                    {isUpdating ? (
                      <Loader2 className="animate-spin" size={16} />
                    ) : (
                      <Save size={16} />
                    )}
                    Save
                  </motion.button>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => {
                      setIsEditingBudget(false);
                      setNewDailyBudget('');
                    }}
                    disabled={isUpdating}
                    className="px-4 py-2 border rounded hover:bg-gray-50"
                  >
                    Cancel
                  </motion.button>
                </div>
              </motion.div>
            ) : (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 10 }}
              >
                <p className="text-3xl font-bold">${budgetData?.daily_budget.toFixed(2)}</p>
                <p className="text-gray-500">Set your daily spending limit</p>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <div className="bg-white p-6 rounded shadow">
          <h3 className="text-lg font-semibold mb-2">Monthly Budget</h3>
          <p className="text-3xl font-bold">${budgetData?.monthly_budget.toFixed(2)}</p>
          <p className="text-gray-500">Estimated monthly spend</p>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-6 mb-6">
        <div className="bg-white p-6 rounded shadow">
          <h3 className="text-lg font-semibold mb-2">Spent Today</h3>
          <p className="text-3xl font-bold">${budgetData?.spent_today.toFixed(2)}</p>
        </div>
        <div className="bg-white p-6 rounded shadow">
          <h3 className="text-lg font-semibold mb-2">Spent This Month</h3>
          <p className="text-3xl font-bold">${budgetData?.spent_this_month.toFixed(2)}</p>
        </div>
        <div className="bg-white p-6 rounded shadow">
          <h3 className="text-lg font-semibold mb-2">Remaining Budget</h3>
          <p className="text-3xl font-bold">${budgetData?.remaining_budget.toFixed(2)}</p>
        </div>
      </div>

      <h2 className="text-xl font-semibold mb-4">Budget History</h2>

      <div className="bg-white p-6 rounded shadow">
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={budgetHistory}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Line type="monotone" dataKey="spent" stroke="#8884d8" />
            <Line type="monotone" dataKey="budget" stroke="#82ca9d" />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default GoogleAdsBudgetPage;