import React, { useState, useEffect, useCallback } from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { toast } from 'react-toastify';
import { useCampaign } from '@/contexts/CampaignContext';
import { AlertCircle, CheckCircle, PlusCircle, XCircle } from 'lucide-react';

const ManageKeywords: React.FC = () => {
  const {
    positiveKeywords: initialPositiveKeywords,
    negativeKeywords: initialNegativeKeywords,
    handleUpdateKeywords
  } = useCampaign();

  // Track both original keywords and current working set
  const [originalPositive, setOriginalPositive] = useState<string[]>([]);
  const [originalNegative, setOriginalNegative] = useState<string[]>([]);
  const [currentPositive, setCurrentPositive] = useState<string[]>([]);
  const [currentNegative, setCurrentNegative] = useState<string[]>([]);
  
  // Track keywords specifically added or removed during this session
  const [addedPositive, setAddedPositive] = useState<string[]>([]);
  const [addedNegative, setAddedNegative] = useState<string[]>([]);
  const [removedPositive, setRemovedPositive] = useState<string[]>([]);
  const [removedNegative, setRemovedNegative] = useState<string[]>([]);

  const [newPositive, setNewPositive] = useState('');
  const [newNegative, setNewNegative] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [saveStatus, setSaveStatus] = useState<'idle' | 'success' | 'error'>('idle');

  // Initialize all state when initial keywords change
  useEffect(() => {
    setOriginalPositive(initialPositiveKeywords);
    setOriginalNegative(initialNegativeKeywords);
    setCurrentPositive(initialPositiveKeywords);
    setCurrentNegative(initialNegativeKeywords);
    // Reset tracking arrays
    setAddedPositive([]);
    setAddedNegative([]);
    setRemovedPositive([]);
    setRemovedNegative([]);
  }, [initialPositiveKeywords, initialNegativeKeywords]);

  // Calculate if there are any unsaved changes
  const hasChanges = 
    addedPositive.length > 0 || 
    addedNegative.length > 0 || 
    removedPositive.length > 0 || 
    removedNegative.length > 0;

  const addKeyword = (type: 'positive' | 'negative') => {
    const keyword = type === 'positive' ? newPositive.trim() : newNegative.trim();
    
    if (!keyword) return;
    
    if (type === 'positive') {
      // Check if it's already in current set or was previously removed
      if (currentPositive.includes(keyword)) {
        toast.error(`"${keyword}" is already in the positive keywords list.`);
        return;
      }

      // Add to current set and track it as added
      setCurrentPositive([...currentPositive, keyword]);
      
      // If it was in original list but marked as removed, just remove it from removed list
      if (originalPositive.includes(keyword) && removedPositive.includes(keyword)) {
        setRemovedPositive(removedPositive.filter(k => k !== keyword));
      } 
      // Otherwise add it to the added list if it wasn't in the original
      else if (!originalPositive.includes(keyword)) {
        setAddedPositive([...addedPositive, keyword]);
      }
      
      setNewPositive('');
    } else {
      // Similar logic for negative keywords
      if (currentNegative.includes(keyword)) {
        toast.error(`"${keyword}" is already in the blocked keywords list.`);
        return;
      }

      setCurrentNegative([...currentNegative, keyword]);
      
      if (originalNegative.includes(keyword) && removedNegative.includes(keyword)) {
        setRemovedNegative(removedNegative.filter(k => k !== keyword));
      } 
      else if (!originalNegative.includes(keyword)) {
        setAddedNegative([...addedNegative, keyword]);
      }
      
      setNewNegative('');
    }
  };

  const removeKeyword = (type: 'positive' | 'negative', keyword: string) => {
    if (type === 'positive') {
      // Remove from current display
      setCurrentPositive(currentPositive.filter(k => k !== keyword));
      
      // If it was in the original set, mark as removed
      if (originalPositive.includes(keyword)) {
        setRemovedPositive([...removedPositive, keyword]);
      }
      
      // If it was newly added, remove from added list
      if (addedPositive.includes(keyword)) {
        setAddedPositive(addedPositive.filter(k => k !== keyword));
      }
    } else {
      // Similar logic for negative keywords
      setCurrentNegative(currentNegative.filter(k => k !== keyword));
      
      if (originalNegative.includes(keyword)) {
        setRemovedNegative([...removedNegative, keyword]);
      }
      
      if (addedNegative.includes(keyword)) {
        setAddedNegative(addedNegative.filter(k => k !== keyword));
      }
    }
  };

  const cancelChanges = () => {
    // Revert to original state
    setCurrentPositive(originalPositive);
    setCurrentNegative(originalNegative);
    setAddedPositive([]);
    setAddedNegative([]);
    setRemovedPositive([]);
    setRemovedNegative([]);
    setSaveStatus('idle');
  };

  const saveKeywords = async () => {
    setIsSaving(true);
    setSaveStatus('idle');
    
    try {
      // Current state after changes = original - removed + added
      const finalPositive = [...originalPositive.filter(k => !removedPositive.includes(k)), ...addedPositive];
      const finalNegative = [...originalNegative.filter(k => !removedNegative.includes(k)), ...addedNegative];
      
      await handleUpdateKeywords(finalPositive, finalNegative);
      
      // Update original keywords to match the new state
      setOriginalPositive(finalPositive);
      setOriginalNegative(finalNegative);
      
      // Clear tracking lists
      setAddedPositive([]);
      setAddedNegative([]);
      setRemovedPositive([]);
      setRemovedNegative([]);
      
      setSaveStatus('success');
      setTimeout(() => setSaveStatus('idle'), 3000);
    } catch (error) {
      console.error('Error saving keywords:', error);
      setSaveStatus('error');
    } finally {
      setIsSaving(false);
    }
  };

  // Get styling for a keyword based on its state
  const getKeywordStyle = (keyword: string, type: 'positive' | 'negative') => {
    const isAdded = type === 'positive' 
      ? addedPositive.includes(keyword) 
      : addedNegative.includes(keyword);
      
    const isRemoved = type === 'positive'
      ? removedPositive.includes(keyword)
      : removedNegative.includes(keyword);
    
    if (isAdded) {
      return "bg-green-100 text-green-800 border border-green-300";
    } else if (isRemoved) {
      return "bg-red-100 text-red-800 border border-red-300 line-through opacity-70";
    } else {
      return "bg-gray-100 text-gray-800";
    }
  };

  // Get label for a keyword's status
  const getKeywordStatusIcon = (keyword: string, type: 'positive' | 'negative') => {
    const isAdded = type === 'positive' 
      ? addedPositive.includes(keyword) 
      : addedNegative.includes(keyword);
      
    const isRemoved = type === 'positive'
      ? removedPositive.includes(keyword)
      : removedNegative.includes(keyword);
    
    if (isAdded) {
      return <PlusCircle className="w-3 h-3 mr-1 text-green-600" />;
    } else if (isRemoved) {
      return <XCircle className="w-3 h-3 mr-1 text-red-600" />;
    }
    return null;
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold">Keywords</h2>
        
        {saveStatus === 'success' && (
          <div className="flex items-center text-green-600 bg-green-50 px-3 py-1 rounded">
            <CheckCircle className="w-4 h-4 mr-2" /> Keywords saved successfully
          </div>
        )}
        
        {saveStatus === 'error' && (
          <div className="flex items-center text-red-600 bg-red-50 px-3 py-1 rounded">
            <AlertCircle className="w-4 h-4 mr-2" /> Error saving keywords
          </div>
        )}
      </div>

      <p className="text-gray-600">
        Words or phrases describing your product or service that you choose to help determine when
        and where your ad can appear.
      </p>
      
      {hasChanges && (
        <div className="bg-blue-50 p-4 rounded border border-blue-200 mb-4">
          <p className="text-sm text-blue-800 mb-1 font-medium">You have unsaved changes</p>
          <div className="text-sm text-blue-600">
            {addedPositive.length > 0 && <p>• {addedPositive.length} new positive keywords</p>}
            {removedPositive.length > 0 && <p>• {removedPositive.length} removed positive keywords</p>}
            {addedNegative.length > 0 && <p>• {addedNegative.length} new blocked keywords</p>}
            {removedNegative.length > 0 && <p>• {removedNegative.length} removed blocked keywords</p>}
          </div>
        </div>
      )}

      <Card>
        <CardContent className="p-6">
          <h3 className="text-xl font-semibold mb-2">Positive keywords</h3>
          <p className="text-gray-600 mb-4">Use positive keywords to show ads for relevant searches.</p>
          
          <div className="flex gap-2 mb-4">
            <Input
              placeholder="Enter a new word or phrase"
              value={newPositive}
              onChange={(e) => setNewPositive(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && addKeyword('positive')}
              className="flex-grow"
            />
            <Button 
              onClick={() => addKeyword('positive')} 
              variant="outline"
              disabled={!newPositive.trim()}
            >
              Add
            </Button>
          </div>
          
          {/* Display a message if no keywords */}
          {currentPositive.length === 0 && !removedPositive.length && (
            <p className="text-gray-500 italic text-sm">No positive keywords added yet</p>
          )}
          
          <div className="flex flex-wrap gap-2 mb-4">
            {/* Show current keywords */}
            {currentPositive.map((keyword, index) => (
              <span 
                key={`current-${index}`} 
                className={`px-3 py-1 rounded-full text-sm flex items-center ${getKeywordStyle(keyword, 'positive')}`}
              >
                {getKeywordStatusIcon(keyword, 'positive')}
                {keyword}
                <button 
                  onClick={() => removeKeyword('positive', keyword)} 
                  className="ml-2 text-gray-500 hover:text-gray-700"
                  title="Remove keyword"
                >
                  ×
                </button>
              </span>
            ))}
            
            {/* Show removed keywords */}
            {removedPositive.filter(k => !currentPositive.includes(k)).map((keyword, index) => (
              <span 
                key={`removed-${index}`} 
                className={`px-3 py-1 rounded-full text-sm flex items-center ${getKeywordStyle(keyword, 'positive')}`}
              >
                {getKeywordStatusIcon(keyword, 'positive')}
                {keyword}
                <button 
                  onClick={() => {
                    setCurrentPositive([...currentPositive, keyword]);
                    setRemovedPositive(removedPositive.filter(k => k !== keyword));
                  }} 
                  className="ml-2 text-green-500 hover:text-green-700"
                  title="Restore keyword"
                >
                  ↺
                </button>
              </span>
            ))}
          </div>
        </CardContent>
      </Card>
      
      <Card>
        <CardContent className="p-6">
          <h3 className="text-xl font-semibold mb-2">Blocked keywords</h3>
          <p className="text-gray-600 mb-4">Prevent your ad from showing for certain searches.</p>
          
          <div className="flex gap-2 mb-4">
            <Input
              placeholder="Enter a new word or phrase"
              value={newNegative}
              onChange={(e) => setNewNegative(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && addKeyword('negative')}
              className="flex-grow"
            />
            <Button 
              onClick={() => addKeyword('negative')} 
              variant="outline"
              disabled={!newNegative.trim()}
            >
              Add
            </Button>
          </div>
          
          {/* Display a message if no keywords */}
          {currentNegative.length === 0 && !removedNegative.length && (
            <p className="text-gray-500 italic text-sm">No blocked keywords added yet</p>
          )}
          
          <div className="flex flex-wrap gap-2 mb-4">
            {/* Show current keywords */}
            {currentNegative.map((keyword, index) => (
              <span 
                key={`current-${index}`} 
                className={`px-3 py-1 rounded-full text-sm flex items-center ${getKeywordStyle(keyword, 'negative')}`}
              >
                {getKeywordStatusIcon(keyword, 'negative')}
                {keyword}
                <button 
                  onClick={() => removeKeyword('negative', keyword)} 
                  className="ml-2 text-gray-500 hover:text-gray-700"
                  title="Remove keyword"
                >
                  ×
                </button>
              </span>
            ))}
            
            {/* Show removed keywords */}
            {removedNegative.filter(k => !currentNegative.includes(k)).map((keyword, index) => (
              <span 
                key={`removed-${index}`} 
                className={`px-3 py-1 rounded-full text-sm flex items-center ${getKeywordStyle(keyword, 'negative')}`}
              >
                {getKeywordStatusIcon(keyword, 'negative')}
                {keyword}
                <button 
                  onClick={() => {
                    setCurrentNegative([...currentNegative, keyword]);
                    setRemovedNegative(removedNegative.filter(k => k !== keyword));
                  }} 
                  className="ml-2 text-green-500 hover:text-green-700"
                  title="Restore keyword"
                >
                  ↺
                </button>
              </span>
            ))}
          </div>
        </CardContent>
      </Card>
      
      <div className="flex gap-3">
        <Button 
          onClick={saveKeywords} 
          disabled={!hasChanges || isSaving}
          className="px-4 py-2 rounded bg-primary text-white hover:bg-primaryDark"
        >
          {isSaving ? 'Saving...' : 'Save Changes'}
        </Button>
        
        {hasChanges && (
          <Button 
            onClick={cancelChanges} 
            variant="outline"
            className="px-4 py-2 rounded"
          >
            Cancel Changes
          </Button>
        )}
      </div>
    </div>
  );
};

export default ManageKeywords;