import React, { useState, useEffect } from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { Slider } from '@/components/ui/slider';
import { StepStatus } from '@/utils/Interfaces';
import { ApiConversionProfileLeads, ApiConversionProfilePurchases } from '@/types/api/CampaignTypes';

interface ConversionProfileProps {
  onStepComplete?: (status: StepStatus) => void;
  onConversionUpdate: (conversionProfile: {
    conversionType: 'leads' | 'purchases';
    profile: ApiConversionProfileLeads | ApiConversionProfilePurchases;
  }) => void;
  initialProfile?: {
    conversionType: 'leads' | 'purchases';
    profile: ApiConversionProfileLeads | ApiConversionProfilePurchases;
  };
}

const OverlayConversionProfile: React.FC<ConversionProfileProps> = ({ 
  onStepComplete, 
  onConversionUpdate,
  initialProfile
}) => {
  const [conversionType, setConversionType] = useState<'leads' | 'purchases'>(
    initialProfile?.conversionType || 'leads'
  );
  const [monthly, setMonthly] = useState(() => {
    if (initialProfile) {
      return 'monthly_leads' in initialProfile.profile 
        ? initialProfile.profile.monthly_leads.toString()
        : initialProfile.profile.monthly_sales.toString();
    }
    return '';
  });
  const [average, setAverage] = useState(() => {
    if (initialProfile) {
      return 'customer_spend' in initialProfile.profile 
        ? initialProfile.profile.customer_spend.toString()
        : initialProfile.profile.aov.toString();
    }
    return '';
  });
  const [profit, setProfit] = useState(() => {
    if (initialProfile) {
      return 'estimated_profit' in initialProfile.profile 
        ? initialProfile.profile.estimated_profit.toString()
        : initialProfile.profile.est_profit.toString();
    }
    return '';
  });
  const [conversionRate, setConversionRate] = useState<number[]>(() => {
    if (initialProfile && 'conversion_rate' in initialProfile.profile) {
      return [initialProfile.profile.conversion_rate];
    }
    return [25];
  });
  
  const [hasInteracted, setHasInteracted] = useState(false);

  // Update parent state when form is valid
  useEffect(() => {
    const isValid = 
      monthly !== '' && 
      average !== '' && 
      profit !== '' && 
      (conversionType === 'purchases' || (conversionType === 'leads' && conversionRate.length > 0));

    if (isValid && hasInteracted) {
      const profile = conversionType === 'leads'
        ? {
            monthly_leads: parseInt(monthly),
            customer_spend: parseFloat(average),
            estimated_profit: parseFloat(profit),
            conversion_rate: conversionRate[0],
          }
        : {
            monthly_sales: parseInt(monthly),
            aov: parseFloat(average),
            est_profit: parseFloat(profit),
          };

      onConversionUpdate({
        conversionType,
        profile: profile as ApiConversionProfileLeads | ApiConversionProfilePurchases
      });

      if (onStepComplete) {
        onStepComplete({
          isComplete: false,
          message: undefined
        });
      }
    } else if (onStepComplete && hasInteracted) {
      onStepComplete({
        isComplete: false,
        message: 'Please fill out all required fields'
      });
    }
  }, [monthly, average, profit, conversionType, conversionRate, hasInteracted, onStepComplete, onConversionUpdate]);

  const handleInputChange = (setter: React.Dispatch<React.SetStateAction<string>>) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setHasInteracted(true);
    setter(e.target.value);
  };

  const handleTypeChange = (value: 'leads' | 'purchases') => {
    setHasInteracted(true);
    setConversionType(value);
    // Reset fields when type changes
    setMonthly('');
    setAverage('');
    setProfit('');
    if (value === 'leads') {
      setConversionRate([25]);
    }
  };

  const handleConversionRateChange = (value: number[]) => {
    setHasInteracted(true);
    setConversionRate(value);
  };

  return (
    <Card className="w-full max-w-2xl mx-auto">
      <CardContent className="p-6">
        <h2 className="text-2xl font-bold mb-2">Set up your conversion profile</h2>
        <p className="text-gray-600 mb-6">
          Multiply will use this information to optimize your conversion tracking, ad spend,
          performance, and more.
        </p>

        <div className="space-y-6">
          <RadioGroup
            value={conversionType}
            onValueChange={handleTypeChange}
            className="grid grid-cols-2 gap-4"
          >
            <div className={`border rounded-lg p-4 ${conversionType === 'leads' ? 'border-blue-500' : 'border-gray-200'}`}>
              <RadioGroupItem value="leads" id="leads" className="sr-only" />
              <Label htmlFor="leads" className="flex flex-col cursor-pointer">
                <span className="text-xl font-semibold mb-2">Leads</span>
                <span className="text-gray-600">
                  For businesses looking to generate leads. This includes service-based
                  businesses, such as electricians.
                </span>
              </Label>
            </div>
            <div className={`border rounded-lg p-4 ${conversionType === 'purchases' ? 'border-blue-500' : 'border-gray-200'}`}>
              <RadioGroupItem value="purchases" id="purchases" className="sr-only" />
              <Label htmlFor="purchases" className="flex flex-col cursor-pointer">
                <span className="text-xl font-semibold mb-2">Purchases</span>
                <span className="text-gray-600">
                  For businesses looking to drive sales. This includes product-based
                  businesses, such as online stores.
                </span>
              </Label>
            </div>
          </RadioGroup>

          <div className="space-y-4">
            <div>
              <Label htmlFor="monthly">
                {conversionType === 'leads' ? 'Monthly leads' : 'Monthly sales'}
              </Label>
              <Input
                id="monthly"
                type="number"
                placeholder="Enter a number"
                className="mt-1"
                value={monthly}
                onChange={handleInputChange(setMonthly)}
                required
              />
            </div>
            <div>
              <Label htmlFor="average">
                {conversionType === 'leads' ? 'Average customer spend' : 'Average order value'}
              </Label>
              <div className="flex mt-1">
                <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                  $
                </span>
                <Input
                  id="average"
                  type="number"
                  placeholder="Enter a number"
                  className="rounded-l-none"
                  value={average}
                  onChange={handleInputChange(setAverage)}
                  required
                />
                <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                  USD
                </span>
              </div>
            </div>
            <div>
              <Label htmlFor="profit">Estimated profit</Label>
              <div className="flex mt-1">
                <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                  $
                </span>
                <Input
                  id="profit"
                  type="number"
                  placeholder="Enter a number"
                  className="rounded-l-none"
                  value={profit}
                  onChange={handleInputChange(setProfit)}
                  required
                />
                <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                  USD
                </span>
              </div>
            </div>
            {conversionType === 'leads' && (
              <div>
                <Label htmlFor="conversion-rate">Conversion rate</Label>
                <Slider
                  id="conversion-rate"
                  min={0}
                  max={100}
                  step={1}
                  value={conversionRate}
                  onValueChange={handleConversionRateChange}
                  className="mt-2"
                />
                <div className="flex justify-between text-sm text-gray-500 mt-1">
                  <span>0%</span>
                  <span>25%</span>
                  <span>50%</span>
                  <span>75%</span>
                  <span>100%</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default OverlayConversionProfile;