export const DOMAIN_KEY = 'domain';
export const ONBOARDING_STATE_KEY = 'onboardingState';
export const ACTIVE_CAMPAIGN_ID = 'activeCampaignId';

export const onboardingRedirectURL = (onboardingState: any, suggestionsState: any) => {
  const BASE_URL = 'https://app.trymultiply.com';
  const encodedAppState = encodeURIComponent(JSON.stringify(onboardingState));
  const encodedSuggestionsState = encodeURIComponent(JSON.stringify(suggestionsState));
  
  return `${BASE_URL}/onboarding/link-google-account?step=loading&appState=${encodedAppState}&suggestionsState=${encodedSuggestionsState}`;
};

export const loginRedirectUrl = (path: string) => {
  return `https://app.trymultiply.com/account/auth/callback`; 
};