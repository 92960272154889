import React, { createContext, useContext, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { baseUrl } from '@/services/api-config';
import { useAuth } from '@/utils/AuthProvider';
import { BudgetData } from '@/pages/page_types/budget';
import { SearchTermsData } from '@/types/api/AdPreviewTypes';
import { HomepageData } from '@/pages/page_types/homepage';
import { ICampaignContext, IUpdateContextLocation } from './CampaignContextInterface';
import { ACTIVE_CAMPAIGN_ID } from '@/types/constants';
import { AdPreviewResponse } from '@/types/api/AdPreviewTypes';
import { IMultiplyLocation, FetchCampaignLocationsResponse, IUpdateLocationsResponse, IUpdateLocationsRequest } from '@/pages/page_types/location_data';
import { FetchAdCreativeResponse, AdItem, CreativeType } from '@/pages/page_types/ad_content';
import { Campaign } from '@/pages/page_types/campaigns';
import { ApiCreateCampaignRequest, ApiCreateCampaignResponse, ApiLoadCampaignResponse, ApiUpdateBudgetRequest, ApiUpdateBudgetResponse } from '@/types/api/CampaignTypes';
import { createApiClient } from '@/interceptors/multiply-interceptor';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const CampaignContext = createContext<ICampaignContext | null>(null);

export const CampaignProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const {
    session,
    signOut,
    refreshSession,
  } = useAuth();

  const navigate = useNavigate();

  const [apiClient] = useState(() => createApiClient({
    signOut: signOut,
    refreshSession: refreshSession,
    getSession: () => session
  }));
  
  // State for API data
  const [homepageData, setHomepageData] = useState<HomepageData | null>(null);
  const [positiveKeywords, setPositiveKeywords] = useState<string[]>([]);
  const [negativeKeywords, setNegativeKeywords] = useState<string[]>([]);
  const [budgetData, setBudgetData] = useState<BudgetData | null>(null);
  const [suggestedBudget, setSuggestedBudget] = useState<number>(0);
  const [budgetHistory, setBudgetHistory] = useState<any[]>([]);
  const [headlines, setHeadlines] = useState<string[]>([]);
  const [descriptions, setDescriptions] = useState<string[]>([]);
  const [domain, setDomain] = useState<string>('');
  const [locationData, setLocationData] = useState<IMultiplyLocation[]>([]);
  const [adCreatives, setAdCreatives] = useState<AdItem[]>([]);
  const [activeCampaign, setActiveCampaign] = useState<Campaign>();
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [searchTerms, setSearchTerms] = useState<SearchTermsData | null>(null);
  
  // Loading states
  const [homepageDataLoading, setHomepageDataLoading] = useState(false);
  const [keywordsLoading, setKeywordsLoading] = useState(false);
  const [budgetLoading, setBudgetLoading] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [campaignStatusLoading, setCampaignStatusLoading] = useState(false);
  const [locationDataLoading, setLocationDataLoading] = useState(false);
  const [adCreativeLoading, setAdCreativeLoading] = useState(false);
  const [searchTermsLoading, setSearchTermsLoading] = useState(false);

  const [loadingCampaigns, setLoadingCampaigns] = useState(false);
  
  // Track if data has been fetched
  const [hasHomepageData, setHasHomepageData] = useState(false);

  const [hasKeywords, setHasKeywords] = useState(false);
  const [hasBudget, setHasBudget] = useState(false);
  const [hasPreview, setHasPreview] = useState(false);
  const [hasLocationData, setHasLocationData] = useState(false);
  const [hasAdCreative, setHasAdCreative] = useState(false);

  const [hasCampaigns, setHasCampaigns] = useState(false);
  const [fetchedCampaigns, setFetchedCampaigns] = useState(false);

  const [hasSearchTerms, setHasSearchTerms] = useState(false);

  const [savingAdCreative, setSavingAdCreative] = useState(false);

  const [campaignStatus, setCampaignStatus] = useState<'ACTIVE' | 'PAUSED' | 'UNKNOWN'>('UNKNOWN');
  const [hasCampaignStatus, setHasCampaignStatus] = useState(false);

  const [showOnboarding, setShowOnboarding] = useState(false);


  const [campaignId, setCampaignId] = useState<string>(() => {
    return activeCampaign?.id ?? localStorage.getItem(ACTIVE_CAMPAIGN_ID)!;
  });


  const getHeaders = async () => {
    if (!session?.access_token) {
      await refreshSession();
    }
    if (!session?.access_token) {
      throw new Error('No access token available');
    }
    return {
      Authorization: `Bearer ${session.access_token}`,
      'Content-Type': 'application/json',
    };
  };

  const toggleCampaignStatus = async () => {
    if (!campaignId || !session?.access_token || !homepageData) {
      toast.error('Unable to update campaign status. Please try again.');
      return;
    }
    
    const currentStatus = homepageData.status;
    const newStatus = currentStatus === 'ENABLED' ? 'PAUSED' : 'ENABLED';
    
    try {
      const headers = await getHeaders()
      const response = await apiClient.post(
        `${baseUrl}/googleads/campaign/status`,
        { 
          campaign_id: campaignId,
          status: newStatus 
        },
        { headers }
      );
      
      if (response.status === 200) {
        // Update homepage data while preserving all other fields
        setHomepageData({
          ...homepageData,
          status: newStatus
        });
        
        toast.success(`Campaign ${newStatus.toLowerCase()} successfully`);
      } else {
        throw new Error('Unexpected response status');
      }
    } catch (error) {
      console.error('Error updating campaign status:', error);
      if (axios.isAxiosError(error)) {
        toast.error(`Failed to update campaign status: ${error.response?.data?.message || error.message}`);
      } else {
        toast.error('An unexpected error occurred while updating campaign status');
      }
    }
  };

  const handleCreateCampaign = useCallback(async (request: ApiCreateCampaignRequest) => {

    if (!session?.access_token) {
      await refreshSession();
    }

    const headers = await getHeaders();


    try {
      const response = await axios.post<ApiCreateCampaignResponse>(`${baseUrl}/googleads/campaign/create`, request, { headers });

      if (!response.data) {
        throw new Error('Failed to save changes');
      }

      localStorage.setItem(ACTIVE_CAMPAIGN_ID, response.data.id);

      setActiveCampaignFull({
        id: response.data.id,
        isOnboarded: response.data.is_onboarded,
        domain: response.data.domain
      });

      resetAllData();
    } catch (exception) {
      toast.error('Error creating campaign');
    }
  }, [])

  const handleSaveAdCreative = useCallback(async () => {
    const toastId = toast.loading("Saving changes...");
    setSavingAdCreative(true);
    
    try {
      if (!session?.access_token) {
        await refreshSession();
      }

      const operations = adCreatives
        .filter(item => item.status !== 'SYNCED')
        .map(item => ({
          operation: item.status === 'PENDING_ADD' ? 'add' : 'remove',
          text: item.text,
          field_type: item.type
        }));

      if (operations.length > 0) {
        const headers = {
          Authorization: `Bearer ${session?.access_token}`,
          'Content-Type': 'application/json',
        };

        const response = await apiClient.post(
          `${baseUrl}/googleads/campaign/creative/update`,
          {
            campaign_id: campaignId,
            ad_creative_operations: operations
          },
          { headers }
        );

        if (!response.data) {
          throw new Error('Failed to save changes');
        }

        setAdCreatives(prev => 
          prev
            .filter(item => item.status !== 'PENDING_REMOVE')
            .map(item => ({ ...item, status: 'SYNCED' as const }))
        );

        setHasAdCreative(true);

        toast.update(toastId, {
          render: "Changes saved successfully!",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      } else {
        toast.dismiss(toastId);
      }
    } catch (error) {
      console.error('Save error:', error);
      toast.update(toastId, {
        render: "Failed to save changes. Please try again.",
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    } finally {
      setSavingAdCreative(false);
    }
  }, []);

  const fetchAdCreativeIfNeeded = useCallback(async () => {

    if (adCreativeLoading || hasAdCreative) {
      return;
    }

    setAdCreativeLoading(true);

    try {
      const headers = await getHeaders();
      const response = await apiClient.get<FetchAdCreativeResponse>(
        `${baseUrl}/googleads/campaign/${campaignId}/creative`,
        { headers }
      );
  
      const { headlines, descriptions } = response.data;
  
      const loadedCreativeItems: AdItem[] = [];
  
      for (const headline of headlines) {
        loadedCreativeItems.push({
          text: headline,
          status: 'SYNCED' as const,
          type: 'HEADLINE' as CreativeType
        });
      }
  
      for (const description of descriptions) {
        loadedCreativeItems.push({
          text: description,
          status: 'SYNCED' as const,
          type: 'DESCRIPTION' as CreativeType
        });
      }
  
      setAdCreatives(loadedCreativeItems);
      setHasAdCreative(true);
    } catch (err) {
      toast.error('Failed to load ad content');
      console.error('Error loading ad content:', err);
    } finally {
      setAdCreativeLoading(false);
      setHasAdCreative(true);
    }

  }, [campaignId, hasAdCreative, adCreativeLoading]);

  const fetchPreviewIfNeeded = useCallback(async () => {
    if (hasPreview || previewLoading) return;

    setPreviewLoading(true);

    try {
        const headers = await getHeaders();
        
        const responseData = await apiClient.get<AdPreviewResponse>(
            `${baseUrl}/googleads/campaign/${campaignId}/preview`,
            { headers }
        );

        const newHeadlines: string[] = [];
        const newDescriptions: string[] = [];

        for (const item of responseData.data.headlines) {
            newHeadlines.push(item);
        }
        for (const item of responseData.data.descriptions) {
            newDescriptions.push(item);
        }
      
        // Fix 2: Set state with new arrays instead of using closure values
        setDomain(responseData.data.url);
        setHeadlines(newHeadlines);
        setDescriptions(newDescriptions);
        setHasPreview(true);
    } catch (error) {
        console.error('Error fetching preview data:', error);
        toast.error('Failed to fetch preview data');
    } finally {
        setPreviewLoading(false);
    }

  }, [campaignId, hasPreview, previewLoading]);

  const fetchSearchTermsIfNeeded = useCallback(async () => {
    // Use campaignId instead of activeCampaign?.id
    if (!campaignId || hasSearchTerms || searchTermsLoading) return;
    
    setSearchTermsLoading(true);
    try {
      const headers = await getHeaders();
  
      const response = await apiClient.get(
        `${baseUrl}/googleads/campaign/${campaignId}/searchTerms`,
        { headers }
      );
  
      if (response.status === 200) {
        setSearchTerms(response.data);
        setHasSearchTerms(true);
      } else {
        throw new Error('Unexpected response status');
      }
    } catch (err) {
      console.error('Error fetching search terms data:', err);
      toast.error('Failed to fetch search terms data');      
    } finally {
      setHasSearchTerms(true);
      setSearchTermsLoading(false);
    }
  }, [hasSearchTerms, searchTermsLoading, campaignId]);

  const fetchLocationDataIfNeeded = useCallback(async () => {
    if (hasLocationData || locationDataLoading) return;

    setLocationDataLoading(true);

    try {
      const headers = await getHeaders();

      const response = await apiClient.get<FetchCampaignLocationsResponse>(
        `${baseUrl}/googleads/campaign/${campaignId}/locations`,
        { headers }
      );

      if (response.status === 200) {
        setLocationData(response.data.locations);
        setHasLocationData(true);
      } else {
        throw new Error('Unexpected response status');
      }

    } catch (error) {
      console.error('Error fetching location data:', error);
      toast.error('Failed to fetch location data');
    } finally {
      setLocationDataLoading(false);
    }
  }, [hasLocationData, locationDataLoading, campaignId]);

  const handleFetchCampaignsIfNeeded = useCallback(async () => {
    // Early return if already loaded or in progress
    if (fetchedCampaigns || loadingCampaigns) {
      console.log('Already fetched or loading, skipping fetch');
      return;
    }
    
    setLoadingCampaigns(true);
    
    try {
      const headers = await getHeaders();
      const { data, status } = await apiClient.get<ApiLoadCampaignResponse>(
        `${baseUrl}/all_campaigns`,
        { headers }
      );

      setFetchedCampaigns(true);
      
      const campaignsData = data.campaigns.map(item => ({
        id: item.campaign_id,
        domain: item.domain,
        isOnboarded: item.is_onboarded
      }));
      
      // Handle empty campaigns case
      if (status === 200 && campaignsData.length === 0) {
        setHasCampaigns(false);
        return;
      }
      
      // Handle success with campaigns
      if (status === 200 && campaignsData.length > 0) {
        // Sort campaigns: onboarded first, then alphabetically by domain
        const sortedCampaigns = [...campaignsData].sort((a, b) => 
          a.isOnboarded !== b.isOnboarded 
            ? (a.isOnboarded ? -1 : 1) 
            : a.domain.localeCompare(b.domain)
        );
        
        setCampaigns(sortedCampaigns);
        setHasCampaigns(true);
        
        // Get active campaign (either from localStorage or first in list)
        const activeId = localStorage.getItem(ACTIVE_CAMPAIGN_ID);
        const activeCampaign = activeId 
          ? sortedCampaigns.find(campaign => campaign.id === activeId) 
          : sortedCampaigns[0];
        
        if (activeCampaign) {
          setActiveCampaign(activeCampaign);
          setCampaignId(activeCampaign.id);
          localStorage.setItem(ACTIVE_CAMPAIGN_ID, activeCampaign.id);
        }
        
        setFetchedCampaigns(true);
        return;
      }
      
      // Handle other status codes
      toast.error('Error fetching campaigns');
      setHasCampaigns(false);
      setFetchedCampaigns(false);
      
    } catch (error) {
      toast.error("Error fetching campaigns");
      setHasCampaigns(false);
      setActiveCampaign(undefined);
      setFetchedCampaigns(true);
      await signOut();
      navigate('/');
    } finally {
      setLoadingCampaigns(false);
    }
  }, [getHeaders, apiClient, baseUrl, signOut, fetchedCampaigns, loadingCampaigns, navigate]);

  const handleUpdateLocation = useCallback(async (input: IUpdateContextLocation) => {
    if (!campaignId) {
      toast.error('Campaign ID is required');
      return;
    }
    
    // Check if both arrays are empty or undefined
    if ((!input.itemsToAdd || input.itemsToAdd.length === 0) && 
        (!input.itemsToRemove || input.itemsToRemove.length === 0)) {
      toast.error("No Locations to Update");
      return;
    }
  
    // Create a toast ID for tracking the loading state
    const toastId = toast.loading("Updating locations...");
    
    try {
      const headers = await getHeaders();
      const request: IUpdateLocationsRequest = {
        campaign_id: campaignId,
        locations_to_add: input.itemsToAdd ?? [],
        locations_to_remove: input.itemsToRemove ?? []
      };
      
      await apiClient.post<IUpdateLocationsResponse>(
        `${baseUrl}/googleads/campaign/locations`,
        request,
        { headers }
      );
      
      // Update the toast with success message
      toast.update(toastId, {
        render: "Locations updated successfully",
        type: "success",
        isLoading: false,
        autoClose: 3000,
        closeButton: true
      });
    } catch (error) {
      // Update the toast with error message
      toast.update(toastId, {
        render: error instanceof Error ? error.message : "Failed to update locations",
        type: "error",
        isLoading: false,
        autoClose: 5000,
        closeButton: true
      });
      
      throw error;
    }
  }, [campaignId, baseUrl, getHeaders]);

  const fetchHomepageDataIfNeeded = useCallback(async () => {
    // Don't fetch if data exists, loading is in progress, or we've already tried and failed
    if (hasHomepageData || homepageData !== null || homepageDataLoading) return;
      
    setHomepageDataLoading(true);
    try {
      const headers = await getHeaders();
      const response = await apiClient.get(`${baseUrl}/googleads/campaign/${campaignId}/homepage`, { headers });
      setHomepageData({
        impressions: response.data.data.impressions,
        clicks: response.data.data.clicks,
        ctr: response.data.data.ctr,
        cost_micros: response.data.data.cost_micros,
        all_conversions: response.data.data.all_conversions,
        status: response.data.data.status
      });
      setHasHomepageData(true);
    } catch (error) {
      console.error('Error fetching analytics:', error);
      toast.error('Failed to fetch analytics data');
      // Set flag to indicate we've attempted the fetch, even if it failed
      setHasHomepageData(true);
    } finally {
      setHomepageDataLoading(false);
      setHasHomepageData(true); // this is kinda fucked but causes it to spaz
    }
  }, [campaignId, hasHomepageData, homepageData, homepageDataLoading]);

  const fetchKeywordsIfNeeded = useCallback(async () => {
    if (hasKeywords || keywordsLoading) return;

    setKeywordsLoading(true);

    try {
        const headers = await getHeaders();
        const response = await apiClient.get(`${baseUrl}/googleads/campaign/${campaignId}/keywords`, { headers });
        setPositiveKeywords(response.data.positive_keywords || []);
        setNegativeKeywords(response.data.blocked_keywords || []);
        setHasKeywords(true);
    } catch (error) {
        console.error('Error fetching keywords:', error);
        toast.error('Failed to fetch keywords');
    } finally {
        setKeywordsLoading(false);
    }

  }, [campaignId, hasKeywords, keywordsLoading]);

  const handleUpdateKeywords = async (positive: string[], negative: string[]) => {
    if (!campaignId) {
      toast.error('Unable to update keywords. Please try again.');
      return;
    } else if (!session?.access_token) {
      await refreshSession();
    }

    if (!session?.access_token) {
      toast.error("Network error please contact support");
      return;
    }

    const headers = {
      Authorization: `Bearer ${session!.access_token}`,
      'Content-Type': 'application/json',
    };

    try {
      const response = await apiClient.post(
        `${baseUrl}/googleads/campaign/keywords`,
        {
          campaign_id: campaignId,
          positive_keywords: positive,
          blocked_keywords: negative
        },
        { headers }
      );

      if (response.status === 200) {
        setPositiveKeywords(positive);
        setNegativeKeywords(negative);
        toast.success('Keywords updated successfully');
      } else {
        throw new Error('Unexpected response status');
      }
    } catch (error) {
      console.error('Error updating keywords:', error);
      if (axios.isAxiosError(error)) {
        console.error('Axios error details:', error.response?.data);
        toast.error(`Failed to update keywords: ${error.response?.data?.message || error.message}`);
      } else {
        toast.error('An unexpected error occurred while updating keywords');
      }
    }
  };

  const fetchCampaignStatusIfNeeded = useCallback(async () => {
    // Only fetch if we don't already have status data and aren't currently loading
    if (hasCampaignStatus || campaignStatusLoading) {
      return;
    }
  
    setCampaignStatusLoading(true);
  
    try {
      const headers = await getHeaders();
      const response = await apiClient.get(
        `${baseUrl}/googleads/campaign/${campaignId}/status`,
        { headers }
      );
  
      // Check that response has the expected structure
      if (response.data && response.data.status) {
        setCampaignStatus(response.data.status);
        setHasCampaignStatus(true);
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('Error fetching campaign status:', error);
      toast.error('Failed to fetch campaign status');
      // Still set hasCampaignStatus to true on error to prevent continuous retries
      setHasCampaignStatus(true);
    } finally {
      setCampaignStatusLoading(false);
    }
  }, [campaignId, hasCampaignStatus, campaignStatusLoading, getHeaders]);

  const updateBudget = useCallback(async (newBudgetUsdCents: number) => {
    if (!campaignId) {
      toast.error('Campaign ID is required to update budget');
      return false;
    }
    
    try {
      const headers = await getHeaders();
      const requestData: ApiUpdateBudgetRequest = {
        new_budget_usd_cents: newBudgetUsdCents
      };
  
      const response = await apiClient.put<ApiUpdateBudgetResponse>(
        `${baseUrl}/googleads/campaign/${campaignId}/budget`,
        requestData,
        { headers }
      );
  
      if (response.data.success) {
        // Update local budget state with the new pending budget
        setBudgetData(prevData => {
          if (!prevData) return null;
          return {
            ...prevData,
            pending_budget_usd_cents: response.data.budget_info.pending_budget_usd_cents,
            effective_date: response.data.budget_info.effective_date
          };
        });
  
        // Add to budget history if needed
        setBudgetHistory(prevHistory => [
          {
            budget_usd_cents: response.data.budget_info.pending_budget_usd_cents,
            effective_date: response.data.budget_info.effective_date
          },
          ...prevHistory
        ]);
        
        return true;
      } else {
        throw new Error('Budget update was not successful');
      }
    } catch (error) {
      console.error('Error updating budget:', error);
      
      let errorMessage = 'Failed to update budget';
      if (axios.isAxiosError(error)) {
        errorMessage = error.response?.data?.message || error.message;
      } else if (error instanceof Error) {
        errorMessage = error.message;
      }
      
      return false;
    }
  }, [campaignId, getHeaders, apiClient, baseUrl]);

  const fetchBudgetIfNeeded = useCallback(async () => {
    if (hasBudget || budgetLoading) return;

    setBudgetLoading(true);

    try {
        const headers = await getHeaders();
        const response = await apiClient.get(`${baseUrl}/googleads/campaign/${campaignId}/budget`, { headers });

        if (response.status === 200) {
            setSuggestedBudget(response.data.suggested_budget / 1000000);
            setBudgetData(response.data.budget_data.current_budget);
            setBudgetHistory(response.data.budget_data.budget_history);
            setHasBudget(true);
        } else {
            setHasBudget(true);
            throw new Error('Unexpected response status');
        }
    } catch (error) {
        console.error('Error fetching budget:', error);
        toast.error('Failed to fetch budget');
    } finally {
        setBudgetLoading(false);
    }
  }, [hasBudget, budgetLoading])
  
  const resetAllData = useCallback(() => {
    setHasHomepageData(false);
    setHomepageData(null);
    setHasCampaignStatus(false);

    setHasKeywords(false);

    setHasBudget(false);
    setHasPreview(false);

    setHasLocationData(false);
    setHasAdCreative(false);

    setHasSearchTerms(false);
  }, []);

  const setActiveCampaignFull = useCallback(async (campaign: Campaign) => {
    try {
      if (!campaign.isOnboarded) {
        setShowOnboarding(true);
      }
      setActiveCampaign(campaign);
      setCampaignId(campaign.id);
      setDomain(campaign.domain);
      localStorage.setItem(ACTIVE_CAMPAIGN_ID, campaign.id);
      resetAllData();
    } catch (error) {
      console.error('Error setting active campaign:', error);
      toast.error('Failed to set active campaign');
    }
  }, [resetAllData])

  const value = {
    homepageData,
    fetchHomepageDataIfNeeded,
    homepageDataLoading,
    positiveKeywords,
    negativeKeywords,
    fetchKeywordsIfNeeded,
    suggestedBudget,
    budgetData,
    budgetHistory,
    setBudgetData,
    keywordsLoading,
    headlines,
    descriptions,
    domain,
    campaignStatus,
    budgetLoading,
    previewLoading,
    setPreviewLoading,
    toggleCampaignStatus,
    resetAllData,
    setPositiveKeywords,
    setNegativeKeywords,
    handleUpdateKeywords,
    fetchBudgetIfNeeded,
    fetchPreviewIfNeeded,
    fetchCampaignStatusIfNeeded,
    fetchLocationDataIfNeeded,
    locationDataLoading,
    campaignStatusLoading,
    setLocationData,
    locationData,
    fetchAdCreativeIfNeeded,
    adCreativeLoading,
    setAdCreatives,
    adCreatives,
    handleSaveAdCreative,
    campaigns,
    handleFetchCampaignsIfNeeded,
    fetchedCampaigns,
    setActiveCampaign,
    activeCampaign,
    showOnboarding,
    setShowOnboarding,
    setActiveCampaignFull,
    hasCampaigns,
    loadingCampaigns,
    hasSearchTerms,
    searchTerms,
    searchTermsLoading,
    fetchSearchTermsIfNeeded,
    handleUpdateLocation,
    handleCreateCampaign,
    updateBudget
  };

  return (
    <CampaignContext.Provider value={value}>
      {children}
    </CampaignContext.Provider>
  );
};

export const useCampaign = () => {
  const context = useContext(CampaignContext);
  if (!context) {
    throw new Error('useCampaign must be used within a CampaignProvider');
  }
  return context;
};
