import React, { useState, useCallback } from 'react';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { Trash2, AlertCircle } from 'lucide-react';
import { GoogleMapsProvider } from '@/components/GoogleMapsProvider';
import AdLocationSelector from '@/components/AdLocationSelector';
import { useCampaign } from '@/contexts/CampaignContext';
import { toast } from 'react-toastify';
import { 
  LocationSelection, 
  isProximityLocationData,
  selectionToLocationData,
  LocationData
} from './page_types/location_data';

// Define the tab interface
interface Tab {
  value: string;
  label: string;
}

// Define props interface for CustomTabs
interface CustomTabsProps {
  tabs: Tab[];
  activeTab: string;
  setActiveTab: (value: string) => void;
}

// Custom simple tabs implementation with proper types
const CustomTabs: React.FC<CustomTabsProps> = ({ tabs, activeTab, setActiveTab }) => {
  return (
    <div className="flex mb-6 border-b">
      {tabs.map((tab) => (
        <button
          key={tab.value}
          className={`px-4 py-2 font-medium ${
            activeTab === tab.value 
            ? 'border-b-2 border-blue-500 text-blue-600' 
            : 'text-gray-500 hover:text-gray-700'
          }`}
          onClick={() => setActiveTab(tab.value)}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
};

const GoogleAdsLocationTool: React.FC = () => {
  // Since we're commenting out the performance tab, we only need targeting tab
  const [activeTab, setActiveTab] = useState('targeting');
  // We'll keep these state variables in case we want to uncomment the performance tab later
  const [selectedLocation, setSelectedLocation] = useState<LocationData | null>(null);
  const [timeFilter, setTimeFilter] = useState('1 Month');
  
  const {
    fetchLocationDataIfNeeded,
    handleUpdateLocation,
    locationData,
    locationDataLoading,
  } = useCampaign();

  const handleAddButtonClick = () => {
    if (selectedLocation) {

      const itemsToAdd: LocationData[] = [];

      if (selectedLocation.type == 'LOCATION') {
        itemsToAdd.push({
          type: 'LOCATION',
          geo_target_constant: selectedLocation.geo_target_constant
        });
      } else {
        itemsToAdd.push({
          type: 'PROXIMITY',
          coordinates: selectedLocation.coordinates,
          radius: selectedLocation.radius,
          unit: 'MILES'
        });
      }

      handleUpdateLocation({
        operation: 'ADD',
        itemsToAdd: itemsToAdd
      }).then(() => {
        // Clear the selected location after adding it (whether successful or not)
        setSelectedLocation(null);
      });
    }
  };

  // Handler for removing a location
  const handleRemoveButtonClick = (resourceName: string | undefined) => {
    if (!resourceName) return;
    
    // Find the location to remove
    const locationToRemove = locationData.find(
      loc => loc.resource_name === resourceName
    );
    
    if (!locationToRemove) {
      toast.error('Location not found');
      return;
    }
    
    // Set the status to REMOVED to indicate removal
    handleUpdateLocation({
      operation: 'REMOVE',
      itemsToRemove: [locationToRemove.resource_name]
    });
  };

  const handleLocationSelect = (
    location: LocationSelection
  ) => {
    // Check if the location type is compatible with existing locations
    if (locationData.length > 0) {
      const isProximity = location.type === 'PROXIMITY';
      const hasProximity = locationData.some(loc => loc.location_data.type === 'PROXIMITY');
      const hasLocation = locationData.some(loc => loc.location_data.type === 'LOCATION');
      
      if ((isProximity && hasLocation) || (!isProximity && hasProximity)) {
        toast.error("You can't mix location and radius targeting in the same campaign");
        return;
      }
    }
    
    setSelectedLocation(selectionToLocationData(location));
  };

  // Helper function to get the location type from the array
  const getCurrentTargetingType = () => {
    if (locationData.length === 0) return undefined;
    return locationData[0].location_data.type;
  };

  const renderTargetingTab = () => (
    <div className="space-y-6">
      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-xl font-bold mb-6">Location Targeting</h2>
        
        <div className="mb-6 p-4 bg-blue-50 border border-blue-200 rounded-lg flex items-start">
          <AlertCircle className="h-5 w-5 text-blue-500 mt-0.5 mr-2" />
          <div>
            <h4 className="font-semibold text-blue-800">Important: Targeting Type Restriction</h4>
            <p className="text-blue-700">
              You can only use one type of targeting (Location or Proximity) per campaign.
              {locationData.length > 0 && ` Current campaign is using ${locationData[0].location_data.type} targeting.`}
            </p>
          </div>
        </div>
        
        <div className="mb-4">
          <AdLocationSelector
            onLocationSelect={handleLocationSelect}
            currentTargetingType={getCurrentTargetingType()}
            disabled={locationDataLoading}
          />
        </div>
        
        {selectedLocation && (
          <div className="mt-4 flex justify-end">
            <Button
              onClick={handleAddButtonClick}
              className="bg-blue-500 hover:bg-blue-600 text-white"
              disabled={locationDataLoading}
            >
              Add Selected Location
            </Button>
          </div>
        )}
        
        <div className="mt-8">
          <h3 className="font-semibold mb-4">Current Targeting</h3>
          {locationDataLoading ? (
            <div className="flex justify-center items-center p-8">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
              <span className="ml-2">Loading location data...</span>
            </div>
          ) : (
            <div className="space-y-2">
              {locationData.length === 0 ? (
                <div className="p-4 text-center text-gray-500 bg-gray-50 border border-gray-200 rounded">
                  No locations selected yet. Use the selector above to add locations.
                </div>
              ) : (
                locationData.map(location => {
                  const locData = location.location_data;
                  const resourceName = location.resource_name;
                  
                  return (
                    <div 
                      key={resourceName} 
                      className="flex items-center justify-between p-3 bg-gray-50 border border-gray-200 rounded"
                    >
                      <div>
                        <span className="font-medium">{locData.canonical_name || "Unknown Location"}</span>
                        {isProximityLocationData(locData) && (
                          <span className="ml-2 text-sm text-gray-600">
                            ({locData.radius} {locData.unit?.toLowerCase() || 'miles'} radius)
                          </span>
                        )}
                        <span className="ml-2 text-xs text-gray-500">({locData.type})</span>
                      </div>
                      <div>
                        <Button 
                          variant="ghost" 
                          size="sm"
                          onClick={() => handleRemoveButtonClick(resourceName)}
                          className="text-red-500 hover:text-red-700"
                        >
                          <Trash2 className="h-4 w-4 mr-1" />
                          <span className="text-xs">remove</span>
                        </Button>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );

  /* Commented out the performance tab as requested
  const renderPerformanceTab = () => (
    <div className="space-y-6">
      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-xl font-bold mb-2">Location Performance</h2>
        <p className="text-gray-600 mb-4">Analyze performance across different geographic levels.</p>
        
        <div className="bg-gray-100 rounded-lg h-96 flex items-center justify-center mb-6">
          <div className="text-gray-500">
            [Map Visualization Placeholder]
          </div>
        </div>
        
        <div className="flex gap-2 mb-6">
          {['24 Hours', '1 Week', '1 Month'].map(filter => (
            <button
              key={filter}
              className={`px-4 py-2 rounded text-sm ${
                timeFilter === filter 
                ? 'bg-blue-500 text-white' 
                : 'border border-gray-300 hover:bg-gray-50'
              }`}
              onClick={() => setTimeFilter(filter)}
            >
              {filter}
            </button>
          ))}
        </div>
        
        {locationDataLoading ? (
          <div className="flex justify-center items-center p-8">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
            <span className="ml-2">Loading performance data...</span>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {locationData.length === 0 ? (
              <div className="col-span-2 p-4 text-center text-gray-500 bg-gray-50 border border-gray-200 rounded">
                No location data available. Add locations in the targeting tab.
              </div>
            ) : (
              locationData.map(location => {
                const locData = location.location_data;
                
                return (
                  <Card key={location.resource_name} className="overflow-hidden border rounded-lg">
                    <CardContent className="p-6">
                      <div className="mb-2 flex justify-between items-start">
                        <div>
                          <h3 className="text-lg font-bold">{locData.canonical_name || "Unknown Location"}</h3>
                          <div className="inline-block bg-gray-800 text-white text-xs px-2 py-1 rounded">
                            {locData.type}
                          </div>
                        </div>
                      </div>
                      
                      <div className="grid grid-cols-2 gap-y-4 mt-3">
                        <div>
                          <p className="text-sm text-gray-500">Conversions</p>
                          <p className="font-bold">{location.conversions}</p>
                        </div>
                        <div>
                          <p className="text-sm text-gray-500">Clicks</p>
                          <p className="font-bold">{location.clicks}</p>
                        </div>
                        {location.average_cost !== undefined && (
                          <div>
                            <p className="text-sm text-gray-500">Average Cost</p>
                            <p className="font-bold">${(location.average_cost / 1000000).toFixed(2)}</p>
                          </div>
                        )}
                        {location.average_cpc !== undefined && (
                          <div>
                            <p className="text-sm text-gray-500">Average CPC</p>
                            <p className="font-bold">${(location.average_cpc/1000000).toFixed(2)}</p>
                          </div>
                        )}
                      </div>
                    </CardContent>
                  </Card>
                );
              })
            )}
          </div>
        )}
      </div>
    </div>
  );
  */

  // Define the tabs array with only the targeting tab
  const tabs: Tab[] = [
    { value: 'targeting', label: 'View or Change Location Targeting' },
    // Commented out the performance tab
    // { value: 'performance', label: 'View Location-Level Performance' }
  ];

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-6">Multiply: Google Ads Location Tool</h1>
      
      <CustomTabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
      
      {/* Since we only have one tab now, we can just render the targeting tab */}
      {renderTargetingTab()}
      
      {/* Commented out the conditional rendering
      {activeTab === 'targeting' ? renderTargetingTab() : renderPerformanceTab()}
      */}
    </div>
  );
};

export default GoogleAdsLocationTool;