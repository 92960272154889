import React, { useState, useCallback, memo } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Menu, X } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useAuth } from "@/utils/AuthProvider";
import { cn } from "@/utils/utils";
import { navigationItems } from '@/types/navigation';
import { ConditionalCampaignNav } from '@/components/ConditionalCampaignNav';
import { loginRedirectUrl } from '@/types/constants';
import { Logo } from '@/components/ui/multiply-logo';

// Memoize SupportDialog to prevent rerenders
const SupportDialog = memo(({ onClose }: { onClose: () => void }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
    <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full mx-4">
      <div className="flex flex-col space-y-4">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold">Need Support?</h2>
          <button 
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X className="h-5 w-5" />
          </button>
        </div>
        <p className="text-gray-600">
          Please email support@trymultiply.com with any bugs or issues.
        </p>
        <button
          onClick={onClose}
          className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition-colors"
        >
          Got it
        </button>
      </div>
    </div>
  </div>
));

SupportDialog.displayName = 'SupportDialog';

interface NavBarProps {
  setShowOnboarding?: (show: boolean) => void;
}

// Memoize the entire MobileNav component to prevent rerenders
const MobileNavComponent = memo(({ 
  isNavOpen, 
  setIsNavOpen, 
  location, 
  handleNavClick, 
  handleSupportClick, 
  handleSignInOut, 
  session 
}: {
  isNavOpen: boolean;
  setIsNavOpen: (open: boolean) => void;
  location: ReturnType<typeof useLocation>;
  handleNavClick: (path: string) => void;
  handleSupportClick: () => void;
  handleSignInOut: () => void;
  session: any;
}) => (
  <>
    <nav className={cn(
      "fixed inset-y-0 left-0 bg-white border-r w-64 transform transition-transform duration-300 ease-in-out z-50 flex flex-col",
      isNavOpen ? "translate-x-0" : "-translate-x-full"
    )}>
      <div className="flex justify-between items-center p-4 border-b">
        <Link to="/" className="flex items-center gap-2" onClick={() => setIsNavOpen(false)}>
          <Logo className='h-8' />
          <span className="text-textPrimary text-lg">Multiply</span>
        </Link>
        <Button
          variant="ghost"
          size="sm"
          className="p-2"
          onClick={() => setIsNavOpen(false)}
        >
          <X className="h-5 w-5" />
        </Button>
      </div>

      {location.pathname.includes('/manage-campaign') && (
        <ul className="py-4 flex-1 overflow-y-auto">
          {navigationItems.map((item) => (
            <li key={item.id}>
              <button
                onClick={() => handleNavClick(`/manage-campaign?tab=${item.id}`)}
                className={cn(
                  "w-full text-left px-4 py-2 flex items-center space-x-2",
                  location.search.includes(item.id) ? "bg-blue-100 text-blue-600" : "hover:bg-gray-100"
                )}
              >
                <span>{item.icon}</span>
                <span>{item.label}</span>
              </button>
            </li>
          ))}
        </ul>
      )}

      <div className="border-t mt-auto">
        <button 
          onClick={handleSupportClick}
          className="w-full text-left px-6 py-3 hover:bg-gray-100 transition-colors text-gray-700"
        >
          Support
        </button>
        <button
          onClick={handleSignInOut}
          className="w-full text-left px-6 py-3 hover:bg-gray-100 transition-colors text-gray-700"
        >
          {session ? 'Sign Out' : 'Sign In'}
        </button>
      </div>
    </nav>
    {isNavOpen && (
      <div
        className="fixed inset-0 bg-black/20 z-40 md:hidden"
        onClick={() => setIsNavOpen(false)}
      />
    )}
  </>
));

MobileNavComponent.displayName = 'MobileNavComponent';

function NavBar({ setShowOnboarding }: NavBarProps) {
  const { session, signIn, signOut } = useAuth();
  const [showSupportDialog, setShowSupportDialog] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  // Memoize all handlers
  const handleNavClick = useCallback((path: string) => {
    setIsNavOpen(false);
    navigate(path);
  }, [navigate]);

  const handleSignInOut = useCallback(() => {
    setIsNavOpen(false);
    if (session) {
      signOut();
    } else {
      signIn({ url: loginRedirectUrl('') });
    }
  }, [session, signIn, signOut]);

  const handleSupportClick = useCallback(() => {
    setIsNavOpen(false);
    setShowSupportDialog(true);
  }, []);

  const closeSupportDialog = useCallback(() => {
    setShowSupportDialog(false);
  }, []);

  const openMobileNav = useCallback(() => {
    setIsNavOpen(true);
  }, []);

  // Use the memoized MobileNav component
  const MobileNav = useCallback(() => (
    <MobileNavComponent
      isNavOpen={isNavOpen}
      setIsNavOpen={setIsNavOpen}
      location={location}
      handleNavClick={handleNavClick}
      handleSupportClick={handleSupportClick}
      handleSignInOut={handleSignInOut}
      session={session}
    />
  ), [isNavOpen, location, handleNavClick, handleSupportClick, handleSignInOut, session]);

  return (
    <>
      <header className="bg-white border-b py-4 px-6 flex justify-between items-center w-full fixed top-0 left-0 z-40 shadow-sm h-[73px]">
        <div className="flex items-center">
          {location.pathname.includes('/manage-campaign') && (
            <Button
              variant="ghost"
              size="sm"
              className="mr-2 p-2 md:hidden"
              onClick={openMobileNav}
            >
              <Menu className="h-5 w-5" />
            </Button>
          )}
          <Link to="/" className='flex items-center gap-4'>
            <Logo className='h-10' />
            <span className='text-textPrimary text-xl hidden md:inline'>Multiply</span>
          </Link>
        </div>
        
        <div className="flex items-center gap-4">
          <ConditionalCampaignNav />
          <button 
            onClick={handleSupportClick}
            className="text-textPrimary hover:underline hidden md:inline-block"
          >
            Support
          </button>
          {!location.pathname.startsWith('/manage-campaign') && (
            <button
              onClick={handleSignInOut}
              className="text-textPrimary hover:underline cursor-pointer hidden md:block"
            >
              {session ? 'Sign Out' : 'Sign In'}
            </button>
          )}
        </div>
      </header>

      <MobileNav />

      {showSupportDialog && (
        <SupportDialog onClose={closeSupportDialog} />
      )}
    </>
  );
}

export default memo(NavBar);