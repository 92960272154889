import React from 'react';

interface BillingSummarySectionProps {
  currentBalance: number;
  nextPaymentDate: string;
  billingPeriod: string;
  cardLastFour: string;
  onUpdatePayment: () => void;
}

const BillingSummarySection: React.FC<BillingSummarySectionProps> = ({
  currentBalance,
  nextPaymentDate,
  billingPeriod,
  cardLastFour,
  onUpdatePayment
}) => {
  return (
    <div className="bg-purple-50 rounded-lg shadow p-6 mb-6">
      <div className="flex justify-between items-center mb-2">
        <h2 className="text-lg font-semibold">Billing summary</h2>
        <button className="text-gray-500 hover:text-gray-700">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-5 h-5">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </button>
      </div>
      <p className="text-sm text-gray-500 mb-6">{billingPeriod}</p>
      
      <div className="grid grid-cols-3 gap-6">
        <div className="flex flex-col">
          <div className="flex items-center mb-1">
            <h3 className="text-sm font-medium text-gray-500">Balance</h3>
            <button className="ml-2 text-gray-400 hover:text-gray-600">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </button>
          </div>
          <p className="text-2xl font-bold">${currentBalance.toFixed(2)} USD</p>
        </div>
        
        <div className="flex flex-col">
          <div className="flex items-center mb-1">
            <h3 className="text-sm font-medium text-gray-500">Next automatic payment</h3>
            <button className="ml-2 text-gray-400 hover:text-gray-600">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </button>
          </div>
          <p className="text-2xl font-bold">{nextPaymentDate}</p>
        </div>
        
        <div className="flex flex-col">
          <div className="flex items-center mb-1">
            <h3 className="text-sm font-medium text-gray-500">Payment method</h3>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <p className="text-lg">Ending in {cardLastFour}</p>
            </div>
            <button 
              onClick={onUpdatePayment}
              className="text-blue-600 hover:text-blue-800 font-medium"
            >
              Edit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingSummarySection;