// OnboardingContext.tsx
import React, { createContext, useContext, useState, useCallback, useEffect, useMemo, ReactNode, useRef } from 'react';
import { StepStatus } from '@/utils/Interfaces';
import { DOMAIN_KEY, ONBOARDING_STATE_KEY } from '@/types/constants';
import { OnboardingPreviewData } from '@/pages/page_types/ad_content';
import { LocationSelection } from '@/pages/page_types/location_data';

// Clean OnboardingState without suggestions
export interface OnboardingState {
  // General state
  currentStep: number;
  stepStatus: StepStatus;
  domain: string | null;
  
  // Step-specific data
  previewData: OnboardingPreviewData | null;
  locationData: LocationSelection[] | null;
  
  budgetData: {
    dailyBudget: number;
    setupIntentId: string | null;
    paymentMethodId: string | null;
  };
  
  googleAdsData: {
    googleAccountId: string | null;
    agreementsAccepted: boolean;
  };
  
  conversionData: {
    type: 'leads' | 'purchases';
    monthly: string;
    average: string;
    profit: string;
    conversionRate: number[];
  };
}

// Cleaned OnboardingContextType
interface OnboardingContextType {
  // Core state properties
  currentStep: number;
  stepStatus: StepStatus;
  domain: string | null;
  previewData: OnboardingPreviewData | null;
  locationData: LocationSelection[] | null;
  budgetData: OnboardingState['budgetData'];
  googleAdsData: OnboardingState['googleAdsData'];
  conversionData: OnboardingState['conversionData'];
  
  // Core functions
  setCurrentStep: (step: number) => void;
  updateStepStatus: (status: StepStatus) => void;
  updatePreviewData: (data: OnboardingState['previewData']) => void;
  updateLocationData: (data: LocationSelection[] | null) => void;
  updateBudgetData: (data: Partial<OnboardingState['budgetData']>) => void;
  updateGoogleAdsData: (data: Partial<OnboardingState['googleAdsData']>) => void;
  updateConversionData: (data: Partial<OnboardingState['conversionData']>) => void;
  isStepComplete: (step: number) => boolean;
  resetOnboarding: () => void;
}

const OnboardingContext = createContext<OnboardingContextType | undefined>(undefined);

const initialState: OnboardingState = {
  currentStep: 0,
  stepStatus: { isComplete: false },
  domain: null,
  previewData: null,
  locationData: null,
  budgetData: {
    dailyBudget: 10,
    setupIntentId: null,
    paymentMethodId: null,
  },
  googleAdsData: {
    googleAccountId: null,
    agreementsAccepted: false
  },
  conversionData: {
    type: 'leads',
    monthly: '',
    average: '',
    profit: '',
    conversionRate: [25]
  }
};

// Main OnboardingProvider without suggestions
export const OnboardingProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, setState] = useState<OnboardingState>(() => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const appState = urlParams.get('appState');
      console.log(`Application state: ${JSON.stringify(appState)}`);
      
      if (appState) {
        const parsedState = JSON.parse(decodeURIComponent(appState));
        return { ...initialState, ...parsedState };
      }
    } catch (error) {
      console.error('Failed to load state from URL:', error);
    }
    return initialState;
  });
  
  // Use a ref for state access in callbacks to avoid dependencies
  const stateRef = useRef(state);
  stateRef.current = state;
  
  // Set current step
  const setCurrentStep = useCallback((step: number) => {
    setState(prev => ({ ...prev, currentStep: step }));
  }, []);
  
  // All other update functions with empty dependency arrays
  const updateStepStatus = useCallback((status: StepStatus) => {
    setState(prev => ({ ...prev, stepStatus: status }));
  }, []);
  
  const updatePreviewData = useCallback((data: OnboardingState['previewData']) => {
    setState(prev => ({ ...prev, previewData: data }));
  }, []);
  
  const updateLocationData = useCallback((data: LocationSelection[] | null) => {
    setState(prev => ({ ...prev, locationData: data }));
    // For debugging
    console.log('Location data updated:', data);
  }, []);
  
  const updateBudgetData = useCallback((data: Partial<OnboardingState['budgetData']>) => {
    setState(prev => ({
      ...prev,
      budgetData: { ...prev.budgetData, ...data }
    }));
  }, []);
  
  const updateGoogleAdsData = useCallback((data: Partial<OnboardingState['googleAdsData']>) => {
    setState(prev => ({
      ...prev,
      googleAdsData: { ...prev.googleAdsData, ...data }
    }));
  }, []);
  
  const updateConversionData = useCallback((data: Partial<OnboardingState['conversionData']>) => {
    setState(prev => ({
      ...prev,
      conversionData: { ...prev.conversionData, ...data }
    }));
  }, []);
  
  // Use the stateRef to avoid dependencies on state
  const isStepComplete = useCallback((step: number): boolean => {
    // Use current ref value to avoid dependency on state
    const currentState = stateRef.current;
    
    switch (step) {
      case 0: // Ad Preview
        return !!currentState.previewData;
      case 1: // Location Targeting
        return !!currentState.locationData && currentState.locationData.length > 0;
      case 2: // Budget
        return currentState.budgetData.dailyBudget >= 10 && 
               !!currentState.budgetData.paymentMethodId;
      case 3: // Google Ads Connection
        return !!currentState.googleAdsData.googleAccountId && 
               !!currentState.googleAdsData.agreementsAccepted;
      case 4: // Conversion Profile
        return currentState.conversionData.monthly !== '' && 
               currentState.conversionData.average !== '' && 
               currentState.conversionData.profit !== '' && 
               (currentState.conversionData.type === 'purchases' || 
                (currentState.conversionData.type === 'leads' && 
                 currentState.conversionData.conversionRate.length > 0));
      default:
        return false;
    }
  }, []); // Empty dependency array
  
  // Reset with stateRef to avoid dependency
  const resetOnboarding = useCallback(() => {
    const domain = stateRef.current.domain;
    setState({ 
      ...initialState, 
      domain
    });
  }, []);
  
  // Memoize value to prevent unnecessary context updates
  const contextValue = useMemo(() => ({
    // State properties
    currentStep: state.currentStep,
    stepStatus: state.stepStatus,
    domain: state.domain,
    previewData: state.previewData,
    locationData: state.locationData,
    budgetData: state.budgetData,
    googleAdsData: state.googleAdsData,
    conversionData: state.conversionData,
    
    // Functions
    setCurrentStep,
    updateStepStatus,
    updatePreviewData,
    updateLocationData,
    updateBudgetData,
    updateGoogleAdsData,
    updateConversionData,
    isStepComplete,
    resetOnboarding
  }), [
    // State dependencies
    state.currentStep,
    state.stepStatus,
    state.domain,
    state.previewData,
    state.locationData,
    state.budgetData,
    state.googleAdsData,
    state.conversionData,
    
    // Stable functions
    setCurrentStep,
    updateStepStatus,
    updatePreviewData,
    updateLocationData,
    updateBudgetData,
    updateGoogleAdsData,
    updateConversionData,
    isStepComplete,
    resetOnboarding
  ]);
  
  return (
    <OnboardingContext.Provider value={contextValue}>
      {children}
    </OnboardingContext.Provider>
  );
};

// Base hook for accessing the onboarding context
export const useOnboarding = () => {
  const context = useContext(OnboardingContext);
  if (context === undefined) {
    throw new Error('useOnboarding must be used within an OnboardingProvider');
  }
  return context;
};

// Specialized hooks for component-specific needs
export const useLocationContext = () => {
  const context = useContext(OnboardingContext)!;
  
  return {
    locationData: context.locationData,
    updateLocationData: context.updateLocationData
  };
};

export const useBudgetContext = () => {
  const context = useContext(OnboardingContext)!;
  
  return {
    budgetData: context.budgetData,
    updateBudgetData: context.updateBudgetData
  };
};

// Other specialized hooks remain the same
export const useOnboardingStep = () => {
  const { currentStep, setCurrentStep, stepStatus, updateStepStatus } = useContext(OnboardingContext)!;
  return { currentStep, setCurrentStep, stepStatus, updateStepStatus };
};

export const useOnboardingPreview = () => {
  const { previewData, updatePreviewData } = useContext(OnboardingContext)!;
  return { previewData, updatePreviewData };
};

export const useConversionContext = () => {
  const context = useContext(OnboardingContext)!;
  return {
    conversionData: context.conversionData,
    updateConversionData: context.updateConversionData,
    domain: context.domain,
    locationData: context.locationData,
    budgetData: context.budgetData
  };
};

export const useGoogleAdsContext = () => {
  const context = useContext(OnboardingContext)!;
  return {
    googleAdsData: context.googleAdsData,
    updateGoogleAdsData: context.updateGoogleAdsData
  };
};