import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useAuth } from '@/utils/AuthProvider';

import BillingSummarySection from '@/components/BillingHistorySection';
import InvoiceHistorySection from '../components/InvoiceHistorySection';
import InvoiceDetailsModal from '../components/InvoiceDetailsModal';
import CheckoutForm from '../components/CheckoutForm';
import { ApiInvoice } from '@/types/api/BillingTypes';
import { useBillingApi } from '@/hooks/useBillingApi';
import { PaymentMethodResponse } from '@/types/api/BillingTypes';
import axios from 'axios';
import { baseUrl } from '@/services/api-config';

// Initialize Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_DETAILS!);

const StripeContainer: React.FC<React.PropsWithChildren> = ({ children }) => {
  const options = {
    fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Roboto' }],
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      {children}
    </Elements>
  );
};

interface SettingsPageProps {
  userProfileId?: string;
  balance?: number;
  nextPaymentDate?: Date;
  isLoading: boolean;
}

const SettingsPage: React.FC<SettingsPageProps> = ({
  userProfileId, 
  balance = 0, // Default to 0 if undefined
  nextPaymentDate,
  isLoading: initialIsLoading = true
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(initialIsLoading);
  const { session, refreshSession, signOut } = useAuth();
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodResponse | null>(null);
  const [selectedInvoice, setSelectedInvoice] = useState<ApiInvoice | null>(null);
  const [cardLastFour, setCardLastFour] = useState<string>('1234');
  
  const { fetchInvoices, isLoading: loadingInvoices } = useBillingApi();
  const [invoices, setInvoices] = useState<ApiInvoice[]>([]);

  // Format the nextPaymentDate into "Month Day" format (e.g., "June 6", "August 11")
  // Handle the case where nextPaymentDate is undefined
  const formattedPaymentDate = nextPaymentDate 
    ? nextPaymentDate.toLocaleDateString('en-US', { 
        month: 'long', 
        day: 'numeric' 
      })
    : 'Not scheduled';
  
  // Calculate billing period based on nextPaymentDate
  // Handle case where nextPaymentDate is undefined
  const calculateBillingPeriod = (date?: Date): string => {
    if (!date) return 'No active billing period';
    
    const endDate = new Date(date);
    const startDate = new Date(date);
    startDate.setDate(startDate.getDate() - 7); // One week before end date
    
    return `${startDate.toLocaleDateString('en-US', { 
      month: 'long', 
      day: 'numeric', 
      year: 'numeric' 
    })} - ${endDate.toLocaleDateString('en-US', { 
      month: 'long', 
      day: 'numeric', 
      year: 'numeric' 
    })}`;
  };
  
  // Use calculated billing period
  const [billingPeriod, setBillingPeriod] = useState<string>(
    calculateBillingPeriod(nextPaymentDate)
  );

  // Update billing period if nextPaymentDate changes
  useEffect(() => {
    setBillingPeriod(calculateBillingPeriod(nextPaymentDate));
  }, [nextPaymentDate]);

  // Fetch user data, payment method details, and invoices on component mount
  useEffect(() => {
    let isMounted = true;
    let fetchAttempted = false;

    const fetchData = async () => {
      if (fetchAttempted || !isMounted) return;
      fetchAttempted = true;
      
      if (!userProfileId) {
        toast.error('You do not have a userId assigned to your account. Please contact support@trymultiply.com');
        await signOut();
        return;
      }
      
      try {
        // Create a local variable to track if we've already refreshed the session
        // to prevent infinite loops
        let currentSession = session;
        let refreshAttempted = false;
        
        if (!currentSession?.access_token && !refreshAttempted) {
          refreshAttempted = true;
          currentSession = await refreshSession();
        }
        
        // Only proceed if we have a valid session after potential refresh
        if (currentSession?.access_token) {
          const headers = {
            Authorization: `Bearer ${currentSession.access_token}`,
            'Content-Type': 'application/json',
          };
          
          try {
            const paymentResponse = await axios.get<PaymentMethodResponse>(
              `${baseUrl}/api/payment-methods`,
              { headers }
            );
            
            if (isMounted) {
              setPaymentMethod(paymentResponse.data);
              if (paymentResponse.data) {
                setCardLastFour(paymentResponse.data.last4);
              }
            }
          } catch (paymentError) {
            console.error('Payment method fetch failed:', paymentError);
            // Continue execution instead of breaking the entire flow
          }
          
          try {
            const invoiceData = await fetchInvoices(userProfileId);
            if (isMounted) {
              setInvoices(invoiceData);
            }
          } catch (invoiceError) {
            console.error('Invoice fetch failed:', invoiceError);
            // Handle invoice error separately
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        if (isMounted) {
          toast.error('Failed to load user data');
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchData();
    
    return () => {
      isMounted = false;
    };
  }, [session, refreshSession, fetchInvoices, userProfileId, signOut]);

  const handleViewInvoiceDetails = (invoice: ApiInvoice) => {
    setSelectedInvoice(invoice);
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500" />
      </div>
    );
  }

  return (
    <div className="space-y-6 p-6 max-w-4xl mx-auto">
      <BillingSummarySection 
        currentBalance={balance}
        nextPaymentDate={formattedPaymentDate} // Pass the formatted string
        billingPeriod={billingPeriod}
        cardLastFour={cardLastFour}
        onUpdatePayment={() => setIsPaymentModalOpen(true)}
      />

      <InvoiceHistorySection 
        invoices={invoices}
        onViewDetails={handleViewInvoiceDetails}
      />

      <div className="bg-white shadow rounded-lg">
        <div className="p-6">
          <h3 className="text-xl font-semibold mb-4 text-red-600">Danger Zone</h3>
          <p className="text-gray-600 mb-4">
            These actions may have significant consequences for your account.
          </p>
          
          <div className="space-y-6">
            <div className="border-b pb-4">
              <h4 className="text-lg font-medium mb-2">Manage Google Ads Campaign</h4>
              <div className="flex justify-between items-center">
                <p className="text-gray-600">Pause or enable your campaign</p>
                <button
                  onClick={() => {}}
                  className="px-4 py-2 border border-red-500 text-red-500 rounded hover:bg-red-50"
                >
                  Pause campaign
                </button>
              </div>
            </div>
            
            <div className="border-b pb-4">
              <h4 className="text-lg font-medium mb-2">Manage Multiply Account</h4>
              <div className="flex justify-between items-center">
                <p className="text-gray-600">Delete your Multiply account</p>
                <button
                  onClick={() => setIsModalOpen(true)}
                  className="px-4 py-2 border border-red-500 text-red-500 rounded hover:bg-red-50"
                >
                  Cancel account
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Delete Account Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-md w-full">
            <h3 className="text-xl font-bold mb-4">
              Are you sure you want to delete your account?
            </h3>
            <p className="text-gray-600 mb-6">
              This action cannot be undone. This will permanently delete your account
              and remove your data from our servers.
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setIsModalOpen(false)}
                className="px-4 py-2 rounded bg-gray-300 text-black hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={() => {}}
                className="px-4 py-2 rounded bg-red-500 text-white hover:bg-red-600"
              >
                Yes, Delete My Account
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Payment Modal */}
      {isPaymentModalOpen && (
        <StripeContainer>
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg max-w-md w-full">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-bold">Set Up Weekly Payments</h3>
                <button
                  onClick={() => setIsPaymentModalOpen(false)}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">Close</span>
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <CheckoutForm
                onClose={() => setIsPaymentModalOpen(false)}
                existingPaymentMethod={paymentMethod ?? undefined}
              />
            </div>
          </div>
        </StripeContainer>
      )}

      {/* Invoice Details Modal */}
      {selectedInvoice && (
        <InvoiceDetailsModal 
          invoice={selectedInvoice} 
          onClose={() => setSelectedInvoice(null)} 
        />
      )}
    </div>
  );
};

export default SettingsPage;