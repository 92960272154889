import React, { useEffect, useState, useCallback, useRef } from 'react';
import { motion } from 'framer-motion';
import { Card, CardContent } from '@/components/ui/card';
import { AuthState, useAuth } from '@/utils/AuthProvider';
import GoogleButton from 'react-google-button';
import axios from 'axios';
import { baseUrl } from '@/services/api-config';
import { Button } from '@/components/ui/button';
import { useNavigate, useLocation } from 'react-router-dom';
import { GoogleAdsAccountSkeleton } from '@/components/ui/skeleton-loader';
import { onboardingRedirectURL } from '@/types/constants';
import DocumentOverlay from '@/components/DocumentOverlay';
import PrivacyPolicy from '@/documents/privacy_policy';
import TermsOfService from '@/documents/toc';
import { useGoogleAdsContext, useOnboarding } from '@/contexts/OnboardingContext';
import { useSuggestions } from '@/contexts/SuggestionsContext';

const GoogleAdsConnection: React.FC = () => {
  const { 
    session,
    signIn,
    authState,
    userStatus,
  } = useAuth();

  const {
    googleAdsData,
    updateGoogleAdsData
  } = useGoogleAdsContext()

  const {
    domain,
    currentStep,
    locationData,
    previewData
  } = useOnboarding();

  const {
    hasSuggestions,
    getLocationSuggestion,
    getBudgetSuggestion,
  } = useSuggestions();

  const [step, setStep] = useState<'signin' | 'loading' | 'select' | 'connected' | undefined>();
  const [accounts, setAccounts] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedAccount, setSelectedAccount] = useState<string | null>(null);
  const [isFetchingAccounts, setIsFetchingAccounts] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  
  // Add a ref to track if we've already fetched for this session
  const fetchedForSession = useRef<string | null>(null);
  // Track whether this component is mounted to avoid state updates after unmount
  const isMounted = useRef(true);

  // Add agreement state for Terms of Service and Privacy Policy
  const [agreements, setAgreements] = useState({
    privacyPolicy: false,
    termsOfService: false
  });

  // Add overlay state for document viewers
  const [overlayState, setOverlayState] = useState({
    privacyPolicy: false,
    termsOfService: false
  });

  // Set isMounted to false when component unmounts
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Check if user already has a connected account and skip this step if needed
  useEffect(() => {
    if (authState === AuthState.AUTHENTICATED && userStatus?.accountLinked) {
      console.log('Account already linked, skipping to next step');
      navigate('/onboarding?step=3');
    }
  }, [authState, userStatus, navigate]);

  const fetchGoogleAdsAccounts = useCallback(async (forceRefresh = false) => {
    if (!session?.access_token) {
      console.error("No tokens set in fetchGoogleAdsAccounts");
      return;
    }

    // Skip if we've already fetched for this session token and not forcing refresh
    if (!forceRefresh && fetchedForSession.current === session.access_token) {
      console.log("Already fetched accounts for this session, skipping");
      if (accounts.length > 0) {
        // If we already have accounts and we're skipping the fetch, make sure we're in the select state
        setStep('select');
      }
      return;
    }

    const headers = {
      Authorization: `Bearer ${session.access_token}`,
    };

    setError(null);
    setIsFetchingAccounts(true);

    try {
      const response = await axios.get(`${baseUrl}/account/get-google-ads-accounts`, { headers });
      if (isMounted.current) {
        const linkableAccounts = response.data?.linkable_accounts || [];
        setAccounts(linkableAccounts);
        
        // Only set step to 'select' if we actually got accounts
        if (linkableAccounts.length > 0) {
          setStep('select');
        } else {
          setError('No Google Ads accounts found. Please create a Google Ads account first.');
          setStep('signin');
        }
        
        // Mark that we've fetched for this session token
        fetchedForSession.current = session.access_token;
      }
    } catch (err) {
      if (isMounted.current) {
        console.error('Error fetching Google Ads accounts:', err);
        setError('Failed to fetch Google Ads accounts. Please try again.');
        setStep('signin');
      }
    } finally {
      if (isMounted.current) {
        setIsFetchingAccounts(false);
      }
    }
  }, [session?.access_token]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const currentStep = queryParams.get('step');
    console.log(`Current Step: [${currentStep}]`);

    const pathParts = location.pathname.split('/').filter(Boolean);
    const suffix = pathParts[pathParts.length - 1];

    if (suffix !== 'link-google-account') {
      setStep('signin');
      setError(null);
      return;
    }

    if (currentStep === 'loading') {
      setError(null);
      if (authState === AuthState.AUTHENTICATED) {
        // Skip to next step if account is already linked
        if (userStatus?.accountLinked) {
          navigate('/onboarding?step=3');
          return;
        }
        
        setStep('loading');
        // Use a slight delay to ensure state is updated before fetching
        const timer = setTimeout(() => {
          if (isMounted.current) {
            fetchGoogleAdsAccounts(false); // Pass false to avoid forcing refresh
          }
        }, 100);
        
        return () => clearTimeout(timer);
      } else {
        setStep('signin');
      }
    }
  }, [authState, location.search, fetchGoogleAdsAccounts, location.pathname, userStatus, navigate]);

  // Check for existing agreement acceptances
  useEffect(() => {
    // First check onboarding state
    if (googleAdsData.agreementsAccepted) {
      setAgreements({
        privacyPolicy: true,
        termsOfService: true
      });
    } 
  }, [googleAdsData.agreementsAccepted]);

  const handleLinkAdsAccount = async () => {
    if (!selectedAccount) {
      setError("Please select an account and ensure you're logged in");
      return;
    }

    if (!session) {
      setError("No Session found");
      return;
    }

    const domain = localStorage.getItem('domain');
    if (!domain) {
      setError("No domain set for application");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const headers = {
        Authorization: `Bearer ${session.access_token}`,
      };

      const response = await axios.post(
        `${baseUrl}/account/link-google-ads-account`,
        {
          g_account_id: selectedAccount,
        },
        { headers }
      );

      updateGoogleAdsData({
        googleAccountId: response.data.g_account_id
      });
      
      setStep('connected');
    } catch (error) {
      console.error('Error linking Google Ads account:', error);
      if (axios.isAxiosError(error)) {
        setError(error.response?.data?.message || 'Failed to link Google Ads account');
      } else {
        setError('Failed to link Google Ads account');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleAccountSelection = (accountId: string, billingApproved: boolean) => {
    if (billingApproved) {
      setSelectedAccount(accountId);
    }
  };

  // Add handlers for agreement changes and overlays
  const handleAgreementChange = (agreement: 'privacyPolicy' | 'termsOfService') => {
    const newAgreements = {
      ...agreements,
      [agreement]: !agreements[agreement]
    };
    
    setAgreements(newAgreements);
   
    // Update onboarding state if both agreements are accepted
    if (newAgreements.privacyPolicy && newAgreements.termsOfService) {
      updateGoogleAdsData({ agreementsAccepted: true });
    } else {
      updateGoogleAdsData({ agreementsAccepted: false });
    }
  };

  const handleOpenOverlay = (type: 'privacyPolicy' | 'termsOfService') => {
    setOverlayState(prev => ({ ...prev, [type]: true }));
  };

  const handleCloseOverlay = (type: 'privacyPolicy' | 'termsOfService') => {
    setOverlayState(prev => ({ ...prev, [type]: false }));
  };

  // 1. Update the signIn call in handleSignIn function
const handleSignIn = () => {
  if (agreements.privacyPolicy && agreements.termsOfService) {
    // Get current onboarding state
    const onboardingStateToPass = {
      currentStep: currentStep,
      previewData: previewData,
      locationData: locationData,
      domain: domain,
      googleAdsData: {
        agreementsAccepted: true
      }
    };

    // Get suggestions state if available
    const suggestionsStateToPass = {
      data: hasSuggestions ? {
        location_name: getLocationSuggestion()?.canonical_name,
        location_geotarget_id: getLocationSuggestion()?.geo_target_constant,
        suggested_budget: getBudgetSuggestion()
      } : null,
    };

    // Pass the states to the redirect URL
    signIn({ url: onboardingRedirectURL(onboardingStateToPass, suggestionsStateToPass) });
  } else {
    setError("Please accept the Terms of Service and Privacy Policy to continue");
  }
};

  const handleRefreshAccounts = () => {
    // Force a refresh even if we've already fetched for this session
    fetchGoogleAdsAccounts(true);
  };

  // If account is already linked, render a loading state while redirecting
  if (authState === AuthState.AUTHENTICATED && userStatus?.accountLinked) {
    return (
      <Card className="w-full max-w-md mx-auto">
        <CardContent className="p-6 text-center">
          <div className="flex flex-col items-center justify-center space-y-4">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              className="w-16 h-16 border-t-4 border-blue-500 border-solid rounded-full animate-spin"
            />
            <p className="text-gray-600">Account already linked, redirecting...</p>
          </div>
        </CardContent>
      </Card>
    );
  }

  // Show accounts if we have them, even if still technically fetching
  // This prevents the UI from going back to loading when refreshing
  const shouldShowAccounts = step === 'select' && accounts.length > 0;

  return (
    <Card className="w-full max-w-md mx-auto">
      <CardContent className="p-6">
        {step === 'signin' && (
          <div>
            <h2 className="text-2xl font-bold mb-4">Connect Your Google Ads Account</h2>
            <p className="text-gray-600 mb-4">
              Sign in with your Google account to connect Multiply with your Google Ads.
            </p>
            
            {/* Agreement checkboxes */}
            <div className="space-y-3 mb-4">
              <div className="flex items-start gap-2">
                <input
                  type="checkbox"
                  id="privacyPolicy"
                  checked={agreements.privacyPolicy}
                  onChange={() => handleAgreementChange('privacyPolicy')}
                  className="mt-1"
                />
                <label htmlFor="privacyPolicy" className="text-sm text-gray-600">
                  I have read and agree to the{' '}
                  <a 
                    href="#"
                    className="text-blue-600 hover:underline"
                    onClick={(e) => {
                      e.preventDefault();
                      handleOpenOverlay('privacyPolicy');
                    }}
                  >
                    Privacy Policy
                  </a>
                </label>
              </div>

              <div className="flex items-start gap-2">
                <input
                  type="checkbox"
                  id="termsOfService"
                  checked={agreements.termsOfService}
                  onChange={() => handleAgreementChange('termsOfService')}
                  className="mt-1"
                />
                <label htmlFor="termsOfService" className="text-sm text-gray-600">
                  I have read and agree to the{' '}
                  <a 
                    href="#"
                    className="text-blue-600 hover:underline"
                    onClick={(e) => {
                      e.preventDefault();
                      handleOpenOverlay('termsOfService');
                    }}
                  >
                    Terms of Service
                  </a>
                </label>
              </div>
            </div>
            
            {(!agreements.privacyPolicy || !agreements.termsOfService) && (
              <div className="mb-3 text-sm text-yellow-600">
                Please accept both the Privacy Policy and Terms of Service to continue.
              </div>
            )}
            
            <GoogleButton 
              onClick={handleSignIn}
              disabled={!agreements.privacyPolicy || !agreements.termsOfService}
              className={!agreements.privacyPolicy || !agreements.termsOfService ? "opacity-50" : ""}
            >
              Login with Google
            </GoogleButton>
            
            {error && <p className="text-red-500 mt-4">{error}</p>}
          </div>
        )}

        {(step === 'loading' && !shouldShowAccounts) && (
          <GoogleAdsAccountSkeleton />
        )}

        {shouldShowAccounts && (
          <div>
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-bold">Select Google Ads Account</h2>
              <Button 
                onClick={handleRefreshAccounts} 
                variant="outline" 
                size="sm"
                disabled={isFetchingAccounts}
                className="flex items-center gap-1"
              >
                {isFetchingAccounts ? (
                  <span className="w-4 h-4 border-t-2 border-blue-500 border-solid rounded-full animate-spin mr-1" />
                ) : (
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                  </svg>
                )}
                {isFetchingAccounts ? 'Refreshing...' : 'Refresh'}
              </Button>
            </div>
            
            {/* YouTube Video Link */}
            <div className="mb-4 p-3 bg-blue-50 border border-blue-200 rounded-lg flex items-center">
              <svg className="w-5 h-5 text-blue-500 mr-2 flex-shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z" />
              </svg>
              <span className="text-sm text-blue-700">
                Watch <a 
                  href="https://www.youtube.com/watch?v=vYduydbKckI" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="font-medium underline hover:text-blue-800"
                >
                  this video
                </a> for instructions on how to setup a Google Ads account
              </span>
            </div>
            
            <div className="space-y-2 mt-4 border rounded-lg overflow-hidden">
              {accounts.map((account, index) => (
                <label
                  key={account.id}
                  className={`flex items-center space-x-3 p-4 min-h-16 cursor-pointer ${
                    !account.billing_approved ? 'opacity-50 bg-gray-100' : 'hover:bg-gray-50'
                  } ${selectedAccount === account.id ? 'bg-blue-50' : ''} ${
                    index !== 0 ? 'border-t' : ''
                  }`}
                >
                  <input
                    type="radio"
                    name="googleAdsAccount"
                    value={account.id}
                    checked={selectedAccount === account.id}
                    onChange={() => handleAccountSelection(account.id, account.billing_approved)}
                    disabled={!account.billing_approved}
                    className="form-radio text-blue-600"
                  />
                  <div className="flex-1">
                    <div className="flex items-center space-x-2">
                      <img
                        src="/google-ads.svg"
                        alt="Google Ads"
                        className="w-6 h-6 flex-shrink-0"
                        onError={(e) => {
                            console.log("Image failed to load:", e);
                            console.log("Attempted URL:", e.currentTarget.src);
                            console.log("Base URL:", window.location.origin);
                          }
                        }
                      />
                      <span className="text-sm font-medium">{account.descriptive_name}</span>
                    </div>
                    {!account.billing_approved && (
                      <span className="text-sm text-red-500 block mt-1">
                        Billing not approved
                      </span>
                    )}
                  </div>
                </label>
              ))}
            </div>
            {error && <p className="text-red-500 mt-4">{error}</p>}
            <Button
              onClick={handleLinkAdsAccount}
              disabled={!selectedAccount || loading}
              className="mt-4 w-full bg-blue-600 text-white hover:bg-blue-700"
            >
              {loading ? 'Linking...' : 'Link Account'}
            </Button>
          </div>
        )}

        {step === 'connected' && (
          <div className="text-center">
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.5 }}
              className="w-20 h-20 bg-gray-800 rounded-full mx-auto mb-4 flex items-center justify-center"
            >
              <svg className="w-12 h-12 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
              </svg>
            </motion.div>
            <h2 className="text-2xl font-bold mb-2">Connected!</h2>
            <Button
              onClick={() => navigate('/onboarding?step=3')}
              className="mt-4 w-full bg-blue-600 text-white hover:bg-blue-700"
            >
              Continue to Billing Setup
            </Button>
          </div>
        )}
      </CardContent>

      {/* Document Overlays */}
      <DocumentOverlay
        isOpen={overlayState.privacyPolicy}
        onClose={() => handleCloseOverlay('privacyPolicy')}
        title="Privacy Policy"
      >
        <PrivacyPolicy />
      </DocumentOverlay>

      <DocumentOverlay
        isOpen={overlayState.termsOfService}
        onClose={() => handleCloseOverlay('termsOfService')}
        title="Terms of Service"
      >
        <TermsOfService />
      </DocumentOverlay>
    </Card>
  );
};

export default GoogleAdsConnection;