import axios, { AxiosInstance, AxiosError } from 'axios';
import { toast } from 'react-toastify';

// Define the interface for the auth service
interface AuthService {
  signOut: () => Promise<void> | Promise<boolean>;
  refreshSession?: () => Promise<any>; // Using 'any' here to be more flexible
  getSession?: () => any;
}

// Function to create and configure axios instance with interceptors
export const createApiClient = (authService: AuthService): AxiosInstance => {
  const apiClient = axios.create({
    baseURL: process.env.NEXT_PUBLIC_API_URL
  });

  // Response interceptor
  apiClient.interceptors.response.use(
    (response) => response,
    async (error: unknown) => {
      // Check if error is an Axios error
      if (axios.isAxiosError(error) && error.response) {
        // Check for the X-Logout-Required header in any response
        if (error.response.headers['x-logout-required'] === 'true') {
          // Show a message to the user
          toast.error('Your session has expired. Please sign in again.');
          
          // Logout the user
          await authService.signOut();
          
          // Redirect to login page (using window.location for a full page reload)
          window.location.href = '/login';
          
          // Return a rejected promise to stop the promise chain
          return Promise.reject(error);
        }
        
        // Also handle 307 redirects to homepage which might be auth-related
        if (error.response.status === 307 && error.response.headers['location'] === '/') {
          // This is likely an auth redirect - check for the header just to be sure
          if (error.response.headers['x-logout-required'] === 'true') {
            toast.error('Your session has expired. Please sign in again.');
            await authService.signOut();
          }
          
          // Let the browser handle the redirect
          window.location.href = '/';
          return Promise.reject(error);
        }
      }
      // For other errors, just pass them through
      return Promise.reject(error);
    }
  );
  
  return apiClient;
};