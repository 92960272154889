import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './utils/AuthProvider';
import { OnboardingProvider } from './contexts/OnboardingContext';
import { CampaignProvider } from './contexts/CampaignContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Layouts
import { ProtectedLayout } from './layouts/ProtectedLayout';
import { PublicLayout } from './layouts/PublicLayout';

// Pages
import OnboardingStartPage from './pages/OnboardingStartPage';
import ManageCampaignPage from './pages/ManageCampaignPage';
import OnboardingContainer from './components/OnboardingContainer';
import GoogleAuthFlowContainer from './pages/GoogleAuthFlowContainer';
import AuthCallback from './pages/AuthCallback';
import { DentistOfficeLandingPage, PersonalInjuryLandingPage } from './pages/VerticalLandingPage';
import PrivacyPolicy from './documents/privacy_policy';
import TermsOfService from './documents/toc';
import { DocumentPage } from './components/DocumentPage';
import { GoogleMapsProvider } from './components/GoogleMapsProvider';
import { Outlet } from 'react-router-dom';
import { SuggestionsProvider } from './contexts/SuggestionsContext';

// Create a layout component that provides the OnboardingContext
const OnboardingLayout = React.memo(() => {
  return (
    <OnboardingProvider>
      <SuggestionsProvider>
        <Outlet />
      </SuggestionsProvider>
    </OnboardingProvider>
  );
});

OnboardingLayout.displayName = 'OnboardingLayout';

// Create a layout component for campaign management
const CampaignLayout = React.memo(() => {
  return (
    <CampaignProvider>
      <Outlet />
    </CampaignProvider>
  );
});

CampaignLayout.displayName = 'CampaignLayout';

// GoogleMaps wrapper component
const GoogleMapsWrapper = React.memo(({ children }: { children: React.ReactNode }) => {

  return (
    <GoogleMapsProvider>
      {children}
    </GoogleMapsProvider>
  );
});

GoogleMapsWrapper.displayName = 'GoogleMapsWrapper';

const App: React.FC = () => {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          {/* Protected Routes - need campaign context */}
          <Route element={<CampaignLayout />}>
            <Route element={<ProtectedLayout />}>
              <Route 
                path="/manage-campaign" 
                element={
                  <GoogleMapsWrapper>
                    <ManageCampaignPage />
                  </GoogleMapsWrapper>
                } 
              />
              <Route path="/account/auth/callback" element={<AuthCallback />} />
            </Route>
          </Route>
          
          {/* Public Routes */}
          <Route element={<PublicLayout />}>
            {/* Onboarding-related routes with shared OnboardingProvider */}
            <Route element={<OnboardingLayout />}>
              <Route path="/" element={<OnboardingStartPage />} />
              <Route 
                path="/onboarding" 
                element={
                  <GoogleMapsWrapper>
                    <OnboardingContainer />
                  </GoogleMapsWrapper>
                } 
              />
              <Route 
                path="/onboarding/link-google-account" 
                element={<GoogleAuthFlowContainer />} 
              />
            </Route>
            
            {/* Static pages without onboarding context */}
            <Route path="/personal-injury-law" element={<PersonalInjuryLandingPage />} />
            <Route path="/dentistry" element={<DentistOfficeLandingPage />} />
            <Route path="/privacy-policy" element={
              <DocumentPage title="Privacy Policy">
                <PrivacyPolicy />
              </DocumentPage>
            } />
            <Route path="/terms-of-service" element={
              <DocumentPage title="Terms of Service">
                <TermsOfService />
              </DocumentPage>
            } />
          </Route>
        </Routes>
        <ToastContainer />
      </AuthProvider>
    </Router>
  );
};

export default App;