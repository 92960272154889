import React, { useState, useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { StepStatus } from '@/utils/Interfaces';
import { LocationData, LocationSelection } from "@/pages/page_types/location_data";
import { ApiConversionProfileLeads, ApiConversionProfilePurchases, ApiCreateCampaignRequest } from '@/types/api/CampaignTypes';
import GoogleAdPreviewOverlay from './GoogleAdPreviewOverlay';
import OnboardingOverlayStart from './OverlayStart';
import OverlayConversionProfile from './OverlayConversionProfile';
import OverlayLocationTargetingPage from './OverlayLocationTargetingPage';
import OverlaySetBudgetPage from './OverlaySetBudget';
import CampaignSummaryOverlay from './CreateCampaignStep';
import { useCampaign } from '@/contexts/CampaignContext';
import { toast } from 'react-toastify';

// Constants to avoid magic numbers
const ANIMATION_DURATION = 300;
const TRANSITION_DELAY = 50;
const STEP_TRANSITION_DELAY = 100;
const DEFAULT_BUDGET = 10;

type TransitionState = 'entering' | 'entered' | 'exiting' | 'exited';

interface OnboardingOverlayProps {
  isOpen: boolean;
  onClose: () => void;
  domain?: string;
}

interface OnboardingState {
  domain: string;
  locations: LocationSelection[] | null;
  conversionProfile: {
    conversionType: 'leads' | 'purchases';
    profile: ApiConversionProfileLeads | ApiConversionProfilePurchases;
  } | null;
  dailyBudget: number;
}

type StepComponent = React.ComponentType<any>;

const OnboardingOverlay: React.FC<OnboardingOverlayProps> = ({ 
  isOpen, 
  onClose, 
  domain
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [mounted, setMounted] = useState(false);
  const [isStepTransitioning, setIsStepTransitioning] = useState(false);
  const [transitionState, setTransitionState] = useState<TransitionState>('exited');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    handleCreateCampaign,
  } = useCampaign();
  
  const [onboardingState, setOnboardingState] = useState<OnboardingState>({
    domain: domain || '',
    locations: null,
    conversionProfile: null,
    dailyBudget: DEFAULT_BUDGET
  });

  const steps: StepComponent[] = [
    OnboardingOverlayStart,
    GoogleAdPreviewOverlay,
    OverlayLocationTargetingPage,
    OverlaySetBudgetPage,
    OverlayConversionProfile,
    CampaignSummaryOverlay
  ];

  useEffect(() => {
    let isMounted = true;
    
    if (isOpen) {
      setMounted(true);
      const animationFrame = requestAnimationFrame(() => {
        if (isMounted) setTransitionState('entering');
        const timeout = setTimeout(() => {
          if (isMounted) setTransitionState('entered');
        }, TRANSITION_DELAY);
        
        return () => clearTimeout(timeout);
      });
      
      // Only set URL params if this is an intentional opening (not a page refresh)
      if (!searchParams.has('onboardingStep') && !window.performance.navigation.type) {
        const newParams = new URLSearchParams(searchParams);
        newParams.set('onboardingStep', '0');
        newParams.set('overlayOpen', '1');
        setSearchParams(newParams, { replace: true });
      }
      
      return () => {
        isMounted = false;
        cancelAnimationFrame(animationFrame);
      };
    }
  }, [isOpen, searchParams, setSearchParams]);

  useEffect(() => {
    setOnboardingState({
      domain: domain || '',
      locations: null,
      conversionProfile: null,
      dailyBudget: DEFAULT_BUDGET
    });
  }, [domain]);

  const handleClose = useCallback(() => {
    setTransitionState('exiting');
    const timeout = setTimeout(() => {
      setTransitionState('exited');
      setMounted(false);
      const newParams = new URLSearchParams(searchParams);
      newParams.delete('onboardingStep');
      newParams.delete('overlayOpen');
      setSearchParams(newParams, { replace: true });
      onClose();
    }, ANIMATION_DURATION);
    
    return () => clearTimeout(timeout);
  }, [searchParams, setSearchParams, onClose]);

  const getCurrentStep = useCallback((): number => {
    const step = searchParams.get('onboardingStep');
    return step ? Math.min(parseInt(step, 10), steps.length - 1) : 0;
  }, [searchParams, steps.length]);

  const goToStep = useCallback((stepNumber: number) => {
    if (isStepTransitioning) return;
    setIsStepTransitioning(true);
    const newParams = new URLSearchParams(searchParams);
    newParams.set('onboardingStep', stepNumber.toString());
    newParams.set('overlayOpen', '1');
    setSearchParams(newParams);
    
    const timeout = setTimeout(() => setIsStepTransitioning(false), STEP_TRANSITION_DELAY);
    return () => clearTimeout(timeout);
  }, [searchParams, setSearchParams, isStepTransitioning]);

  const handleStartComplete = useCallback((url: string) => {
    setOnboardingState(prev => ({ ...prev, domain: url }));
    goToStep(1);
  }, [goToStep]);

  const handleLocationUpdate = useCallback((locations: LocationSelection[]) => {
    setOnboardingState(prev => ({ ...prev, locations }));
  }, []);

  const handleBudgetUpdate = useCallback((budget: number) => {
    setOnboardingState(prev => ({ ...prev, dailyBudget: budget }));
  }, []);

  const handleConversionUpdate = useCallback((conversionProfile: OnboardingState['conversionProfile']) => {
    setOnboardingState(prev => ({ ...prev, conversionProfile }));
  }, []);

  const handleStepComplete = useCallback((status: StepStatus) => {
    if (status.isComplete && !isStepTransitioning) {
      const currentStep = getCurrentStep();
      const nextStep = currentStep + 1;
      if (nextStep >= steps.length) {
        handleClose();
      } else {
        goToStep(nextStep);
      }
    }
  }, [getCurrentStep, goToStep, steps.length, isStepTransitioning, handleClose]);

  const createCampaign = useCallback(async () => {
    // Validate all required fields with specific error messages
    if (!onboardingState.domain) {
      toast.error('Please provide a domain');
      return;
    }
    if (!onboardingState.locations || onboardingState.locations.length === 0) {
      toast.error('Please select at least one location');
      return;
    }
    if (!onboardingState.conversionProfile) {
      toast.error('Please configure your conversion profile');
      return;
    }
    
    const campaignData: ApiCreateCampaignRequest = {
      domain: onboardingState.domain,
      locations: onboardingState.locations,
      budget: onboardingState.dailyBudget,
      profile: onboardingState.conversionProfile.profile,
    };
  
    try {
      setIsSubmitting(true);
      await handleCreateCampaign(campaignData);
      toast.success('Successfully Created a Campaign!');
      handleClose();
    } catch (error) {
      console.error('Error creating campaign:', error);
      
      // More specific error handling
      if (error instanceof Response) {
        if (error.status === 401) {
          toast.error('Your session has expired. Please log in again.');
        } else if (error.status === 429) {
          toast.error('You have reached the rate limit. Please try again later.');
        } else {
          toast.error(`Error (${error.status}): ${error.statusText || 'Failed to create campaign'}`);
        }
      } else {
        toast.error(error instanceof Error ? error.message : 'Failed to create campaign. Please try again.');
      }
    } finally {
      setIsSubmitting(false);
    }
  }, [onboardingState, handleCreateCampaign, handleClose]);

  const handlePrevStep = useCallback(() => {
    if (isStepTransitioning) return;
    const prevStep = Math.max(getCurrentStep() - 1, 0);
    setIsStepTransitioning(true);
    const newParams = new URLSearchParams(searchParams);
    newParams.set('onboardingStep', prevStep.toString());
    newParams.set('overlayOpen', '1');
    setSearchParams(newParams);
    
    const timeout = setTimeout(() => setIsStepTransitioning(false), STEP_TRANSITION_DELAY);
    return () => clearTimeout(timeout);
  }, [getCurrentStep, setSearchParams, searchParams, isStepTransitioning]);

  if (!mounted) return null;

  const currentStep = getCurrentStep();
  const CurrentPage = steps[currentStep];
  const isLastStep = currentStep === steps.length - 1;

  const stepProps = isLastStep ? {
    onStepComplete: handleStepComplete,
    onClose: handleClose,
    onCreateCampaign: createCampaign, // Fixed to use createCampaign instead of handleCreateCampaign
    isSubmitting,
    campaignData: {
      domain: onboardingState.domain,
      locations: onboardingState.locations, // Fixed to consistently use locations
      conversionProfile: onboardingState.conversionProfile,
      dailyBudget: onboardingState.dailyBudget
    }
  } : {
    url: onboardingState.domain,
    onStepComplete: handleStepComplete,
    onLocationUpdate: handleLocationUpdate,
    onBudgetUpdate: handleBudgetUpdate,
    onConversionUpdate: handleConversionUpdate,
    locations: onboardingState.locations, // Fixed to consistently use locations
    dailyBudget: onboardingState.dailyBudget,
    conversionProfile: onboardingState.conversionProfile
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div 
        className={`fixed inset-0 bg-black/50 transition-opacity duration-300 ${
          transitionState === 'entering' || transitionState === 'entered' 
            ? 'opacity-100' 
            : 'opacity-0'
        }`}
        onClick={handleClose}
      />
      <div className="relative min-h-screen flex items-center justify-center p-4">
        <div 
          className={`
            relative 
            bg-white 
            rounded-lg 
            shadow-xl 
            w-full 
            p-6 
            transition-all 
            duration-300 
            transform
            ${currentStep === 0 ? 'max-w-2xl' : 'max-w-4xl'}
            ${transitionState === 'entering' || transitionState === 'entered'
              ? 'opacity-100 scale-100 translate-y-0'
              : 'opacity-0 scale-95 translate-y-4'}
          `}
        >
          <button
            onClick={handleClose}
            className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 transition-colors"
            aria-label="Close dialog"
          >
            <span className="sr-only">Close</span>
            <span className="text-xl">×</span>
          </button>
          
          <div className="transition-opacity duration-300">
            {currentStep === 0 ? (
              <CurrentPage onComplete={handleStartComplete} />
            ) : (
              <CurrentPage {...stepProps} />
            )}
          </div>
          
          {currentStep > 0 && !isLastStep && (
            <div className="flex justify-between mt-8">
              <button
                onClick={handlePrevStep}
                className="px-4 py-2 rounded bg-gray-300 text-gray-500 hover:bg-gray-400 transition-colors"
                disabled={isStepTransitioning}
              >
                Previous
              </button>
              
              <button
                onClick={() => handleStepComplete({ isComplete: true })}
                className="px-4 py-2 rounded bg-primary hover:bg-primaryDark text-white transition-colors disabled:opacity-50"
                disabled={isStepTransitioning}
              >
                Next
              </button>
            </div>
          )}
          
          {currentStep > 0 && isLastStep && (
            <div className="flex justify-between mt-8">
              <button
                onClick={handlePrevStep}
                className="px-4 py-2 rounded bg-gray-300 text-gray-500 hover:bg-gray-400 transition-colors disabled:opacity-50"
                disabled={isStepTransitioning || isSubmitting}
              >
                Previous
              </button>
              
              {/* No "Finish" button on the last step as it has its own "Create Campaign" button */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OnboardingOverlay;