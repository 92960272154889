import React from 'react';
import NavBar from './NavBar';
import Footer from './Footer';

interface MainLayoutProps {
  children: React.ReactNode;
  setShowOnboarding?: (show: boolean) => void;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children, setShowOnboarding }) => {
  return (
    <div className="min-h-screen bg-background text-neutralDark flex flex-col">
      <NavBar setShowOnboarding={setShowOnboarding} />
      <main className="flex-1 pt-[73px]">
        {children}
      </main>
    </div>
  );
};

export default MainLayout;