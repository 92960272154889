import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth, AuthState } from '@/utils/AuthProvider';
import { useCampaign } from '@/contexts/CampaignContext';
import { toast } from 'react-toastify';
import { Loader2 } from 'lucide-react';

const LoadingScreen = () => (
  <div className="min-h-screen flex flex-col items-center justify-center bg-white">
    <div className="flex flex-col items-center space-y-6 max-w-sm mx-auto p-8 text-center">
      <img src="/aperture-thin.png" alt="Multiply" className="h-12 mb-2" />
      <div className="flex items-center justify-center">
        <Loader2 className="animate-spin h-6 w-6 text-blue-600 mr-2" />
      </div>
      <div className="space-y-2">
        <h2 className="text-xl font-semibold text-gray-900">
          Setting up your account
        </h2>
        <p className="text-gray-600">
          We're configuring your Multiply dashboard. This will only take a moment.
        </p>
      </div>
    </div>
  </div>
);

const AuthCallback = () => {
  const navigate = useNavigate();
  const hasNavigatedRef = useRef(false);
  
  // Track all loading states in one object to avoid dependency issues
  const [authState, setAuthState] = useState({
    userStatusFetched: false,
    campaignsFetched: false,
    navigationComplete: false
  });
  
  const { 
    authState: loginAuthState,
    userStatus,
    fetchUserStatus,
    session
  } = useAuth();
  
  const { 
    hasCampaigns, 
    fetchedCampaigns,
    handleFetchCampaignsIfNeeded,
    campaigns,
    loadingCampaigns
  } = useCampaign();
  
  // Set a flag to prevent circular redirects when navigating back to onboarding page
  useEffect(() => {
    // Set a flag in sessionStorage to indicate we're coming from the auth callback
    sessionStorage.setItem('from_auth_callback', 'true');
    
    // Cleanup function to remove the flag when component unmounts
    return () => {
      // We'll keep the flag to allow the OnboardingStartPage to use it once
    };
  }, []);
  
  // Step 1: Check authentication and fetch user status if needed
  useEffect(() => {
    // Prevent running if we've already completed this step or have navigated
    if (authState.userStatusFetched || authState.navigationComplete || hasNavigatedRef.current) {
      return;
    }
    
    const initUserStatus = async () => {
      console.log("Step 1: Initializing user status");
      
      // Only proceed if authenticated
      if (loginAuthState !== AuthState.AUTHENTICATED || !session?.access_token) {
        console.log('Not authenticated, redirecting to home');
        toast.error("Unable to authenticate. Please try again.");
        hasNavigatedRef.current = true;
        navigate('/');
        return;
      }
      
      // Skip if already fetched
      if (userStatus) {
        console.log("User status already available");
        setAuthState(prev => ({ ...prev, userStatusFetched: true }));
        return;
      }
      
      try {
        console.log("Loading user status...");
        await fetchUserStatus();
        console.log("User status loaded successfully");
        setAuthState(prev => ({ ...prev, userStatusFetched: true }));
      } catch (error) {
        console.error("Error loading user status:", error);
        toast.error("Failed to load account information");
        hasNavigatedRef.current = true;
        navigate('/');
      }
    };

    initUserStatus();
  }, [loginAuthState, session, userStatus, fetchUserStatus, navigate, authState.userStatusFetched, authState.navigationComplete]);
  
  // Step 2: Fetch campaigns after user status is available
  useEffect(() => {
    // Only proceed if user status is fetched and we haven't fetched campaigns yet
    if (!authState.userStatusFetched || authState.campaignsFetched || authState.navigationComplete || hasNavigatedRef.current) {
      return;
    }
    
    // Guard against missing user status
    if (!userStatus) {
      console.error('Cannot proceed: userStatus is not available');
      hasNavigatedRef.current = true;
      navigate('/');
      return;
    }
    
    const fetchCampaigns = async () => {
      console.log("Step 2: Fetching campaigns", { fetchedCampaigns, loadingCampaigns });
      
      // Skip if campaigns are already fetched or loading
      if (fetchedCampaigns) {
        console.log("Campaigns already fetched according to context");
        setAuthState(prev => ({ ...prev, campaignsFetched: true }));
        return;
      }
      
      if (loadingCampaigns) {
        console.log("Campaigns currently loading");
        return; // Just wait for loading to complete
      }
      
      try {
        console.log("Initiating campaign fetch");
        await handleFetchCampaignsIfNeeded();
        console.log("Campaign fetch completed");
        setAuthState(prev => ({ ...prev, campaignsFetched: true }));
      } catch (error) {
        console.error("Error loading campaigns:", error);
        toast.error("Failed to load campaign data");
        hasNavigatedRef.current = true;
        navigate('/');
      }
    };
    
    fetchCampaigns();
  }, [
    authState.userStatusFetched, 
    authState.campaignsFetched, 
    authState.navigationComplete,
    userStatus, 
    fetchedCampaigns, 
    loadingCampaigns, 
    handleFetchCampaignsIfNeeded, 
    navigate
  ]);
  
  // Step 3: Navigate based on the state of user status and campaigns
  useEffect(() => {
    // Avoid running if we're not ready or have already navigated
    if (!authState.userStatusFetched || !authState.campaignsFetched || 
        authState.navigationComplete || hasNavigatedRef.current || !fetchedCampaigns) {
      return;
    }
    
    console.log("Step 3: Ready to navigate", {
      userStatusFetched: authState.userStatusFetched,
      campaignsFetched: authState.campaignsFetched,
      hasUserStatus: !!userStatus,
      hasCampaigns,
      campaignsCount: campaigns?.length 
    });
    
    // Set navigation flags immediately to prevent duplicate navigation
    hasNavigatedRef.current = true;
    setAuthState(prev => ({ ...prev, navigationComplete: true }));
    
    // Guard against missing user status (additional safety check)
    if (!userStatus) {
      console.log("User Status not defined");
      navigate('/');
      return;
    }

    // Navigate based on user status and hasCampaigns
    if (!userStatus.oauth2Linked) {
      console.log('Navigating to link-google-account');
      navigate('/onboarding/link-google-account?step=signin');
    } else if (userStatus.accountLinked && userStatus.oauth2Linked && hasCampaigns) {
      console.log('Account linked and has campaigns, navigating to manage-campaign');
      navigate('/manage-campaign');
    } else {
      console.log('Account not fully set up or no campaigns, navigating to onboarding');
      toast.info('Please create a campaign');
      navigate('/');
    }
  }, [
    authState.userStatusFetched, 
    authState.campaignsFetched, 
    authState.navigationComplete,
    userStatus, 
    hasCampaigns, 
    campaigns, 
    navigate,
    fetchedCampaigns
  ]);
  
  return <LoadingScreen />;
};

export default AuthCallback;