import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import AdLocationSelector from '@/components/AdLocationSelector';
import { MapPin, Target, AlertCircle, X } from 'lucide-react';
import { StepStatus } from '@/utils/Interfaces';
import { Button } from '@/components/ui/button';
import { useLocationContext } from '@/contexts/OnboardingContext';
import { useSuggestions } from '@/contexts/SuggestionsContext';
import { LocationSelection, LocationType } from './page_types/location_data';
import AISuggestion from '@/components/AiSuggestion';

interface LocationTargetingPageProps {
  onStepComplete?: (status: StepStatus) => void;
  onPrevious?: () => void;
}

const LocationTargetingPage: React.FC<LocationTargetingPageProps> = ({ 
  onStepComplete,
  onPrevious 
}) => {
  // Get location data from onboarding context
  const { 
    locationData, 
    updateLocationData, 
  } = useLocationContext();
  
  // Get suggestion data directly from suggestions context
  const {
    isFetching: isFetchingSuggestions,
    getLocationSuggestion,
    markLocationSuggestionApplied,
    pollingCount,
    isMaxPollingReached,
    refetchSuggestions
  } = useSuggestions();
  
  const [hasInteracted, setHasInteracted] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<LocationSelection | undefined>(undefined);
  const [showSuggestion, setShowSuggestion] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);
  const [lastSeenSuggestion, setLastSeenSuggestion] = useState<LocationSelection | null>(null);
  const [dismissedSuggestion, setDismissedSuggestion] = useState(false);

  // Get the current location suggestion - only call once to avoid re-renders
  const locationSuggestion = getLocationSuggestion();

  // Initialize targeting type from existing data
  const [targetingType, setTargetingType] = useState<LocationType | undefined>(() => {
    return locationData && locationData.length > 0 ? locationData[0].type as LocationType : undefined;
  });

  // Update step completion status - only run when dependencies actually change
  useEffect(() => {
    if (hasInteracted || !locationData || locationData.length === 0) {
      onStepComplete?.({
        isComplete: !!(locationData && locationData.length > 0),
        message: !locationData || locationData.length === 0 ? 'Please select at least one location to continue' : undefined
      });
    }
  }, [locationData, onStepComplete, hasInteracted]);

  // React to changes in polling status - simplified
  useEffect(() => {
    // Only trigger refetch if we're completely out of polling attempts AND have no locations
    if (!locationData || locationData.length === 0) {
      if (isMaxPollingReached && !isFetchingSuggestions) {
        refetchSuggestions();
      }
    }
  }, [isMaxPollingReached, locationData, isFetchingSuggestions, refetchSuggestions]);

  // Track suggestion changes during polling - with safeguards to prevent loops
  useEffect(() => {
    if (!locationSuggestion) return;
    
    const suggestionJSON = JSON.stringify(locationSuggestion);
    const lastSeenJSON = lastSeenSuggestion ? JSON.stringify(lastSeenSuggestion) : null;
    
    // Only update if suggestion has changed
    if (suggestionJSON !== lastSeenJSON) {
      setLastSeenSuggestion(locationSuggestion);
      
      // If user hasn't selected a location yet and hasn't dismissed, show the suggestion panel
      if ((!locationData || locationData.length === 0) && !dismissedSuggestion) {
        setShowSuggestion(true);
      }
    }
  }, [locationSuggestion, lastSeenSuggestion, locationData, dismissedSuggestion]);

  // Hide suggestion once user has selected a location or dismissed it
  useEffect(() => {
    if (locationData && locationData.length > 0) {
      setShowSuggestion(false);
    } else if (locationSuggestion && !dismissedSuggestion) {
      // Only show suggestion panel when location is removed, suggestion exists, and wasn't dismissed
      setShowSuggestion(true);
    }
  }, [locationData, locationSuggestion, dismissedSuggestion]);

  const handleLocationSelect = useCallback((selected: LocationSelection) => {
    setHasInteracted(true);
    setSelectedLocation(selected);
    if (!locationData || locationData.length === 0) {
      setTargetingType(selected.type as LocationType);
    }
  }, [locationData]);

  const handleAddLocation = useCallback(() => {
    if (!selectedLocation) {
      toast.error('Please select a location');
      return;
    }

    if (locationData && locationData.length > 0 && locationData[0].type !== selectedLocation.type) {
      toast.error(`Cannot mix ${selectedLocation.type} and ${locationData[0].type} targeting types`);
      return;
    }

    setHasInteracted(true);
    
    // Check if this location is already added
    const isLocationAlreadyAdded = locationData?.some(location => 
      (location.canonical_name === selectedLocation.canonical_name && 
      location.type === selectedLocation.type)
    );
    
    if (isLocationAlreadyAdded) {
      toast.warning('This location is already added');
      return;
    }
    
    // Update location data by adding to the array
    const newLocationData = locationData ? [...locationData, selectedLocation] : [selectedLocation];
    updateLocationData(newLocationData);
    
    setSelectedLocation(undefined);
    toast.success(`${selectedLocation.type === 'LOCATION' ? 'Location' : 'Proximity'} target added successfully`);
  }, [selectedLocation, locationData, updateLocationData]);

  const handleRemoveLocation = useCallback((indexToRemove: number) => {
    if (!locationData) return;
    
    const newLocationData = locationData.filter((_, index) => index !== indexToRemove);
    
    if (newLocationData.length === 0) {
      setHasInteracted(false);
      setSelectedLocation(undefined);
      setTargetingType(undefined);
      updateLocationData(null);
      
      // Reset dismissal state when all locations are removed
      setDismissedSuggestion(false);
      setShowSuggestion(true); // Show suggestions again after removing all locations
      
      toast.info('All locations removed. Please select a new location to continue.');
      
      // Refetch suggestions when all locations are removed
      refetchSuggestions();
    } else {
      updateLocationData(newLocationData);
      toast.info('Location removed');
    }
  }, [locationData, updateLocationData, refetchSuggestions]);

  const handlePrevious = useCallback(() => {
    setSelectedLocation(undefined);
    setTargetingType(undefined);
    onPrevious?.();
  }, [onPrevious]);

  const handleApplySuggestion = useCallback(() => {
    if (!locationSuggestion) {
      return;
    }
    
    // Start fade out transition
    setFadeOut(true);
    
    // Create location selection from suggestion
    const newLocation: LocationSelection = {
      type: 'LOCATION',
      canonical_name: locationSuggestion.canonical_name,
      geo_target_constant: locationSuggestion.geo_target_constant,
    };
    
    // Wait for animation to complete before updating data
    setTimeout(() => {
      setHasInteracted(true);
      
      // Add the suggestion as a single location in an array
      updateLocationData([newLocation]);
      
      // Mark suggestion as applied in the component state
      setDismissedSuggestion(true);
      
      // Also notify the context that the suggestion was applied (for analytics)
      markLocationSuggestionApplied();
      
      setShowSuggestion(false);
      setFadeOut(false);
      setFadeIn(true);
      toast.success('AI location suggestion applied');
      
      // Reset fade-in animation after it completes
      setTimeout(() => {
        setFadeIn(false);
      }, 500);
    }, 300);
  }, [locationSuggestion, updateLocationData, markLocationSuggestionApplied]);

  const handleDismissSuggestion = useCallback(() => {
    // Start fade out transition
    setFadeOut(true);
    
    // Wait for animation to complete before hiding
    setTimeout(() => {
      setShowSuggestion(false);
      setDismissedSuggestion(true);
      setFadeOut(false);
      setFadeIn(true);
      
      // Reset fade-in animation after it completes
      setTimeout(() => {
        setFadeIn(false);
      }, 500);
    }, 300);
  }, []);

  const renderSuggestionContent = useCallback(() => {
    if (!locationSuggestion) return null;
    
    return (
      <>
        <div className="mb-3">
          <span className="text-sm text-gray-700">Recommended Location: </span>
          <span className="font-medium">{locationSuggestion.canonical_name}</span>
        </div>
      </>
    );
  }, [locationSuggestion, pollingCount]);
  
  // FIX: Updated condition to ensure loading UI doesn't get stuck
  const shouldShowOnlySuggestion = showSuggestion && !dismissedSuggestion && (
    isFetchingSuggestions || 
    (locationSuggestion && (!locationData || locationData.length === 0))
  );

  return (
    <div className="flex-1 p-8 max-w-6xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Location Targeting Setup</h1>
        <div className="flex items-center gap-3">
          {onPrevious && (
            <Button
              variant="outline"
              onClick={handlePrevious}
            >
              Previous
            </Button>
          )}
        </div>
      </div>
      
      {shouldShowOnlySuggestion && (
        <div 
          className={`mb-6 transition-opacity duration-300 ${fadeOut ? 'opacity-0' : 'opacity-100'}`}
        >
          <AISuggestion
            title="AI Location Recommendation"
            isLoading={isFetchingSuggestions && !isMaxPollingReached}
            suggestion={locationSuggestion || {}}
            renderContent={renderSuggestionContent}
            onApply={handleApplySuggestion}
            onDismiss={handleDismissSuggestion}
            description="This recommendation is based on analysis of your business type and customer demographics."
          />
        </div>
      )}
      
      {!shouldShowOnlySuggestion && (
        <div className={`transition-opacity duration-300 ${fadeIn ? 'opacity-100' : 'opacity-100'}`}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
            <div className="bg-white p-6 rounded-lg shadow-sm border">
              <div className="mb-4">
                <div className="flex items-center gap-2">
                  <MapPin className="h-5 w-5 text-blue-500" />
                  <h2 className="text-lg font-semibold">Target specific locations</h2>
                </div>
                <p className="text-sm text-gray-600 mt-2">
                  Select specific areas to target your ads, including:
                </p>
              </div>
              <div className="mt-4">
                <ul className="space-y-2 text-sm">
                  <li className="flex items-center gap-2">
                    <span className="h-1.5 w-1.5 rounded-full bg-blue-500"></span>
                    Zip/postal codes
                  </li>
                  <li className="flex items-center gap-2">
                    <span className="h-1.5 w-1.5 rounded-full bg-blue-500"></span>
                    Neighborhoods
                  </li>
                  <li className="flex items-center gap-2">
                    <span className="h-1.5 w-1.5 rounded-full bg-blue-500"></span>
                    Counties and cities
                  </li>
                  <li className="flex items-center gap-2">
                    <span className="h-1.5 w-1.5 rounded-full bg-blue-500"></span>
                    States and countries
                  </li>
                </ul>
              </div>
            </div>
      
            <div className="bg-white p-6 rounded-lg shadow-sm border">
              <div className="mb-4">
                <div className="flex items-center gap-2">
                  <Target className="h-5 w-5 text-blue-500" />
                  <h2 className="text-lg font-semibold">Target by radius</h2>
                </div>
                <p className="text-sm text-gray-600 mt-2">
                  Target a specific area around a central point
                </p>
              </div>
              <div className="mt-4">
                <p className="text-sm text-gray-600">
                  Define a radius around any location (e.g., 5 miles around Manhattan) to target potential customers in that area. Perfect for:
                </p>
                <ul className="mt-3 space-y-2 text-sm">
                  <li className="flex items-center gap-2">
                    <span className="h-1.5 w-1.5 rounded-full bg-blue-500"></span>
                    Local businesses
                  </li>
                  <li className="flex items-center gap-2">
                    <span className="h-1.5 w-1.5 rounded-full bg-blue-500"></span>
                    Service areas
                  </li>
                  <li className="flex items-center gap-2">
                    <span className="h-1.5 w-1.5 rounded-full bg-blue-500"></span>
                    Event locations
                  </li>
                </ul>
              </div>
            </div>
          </div>
      
          {targetingType && (
            <div className="mb-6 p-4 bg-blue-50 border border-blue-200 rounded-lg flex items-start">
              <AlertCircle className="h-5 w-5 text-blue-500 mt-0.5 mr-2" />
              <p className="text-blue-700">
                Your campaign is currently using {targetingType.toLowerCase()} targeting. 
                You can add multiple {targetingType.toLowerCase()} targets, but you cannot mix targeting types.
              </p>
            </div>
          )}
      
          <div className="bg-white p-6 rounded-lg shadow-sm border">
            {locationData && locationData.length > 0 && (
              <div className="mb-4">
                <h3 className="text-lg font-medium mb-3">Selected Locations</h3>
                <div className="space-y-2">
                  {locationData.map((location, index) => (
                    <div 
                      key={`${location.canonical_name}-${index}`}
                      className="flex justify-between items-start p-4 bg-gray-50 rounded-lg border"
                    >
                      <div>
                        <div className="font-medium">
                          {location.type === 'LOCATION' 
                            ? `Location Target: ${location.canonical_name}` 
                            : `Proximity Target: ${location.radius ?? 0} miles radius`}
                        </div>
                        {location.coordinates && (
                          <div className="text-sm text-gray-600">
                            Coordinates: {location.coordinates.lat.toFixed(4)}, {location.coordinates.lng.toFixed(4)}
                          </div>
                        )}
                      </div>
                      <Button
                        variant="ghost"
                        size="sm"
                        className="text-gray-500 hover:text-red-500"
                        onClick={() => handleRemoveLocation(index)}
                      >
                        <X className="h-4 w-4" />
                      </Button>
                    </div>
                  ))}
                </div>
              </div>
            )}
            
            <AdLocationSelector 
              onLocationSelect={handleLocationSelect}
              currentTargetingType={targetingType}
              disabled={false}
            />
      
            {selectedLocation && (
              <div className="mt-4 flex justify-end">
                <Button
                  onClick={handleAddLocation}
                  className="bg-blue-500 hover:bg-blue-600 text-white"
                >
                  Add Selected Location
                </Button>
              </div>
            )}
          </div>
      
          <div className="mt-6 bg-gray-50 p-6 rounded-lg border">
            <h4 className="text-lg font-semibold text-gray-900 mb-3">Quick Tips</h4>
            <ul className="space-y-2 text-sm text-gray-600">
              <li className="flex items-start gap-2">
                <span className="h-1.5 w-1.5 rounded-full bg-blue-500 mt-2"></span>
                <span>Use specific location targeting when you want to target entire administrative areas like cities or states</span>
              </li>
              <li className="flex items-start gap-2">
                <span className="h-1.5 w-1.5 rounded-full bg-blue-500 mt-2"></span>
                <span>Use radius targeting when you have a physical location and want to reach people within a specific distance</span>
              </li>
              <li className="flex items-start gap-2">
                <span className="h-1.5 w-1.5 rounded-full bg-blue-500 mt-2"></span>
                <span>You can add multiple locations of the same targeting type, but you cannot mix location and radius targeting</span>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default LocationTargetingPage;