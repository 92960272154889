import axios from 'axios';
import { baseUrl } from '@/services/api-config';

export interface FetchBillingMetadataResponse {
  user_profile_id: string;
  next_billing_date: string | null;
  current_bill_in_cents: number | null;
  estimated_weekly_bill_in_cents: number;
}

export interface FetchBillingMetadataRequestProps {
    userProfileId: string;
    token: string;
}

export class BillingApi {
  private static getHeaders(token: string) {
    return {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
  }

  public static async fetchBillingMetadata(request: FetchBillingMetadataRequestProps): Promise<FetchBillingMetadataResponse> {
    try {
      const response = await axios.get(
        `${baseUrl}/api/billing`, 
        { headers: this.getHeaders(request.token) }
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching billing metadata:', error);
      throw error;
    }
  }
}