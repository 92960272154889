import { useAuth } from "@/utils/AuthProvider";
import { baseUrl } from "@/services/api-config";
import axios from "axios";
import { useState, useCallback } from "react";
import { FetchBillingHistoryResponse, ApiInvoice } from "@/types/api/BillingTypes";

export function useBillingApi() {
  const { session, refreshSession } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const getHeaders = useCallback(async () => {
    if (!session?.access_token) {
      await refreshSession();
    }
    return {
      Authorization: `Bearer ${session?.access_token}`,
      'Content-Type': 'application/json',
    };
  }, [session, refreshSession]);

  const fetchInvoices = useCallback(async (userProfileId: string): Promise<ApiInvoice[]> => {
    setIsLoading(true);
    try {
      const headers = await getHeaders();
      const response = await axios.get<FetchBillingHistoryResponse>(
        `${baseUrl}/api/billing/${userProfileId}/invoices`,
        { headers }
      );
      return response.data.billing_history || [];
    } catch (error) {
      console.error("Error fetching invoices:", error);
      return [];
    } finally {
      setIsLoading(false);
    }
  }, [getHeaders]);

  return { fetchInvoices, isLoading };
}