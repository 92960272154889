import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import SetBudgetPage from '../pages/SetBudgetPage';
import LocationTargetingPage from '../pages/LocationTargetingPage';
import GoogleAdPreview from '../pages/AdPreviewPageV2';
import GoogleAdsConnection from '@/pages/GoogleAuthFlowContainer';
import ConversionProfile from '@/pages/ConversionProfile';
import { StepStatus } from '@/utils/Interfaces';
import { DOMAIN_KEY } from '@/types/constants';

const OnboardingContainer: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  
  // Memoize steps array to prevent recreation on each render
  const steps = useMemo(() => [
    GoogleAdPreview,
    LocationTargetingPage,
    GoogleAdsConnection,
    SetBudgetPage,
    ConversionProfile
  ], []);
  
  // Get the index of GoogleAdsConnection in the steps array
  const googleAdsConnectionIndex = useMemo(() => 
    steps.indexOf(GoogleAdsConnection), 
  [steps]);
  
  const [stepStatus, setStepStatus] = useState<StepStatus>({ isComplete: false });
  const [domain, setDomain] = useState<string>('');
  const [step, setStep] = useState(0);
  
  useEffect(() => {
    const storedDomain = localStorage.getItem(DOMAIN_KEY);
    if (!storedDomain) {
      navigate('/');
      return;
    }
    
    setDomain(storedDomain);
    
    const params = new URLSearchParams(location.search);
    const stepParam = params.get('step');
    if (stepParam !== null) {
      const newStep = parseInt(stepParam, 10);
      if (!isNaN(newStep) && newStep >= 0 && newStep < steps.length) {
        setStep(newStep);
      }
    } else {
      // If no step is provided in the URL, set it to 0 and update the URL
      setStep(0);
      navigate(`/onboarding?step=0`, { replace: true });
    }
  }, [location, navigate, steps.length]);
  
  // Memoize handlers to prevent recreation on each render
  const handleStepComplete = useCallback((status: StepStatus) => {
    setStepStatus(status);
  }, []);
  
  const isLastStep = useCallback(() => 
    step === steps.length - 1, 
  [step, steps.length]);
  
  const isNextButtonDisabled = useCallback(() => 
    !stepStatus.isComplete, 
  [stepStatus.isComplete]);
  
  // Check if current step is GoogleAdsConnection
  const isGoogleAdsConnectionStep = useCallback(() => 
    step === googleAdsConnectionIndex, 
  [step, googleAdsConnectionIndex]);
  
  const nextStep = useCallback(() => {
    if (!stepStatus.isComplete) return;
    
    const newStep = Math.min(step + 1, steps.length - 1);
    setStep(newStep);
    setStepStatus({ isComplete: false }); // Reset completion status for new step
    navigate(`/onboarding?step=${newStep}`);
  }, [step, steps.length, stepStatus.isComplete, navigate]);
  
  const prevStep = useCallback(() => {
    const newStep = Math.max(step - 1, 0);
    setStep(newStep);
    navigate(`/onboarding?step=${newStep}`);
  }, [step, navigate]);
  
  // Memoize the current page component
  const CurrentPage = useMemo(() => 
    steps[step], 
  [steps, step]);
  
  return (
    <div className="min-h-screen pb-20 relative">
      <div className="max-w-4xl mx-auto p-6">
        <CurrentPage url={domain} onStepComplete={handleStepComplete} />
      </div>
      <div className="fixed bottom-0 left-0 right-0 bg-white border-t">
        <div className="max-w-4xl mx-auto p-6 flex justify-between">
          {step > 0 ? (
            <button
              onClick={prevStep}
              className="px-4 py-2 rounded bg-gray-300 text-gray-500 hover:bg-gray-400"
            >
              Previous
            </button>
          ) : (
            <div />
          )}
          
          <div className="flex items-center gap-4">
            {!stepStatus.isComplete && stepStatus.message && (
              <span className="text-red-500 text-sm">{stepStatus.message}</span>
            )}
            {!isLastStep() && !isGoogleAdsConnectionStep() && (
              <button
                onClick={nextStep}
                disabled={isNextButtonDisabled()}
                className={`px-4 py-2 rounded text-white ${
                  isNextButtonDisabled()
                    ? 'bg-gray-400 cursor-not-allowed'
                    : 'bg-primary hover:bg-primaryDark'
                }`}
              >
                Next
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(OnboardingContainer);