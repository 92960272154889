import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { motion } from 'framer-motion';
import axios from 'axios';
import { baseUrl } from '@/services/api-config';
import { toast } from 'react-toastify';
import { StepStatus } from '@/utils/Interfaces';
import { useLocation } from 'react-router-dom';
import { useOnboardingPreview } from '@/contexts/OnboardingContext';

interface GoogleAdPreviewProps {
  url?: string;
  onStepComplete?: (status: StepStatus) => void;
}

const GoogleAdPreview: React.FC<GoogleAdPreviewProps> = ({ url, onStepComplete }) => {
  const { previewData, updatePreviewData } = useOnboardingPreview();

  const [isLoading, setIsLoading] = useState(true);
  const [loadingStep, setLoadingStep] = useState(1);
  const [currentAdIndex, setCurrentAdIndex] = useState(0);
  const [activeStep, setActiveStep] = useState(1);
  const location = useLocation();

  const previewRef = useRef<HTMLDivElement>(null);
  const optimizationRef = useRef<HTMLDivElement>(null);
  const getStartedRef = useRef<HTMLDivElement>(null);

  // Define handleScroll outside of useEffect with useCallback
  const handleScroll = useCallback(() => {
    const scrollPosition = window.scrollY + window.innerHeight;
    const buffer = 100;

    const optimizationPosition = optimizationRef.current?.offsetTop || 0;
    const getStartedPosition = getStartedRef.current?.offsetTop || 0;
    
    const documentHeight = document.documentElement.scrollHeight;
    const isNearBottom = documentHeight - scrollPosition < buffer;

    if (isNearBottom || scrollPosition >= getStartedPosition) {
      setActiveStep(3);
    } else if (scrollPosition >= optimizationPosition) {
      setActiveStep(2);
    } else {
      setActiveStep(1);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  // Update step completion based on previewData directly
  useEffect(() => {
    if (onStepComplete) {
      onStepComplete({
        isComplete: !!previewData,
        message: !previewData && (location.pathname.endsWith('?step=0') || location.pathname.includes('onboardingStep'))
          ? 'Please wait while we analyze your website' 
          : undefined
      });
    }
  }, [previewData, onStepComplete, location.pathname]);

  // Loading step animation
  useEffect(() => {
    if (isLoading) {
      const stepTimer = setTimeout(() => {
        setLoadingStep(2);
      }, 1500);
      
      return () => clearTimeout(stepTimer);
    }
  }, [isLoading]);

  const fetchPreviewData = useCallback(async () => {
    if (!url) {
      console.error("No URL provided");
      toast.error('No URL provided');
      setIsLoading(false);
      return;
    }
  
    try {
      // Add artificial delay to show the loading animation for at least 3 seconds
      const startTime = Date.now();
      
      const { data } = await axios.get(`${baseUrl}/getSiteData`, {
        params: { url },
        headers: {
          'accept': 'application/json'
        }
      });
  
      const { descriptions, headlines, keywords, businessName, alreadyOnboarded } = data;
      
      const newPreviewData = {
        businessName,
        adHeadings: headlines,
        adDescriptions: descriptions,
        keywords: keywords,
      };
      
      // Calculate elapsed time and add delay if needed
      const elapsedMs = Date.now() - startTime;
      const minLoadingTime = 3000; // 3 seconds minimum
      
      if (elapsedMs < minLoadingTime) {
        await new Promise(resolve => setTimeout(resolve, minLoadingTime - elapsedMs));
      }
      
      // Update the context with the new preview data
      updatePreviewData(newPreviewData);
    } catch (error) {
      toast.error('Error analyzing website. Please try again.');
      console.error('Error fetching site data:', error);
    } finally {
      setIsLoading(false);
    }
  }, [url, updatePreviewData]);
  
  useEffect(() => {
    // Only fetch if we don't already have the data
    console.log(`URL: [${url}] Preview Data: [${JSON.stringify(previewData)}]`);
    if (url && !previewData) {
      setIsLoading(true);
      fetchPreviewData();
    } else if (previewData) {
      setIsLoading(false);
    }
  }, [url, previewData, fetchPreviewData]);

  const handlePrevAd = useCallback(() => {
    if (!previewData) return;
    
    setCurrentAdIndex((prev) => 
      prev > 0 ? prev - 1 : (previewData?.adHeadings.length || 1) - 1
    );
  }, [previewData]);

  const handleNextAd = useCallback(() => {
    if (!previewData) return;
    
    setCurrentAdIndex((prev) => 
      prev < (previewData?.adHeadings.length || 1) - 1 ? prev + 1 : 0
    );
  }, [previewData]);

  // Memoize the navigation items
  const navigationItems = useMemo(() => [
    {
      number: 1,
      title: 'Ad previews',
      description: 'Take a look at the ads Multiply will generate for your business.'
    },
    {
      number: 2,
      title: 'Optimizations',
      description: 'Learn how Multiply will optimize your ads automatically.'
    },
    {
      number: 3,
      title: 'Ready to start',
      description: 'See how Multiply can help grow your business.'
    }
  ], []);

  // Loading state or no preview data
  if (isLoading || !previewData) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-blue-50 to-white w-full">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center max-w-md mx-auto w-full px-4"
        >
          <div className="flex justify-center mb-8">
            <div className="relative w-24 h-24">
              {/* Outer pulsing circle */}
              <motion.div 
                className="absolute inset-0 rounded-full border-4 border-blue-500 border-opacity-30"
                initial={{ scale: 0.9, opacity: 0.5 }}
                animate={{ 
                  scale: [0.9, 1.1, 0.9], 
                  opacity: [0.5, 0.2, 0.5] 
                }}
                transition={{ 
                  duration: 2, 
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              />
              
              {/* Main spinning loader */}
              <motion.div 
                className="absolute inset-0 border-4 border-blue-500 rounded-full"
                style={{ 
                  borderTopColor: 'transparent',
                  borderLeftColor: 'transparent'
                }}
                animate={{ rotate: 360 }}
                transition={{ 
                  duration: 2, 
                  repeat: Infinity, 
                  ease: "linear" 
                }}
              />
              
              {/* Inner dot */}
              <motion.div 
                className="absolute left-1/2 top-1/2 w-4 h-4 -ml-2 -mt-2 bg-blue-500 rounded-full"
                animate={{ 
                  scale: [1, 1.2, 1],
                }}
                transition={{ 
                  duration: 2, 
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              />
            </div>
          </div>

          <motion.h2 
            className="text-2xl font-semibold text-blue-700 mb-4"
            key={`loading-step-${loadingStep}`}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.5 }}
          >
            {loadingStep === 1 ? "Analyzing your Business" : "Preparing Ad Creative"}
          </motion.h2>

          <div className="w-full max-w-md mx-auto mb-5">
            <motion.div 
              className="w-full h-2 bg-gray-200 rounded-full overflow-hidden"
            >
              <motion.div 
                className="h-full bg-blue-500"
                initial={{ width: "30%" }}
                animate={{ width: loadingStep === 1 ? "50%" : "85%" }}
                transition={{ duration: 1, ease: "easeInOut" }}
              />
            </motion.div>
          </div>
          
          <motion.p 
            className="text-gray-600 mt-4"
            animate={{ 
              opacity: [0.7, 1, 0.7]
            }}
            transition={{ 
              duration: 2, 
              repeat: Infinity,
              ease: "easeInOut"
            }}
          >
            We're creating personalized ad content for your business...
          </motion.p>
        </motion.div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto p-6">
      <div className="flex gap-12">
        {/* Main Content */}
        <div className="flex-1">
          <h1 className="text-2xl font-semibold mb-2">
            Welcome to Multiply, {previewData.businessName}
          </h1>
          <p className="text-gray-600 mb-8">
            Multiply simplifies your advertising journey by automatically creating, managing, and
            optimizing your Google Ad campaigns. Let's take a look at what Multiply will do for you.
          </p>
  
          {/* Preview Section */}
          <Card className="mb-8">
            <CardContent className="pt-6">
              <div ref={previewRef}>
                <h2 className="text-lg font-semibold mb-3">Preview your Google ads</h2>
                <p className="text-gray-600 mb-4">
                  Here's a sneak peek of an ad that Multiply might run for your business. Multiply is trained on 
                  top-performing ad campaigns to generate high-quality ad creative that's designed to get you new customers.
                  You can always add, remove, or modify creative through your personal control dashboard.
                </p>
                
                <div className="mt-4">
                  <p className="text-sm text-gray-600 mb-2">Ex: Google Search Ad for {url}</p>
                  <motion.div
                    key={currentAdIndex}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                  >
                    <p className="text-sm text-gray-600">Ad {url}</p>
                    <h3 className="text-blue-600 font-medium">
                      {previewData.adHeadings[currentAdIndex]}
                    </h3>
                    <p className="text-sm">
                      {previewData.adDescriptions[currentAdIndex]}
                    </p>
                  </motion.div>
                  <div className="flex items-center justify-between mt-4">
                    <p className="text-sm text-gray-500">
                      Ad Preview {currentAdIndex + 1} of {previewData.adHeadings.length}
                    </p>
                    <div className="flex gap-2">
                      <Button variant="outline" size="sm" onClick={handlePrevAd}>
                        <ChevronLeft className="h-4 w-4" />
                      </Button>
                      <Button variant="outline" size="sm" onClick={handleNextAd}>
                        <ChevronRight className="h-4 w-4" />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
  
          {/* Optimization Section */}
          <Card className="mb-8">
            <CardContent className="pt-6">
              <div ref={optimizationRef}>
                <h2 className="text-lg font-semibold mb-3">How Multiply optimizes your ads</h2>
                <p className="text-gray-600 mb-4">
                  Multiply uses advanced analytics and AI to write different keywords and messages for
                  your ads in order to maximize conversions.
                </p>
                
                <p className="text-sm text-gray-600 mb-2">Example keywords for your business</p>
                <div className="flex flex-wrap gap-2">
                  {previewData.keywords.map((keyword, index) => (
                    <span 
                      key={index}
                      className="px-3 py-1 bg-green-50 text-green-700 rounded-full text-sm"
                    >
                      {keyword}
                    </span>
                  ))}
                </div>
              </div>
            </CardContent>
          </Card>
  
          {/* Get Started Section */}
          <Card className="mb-8">
            <CardContent className="pt-6">
              <div ref={getStartedRef}>
                <h2 className="text-lg font-semibold mb-3">Ready to get started</h2>
                <p className="text-gray-600 mb-4">
                  We'll help you reach more customers with automated, optimized ads.
                </p>
              </div>
            </CardContent>
          </Card>
        </div>
  
        {/* Side Navigation */}
        <div className="w-64">
          <div className="sticky top-6 flex">
            {/* Vertical Line */}
            <div className="mr-3 relative">
              <div className="absolute left-3 top-3 bottom-3 w-0.5 bg-gray-200" />
              <div 
                className="absolute left-3 top-3 w-0.5 bg-green-500 transition-all duration-300"
                style={{
                  height: `${(activeStep / 3) * 100}%`
                }}
              />
            </div>
  
            {/* Navigation Items */}
            <div className="space-y-8">
              {navigationItems.map((item) => (
                <div key={item.number} className="flex items-start gap-3">
                  <div 
                    className={`
                      flex-shrink-0 w-6 h-6 rounded-full flex items-center justify-center text-sm
                      transition-colors duration-300 z-10
                      ${item.number <= activeStep 
                        ? 'bg-green-500 text-white' 
                        : 'bg-gray-200 text-gray-600'}
                    `}
                  >
                    {item.number}
                  </div>
                  <div>
                    <h3 className="font-medium">{item.title}</h3>
                    <p className="text-sm text-gray-600">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogleAdPreview;