import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Loader2 } from 'lucide-react';
import { StepStatus } from '@/utils/Interfaces';
import { 
  ApiConversionProfileLeads, 
  ApiConversionProfilePurchases,
} from '@/types/api/CampaignTypes';
import { LocationData } from "@/pages/page_types/location_data";

interface CampaignSummaryProps {
  onStepComplete?: (status: StepStatus) => void;
  onClose: () => void;
  onCreateCampaign: () => Promise<void>;
  isSubmitting: boolean;
  campaignData: {
    domain: string;
    location: LocationData | null;
    conversionProfile: {
      conversionType: 'leads' | 'purchases';
      profile: ApiConversionProfileLeads | ApiConversionProfilePurchases;
    } | null;
    dailyBudget: number;
  };
}

const CampaignSummaryOverlay: React.FC<CampaignSummaryProps> = ({ 
  onStepComplete, 
  onCreateCampaign,
  isSubmitting,
  campaignData
}) => {
  useEffect(() => {
    console.log(`Campaign Data: [${JSON.stringify(campaignData)}]`);
    const isComplete = campaignData &&
                      campaignData.location && 
                      campaignData.conversionProfile && 
                      campaignData.domain && 
                      campaignData.dailyBudget > 0;
    
    if (onStepComplete) {
      onStepComplete({
        isComplete: false,
        message: !isComplete ? 'Missing required campaign information' : undefined
      });
    }
  }, [campaignData, onStepComplete]);

  if (!campaignData || !campaignData.conversionProfile || !campaignData.location) {
    return (
      <Card className="w-full max-w-2xl mx-auto">
        <CardContent className="p-6">
          <div className="text-center text-red-600">
            Missing required campaign information. Please complete all previous steps.
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="w-full max-w-2xl mx-auto">
      <CardHeader>
        <h2 className="text-2xl font-bold">Campaign Summary</h2>
        <p className="text-gray-600">Review your campaign details before creation</p>
      </CardHeader>
      <CardContent className="space-y-6">
        {/* Domain Information */}
        <div className="border-b pb-4">
          <h3 className="font-semibold mb-2">Domain</h3>
          <p className="text-gray-600">{campaignData.domain}</p>
        </div>

        {/* Budget Information */}
        <div className="border-b pb-4">
          <h3 className="font-semibold mb-2">Budget</h3>
          <p className="text-gray-600">Daily budget: ${campaignData.dailyBudget}</p>
        </div>

        {/* Location Information */}
        <div className="border-b pb-4">
          <h3 className="font-semibold mb-2">Location Targeting</h3>
          <p className="text-gray-600">
            {campaignData.location.canonical_name || 'Location information not available'}
          </p>
        </div>

        {/* Conversion Profile */}
        <div className="border-b pb-4">
          <h3 className="font-semibold mb-2">Conversion Profile</h3>
          <div className="space-y-2">
            <p className="text-gray-600">Type: {campaignData.conversionProfile.conversionType}</p>
            {'monthly_leads' in campaignData.conversionProfile.profile ? (
              <>
                <p className="text-gray-600">
                  Monthly leads: {campaignData.conversionProfile.profile.monthly_leads}
                </p>
                <p className="text-gray-600">
                  Average customer spend: ${campaignData.conversionProfile.profile.customer_spend}
                </p>
                <p className="text-gray-600">
                  Estimated profit: ${campaignData.conversionProfile.profile.estimated_profit}
                </p>
                <p className="text-gray-600">
                  Conversion rate: {campaignData.conversionProfile.profile.conversion_rate}%
                </p>
              </>
            ) : (
              <>
                <p className="text-gray-600">
                  Monthly sales: {campaignData.conversionProfile.profile.monthly_sales}
                </p>
                <p className="text-gray-600">
                  Average order value: ${campaignData.conversionProfile.profile.aov}
                </p>
                <p className="text-gray-600">
                  Estimated profit: ${campaignData.conversionProfile.profile.est_profit}
                </p>
              </>
            )}
          </div>
        </div>

        <Button 
          onClick={onCreateCampaign}
          className="w-full bg-blue-600 hover:bg-blue-700"
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <span className="flex items-center justify-center">
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Creating Campaign...
            </span>
          ) : (
            'Confirm & Create Campaign'
          )}
        </Button>
      </CardContent>
    </Card>
  );
};

export default CampaignSummaryOverlay;