// location_data.ts

export type LocationType = 'PROXIMITY' | 'LOCATION';
export type LocationRadiusUnit = 'MILES' | 'KILOMETERS';
export type LocationData = GeoLocationData | ProximityLocationData;

export interface Coordinates {
  lat: number;
  lng: number;
}

// Base location data with discriminator
interface LocationDataBase {
  type: LocationType;
  canonical_name?: string;
}

// Specific location data types
export interface GeoLocationData extends LocationDataBase {
  type: 'LOCATION';
  geo_target_constant: string;
}

export interface ProximityLocationData extends LocationDataBase {
  type: 'PROXIMITY';
  coordinates: Coordinates;
  radius: number;
  unit?: LocationRadiusUnit;
  address?: string;
}

// Union type for any location data

// Full location model with analytics
export interface IMultiplyLocation {
  resource_name: string;
  location_data: LocationData;
  
  // Common metadata
  address_type?: string;
  
  // Analytics fields
  average_cpm?: number;
  average_cpv?: number;
  average_cpe?: number;
  average_cpc?: number;
  average_cost?: number;
  clicks?: number;
  conversions?: number;
  cost_per_conversion?: number;
}

// Type guard functions
export function isGeoLocationData(data: LocationData): data is GeoLocationData {
  return data.type === 'LOCATION';
}

export function isProximityLocationData(data: LocationData): data is ProximityLocationData {
  return data.type === 'PROXIMITY';
}

// Selection interface for location picker
export interface LocationSelection {
  type: LocationType;
  canonical_name?: string;
  geo_target_constant?: string;
  coordinates?: Coordinates;
  radius?: number;
  unit?: LocationRadiusUnit;
  address?: string;
  resource_name?: string;
}

// Helper to convert selection to location data
export function selectionToLocationData(selection: LocationSelection): LocationData {
  if (selection.type === 'LOCATION' && selection.geo_target_constant) {
    return {
      type: 'LOCATION',
      geo_target_constant: selection.geo_target_constant,
      canonical_name: selection.canonical_name
    };
  } else if (selection.type === 'PROXIMITY' && selection.coordinates && selection.radius) {
    return {
      type: 'PROXIMITY',
      coordinates: selection.coordinates,
      radius: selection.radius,
      unit: selection.unit || 'MILES',
      address: selection.address,
      canonical_name: selection.canonical_name
    };
  }
  
  throw new Error(`Invalid location selection: ${JSON.stringify(selection)}`);
}

export interface FetchCampaignLocationsResponse {
  campaign_id: string;
  locations: IMultiplyLocation[];
}

export interface IUpdateLocationsRequest {
  campaign_id: string;
  locations_to_add: LocationData[];
  locations_to_remove: string[];
}

export interface IUpdateLocationsResponse {
  success: boolean;
}