// components/GoogleMapsProvider.tsx
import React from 'react';
import { LoadScript, Libraries } from '@react-google-maps/api';
import { Card, CardContent } from '@/components/ui/card';

const libraries: Libraries = ['places'];

interface GoogleMapsProviderProps {
  children: React.ReactNode;
}

export const GoogleMapsProvider: React.FC<GoogleMapsProviderProps> = React.memo(({ children }) => {
  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY!}
      libraries={libraries}
    >
      {children}
    </LoadScript>
  );
});

GoogleMapsProvider.displayName = 'GoogleMapsProvider';