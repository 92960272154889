import React, { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { StepStatus } from '@/utils/Interfaces';
import { Button } from '@/components/ui/button';
import { LocationType, LocationSelection } from '../page_types/location_data';
import {
  LocationDisplay,
  LocationTargetingTips,
  LocationTypesInfo,
  LocationWarning,
  LocationSelectorSection,
  canAddLocation
} from '@/components/locations/SharedComponents';

interface LocationTargetingPageProps {
  onStepComplete?: (status: StepStatus) => void;
  onPrevious?: () => void;
  onLocationUpdate: (location: LocationSelection | null) => void;
  initialLocation?: LocationSelection;
}

const OverlayLocationTargetingPage: React.FC<LocationTargetingPageProps> = ({ 
  onStepComplete,
  onPrevious,
  onLocationUpdate,
  initialLocation = undefined
}) => {
  const [location, setLocation] = useState<LocationSelection | undefined>(initialLocation);
  const [hasInteracted, setHasInteracted] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<LocationSelection | null>(null);
  const [targetingType, setTargetingType] = useState<LocationType | undefined>(
    initialLocation?.type ?? undefined
  );

  // Update step completion status
  const updateStepStatus = useCallback(() => {
    if (hasInteracted || !location) {
      onStepComplete?.({
        isComplete: !!location,
        message: !location ? 'Please select a location to continue' : undefined
      });
    }
  }, [location, onStepComplete, hasInteracted]);

  const handleLocationSelect = useCallback((selected: LocationSelection) => {
    setHasInteracted(true);
    setSelectedLocation(selected);
    if (!location) {
      setTargetingType(selected.type);
    }
    updateStepStatus();
  }, [location, updateStepStatus]);

  const handleAddLocation = useCallback(() => {
    if (!selectedLocation) {
      toast.error('Please select a location');
      return;
    }

    // Use the canAddLocation helper to check compatibility
    const existingLocations: LocationSelection[] = location ? [location] : [];
    const { canAdd, message } = canAddLocation(selectedLocation, existingLocations);
    
    if (!canAdd) {
      toast.error(message || 'Cannot mix location types');
      return;
    }

    setHasInteracted(true);
    setLocation(selectedLocation);
    onLocationUpdate(selectedLocation);
    setSelectedLocation(null);
    toast.success(`${selectedLocation.type === 'LOCATION' ? 'Location' : 'Proximity'} target added successfully`);
    updateStepStatus();
  }, [selectedLocation, location, onLocationUpdate, updateStepStatus]);

  const handleRemoveLocation = useCallback(() => {
    setHasInteracted(false);
    setLocation(undefined);
    setSelectedLocation(null);
    setTargetingType(undefined);
    onLocationUpdate(null);
    toast.success('Location target removed');
    updateStepStatus();
  }, [onLocationUpdate, updateStepStatus]);

  const handlePrevious = useCallback(() => {
    setLocation(undefined);
    setSelectedLocation(null);
    setTargetingType(undefined);
    onLocationUpdate(null);
    onPrevious?.();
  }, [onPrevious, onLocationUpdate]);

  return (
    <div className="flex-1 p-8 max-w-6xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Location Targeting Setup</h1>
        {onPrevious && (
          <Button
            variant="outline"
            onClick={handlePrevious}
          >
            Previous
          </Button>
        )}
      </div>
  
      {/* Use LocationTypesInfo shared component */}
      <LocationTypesInfo className="mb-8" />
  
      {/* Use LocationWarning shared component */}
      {targetingType && (
        <LocationWarning targetingType={targetingType} className="mb-6" />
      )}
  
      <div className="bg-white p-6 rounded-lg shadow-sm border">
        {/* Use LocationDisplay shared component */}
        {location && (
          <LocationDisplay 
            locations={[location]} 
            onRemoveLocation={() => handleRemoveLocation()}
            className="mb-4"
          />
        )}
        
        {/* Use LocationSelectorSection shared component */}
        <LocationSelectorSection
          onLocationSelect={handleLocationSelect}
          onAddLocation={handleAddLocation}
          selectedLocation={selectedLocation}
          currentTargetingType={targetingType}
          disabled={false}
        />
      </div>
  
      {/* Use LocationTargetingTips shared component */}
      <LocationTargetingTips className="mt-6" />
    </div>
  );
};

export default OverlayLocationTargetingPage;