import React, { useEffect } from 'react';
import { Sparkles, X, Loader2 } from 'lucide-react';
import { Button } from '@/components/ui/button';

interface AISuggestionProps<T> {
  /** The title of the suggestion */
  title: string;
  
  /** Whether suggestions are currently loading */
  isLoading: boolean;
  
  /** The specific suggestion to display */
  suggestion: T | null;
  
  /** Custom render function for suggestion content */
  renderContent: (suggestion: T) => React.ReactNode;
  
  /** Called when user applies the suggestion */
  onApply: () => void;
  
  /** Called when user dismisses the suggestion */
  onDismiss: () => void;
  
  /** Additional description text (optional) */
  description?: string;
  
  /** Whether to show action buttons (default: true) */
  showButtons?: boolean;
  
  /** Whether to auto-apply the suggestion (default: false) */
  autoApply?: boolean;
}

/**
 * A reusable component for displaying AI-generated suggestions
 * with consistent styling and behavior throughout the application.
 */
function AISuggestion<T>({
  title,
  isLoading,
  suggestion,
  renderContent,
  onApply,
  onDismiss,
  description,
  showButtons = true,
  autoApply = false
}: AISuggestionProps<T>) {
  // Auto-apply suggestion when it loads
  useEffect(() => {
    if (autoApply && !isLoading && suggestion) {
      onApply();
    }
  }, [autoApply, isLoading, suggestion, onApply]);

  if (!isLoading && !suggestion) {
    return null;
  }
  
  return (
    <div className="overflow-hidden transition-all duration-300 ease-in-out max-h-96">
      <div className="bg-gradient-to-r from-blue-50 to-indigo-50 border border-blue-200 rounded-lg p-5 shadow-sm transition-opacity duration-300">
        {isLoading ? (
          <div className="flex items-center justify-center py-6">
            <div className="flex flex-col items-center space-y-3">
              <Loader2 className="h-8 w-8 text-blue-500 animate-spin" />
              <p className="text-blue-700 font-medium">Analyzing your business data...</p>
              <p className="text-sm text-blue-600">This will only take a moment</p>
            </div>
          </div>
        ) : suggestion ? (
          <div className="flex flex-col space-y-4">
            <div className="flex items-start justify-between">
              <div className="flex items-center">
                <Sparkles className="h-5 w-5 text-amber-500 mr-2" />
                <h3 className="text-lg font-semibold text-blue-800">{title}</h3>
              </div>
              <Button 
                variant="ghost" 
                size="sm" 
                className="text-gray-500 hover:text-gray-700"
                onClick={onDismiss}
              >
                <X className="h-4 w-4" />
              </Button>
            </div>
            
            <div className="p-4 bg-white bg-opacity-70 rounded-md">
              <p className="text-blue-800 font-medium mb-2">Based on your business profile:</p>
              {renderContent(suggestion)}
              {description && (
                <div className="text-sm text-gray-600 italic mt-3">
                  {description}
                </div>
              )}
            </div>
            
            {showButtons && (
              <div className="flex justify-end pt-2">
                <Button
                  variant="outline"
                  size="sm"
                  className="mr-2"
                  onClick={onDismiss}
                >
                  Dismiss
                </Button>
                <Button
                  className="bg-blue-600 hover:bg-blue-700 text-white"
                  size="sm"
                  onClick={onApply}
                >
                  Apply Recommendation
                </Button>
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default AISuggestion;