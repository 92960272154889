import React, { createContext, useContext, useState, useCallback, useEffect, useMemo, ReactNode, useRef } from 'react';
import axios from 'axios';
import { baseUrl } from '@/services/api-config';
import { ApiOnboardingSuggestions } from '@/types/api/CampaignTypes';
import { LocationSelection } from '@/pages/page_types/location_data';
import { DOMAIN_KEY } from '@/types/constants';

// Constants for polling
const MAX_POLLING_ATTEMPTS = 4;
const POLLING_INTERVAL = 2000; // 2 seconds

// Suggestions state interface
interface SuggestionsState {
  data: ApiOnboardingSuggestions | null;
  status: 'idle' | 'loading' | 'success' | 'error';
  error: string | null;
  hasAppliedBudget: boolean;
  pollingCount: number;
  lastPolled: number | null;
}

// Suggestions context interface
interface SuggestionsContextType {
  // Status
  isFetching: boolean;
  error: string | null;
  hasSuggestions: boolean;
  
  // Getters for suggestions
  getLocationSuggestion: () => LocationSelection | null;
  getBudgetSuggestion: () => number | null;
  
  // Action handlers
  markLocationSuggestionApplied: () => void;
  markBudgetSuggestionApplied: () => void;
  
  // Polling state
  pollingCount: number;
  isMaxPollingReached: boolean;
  
  // Force refetch
  refetchSuggestions: () => void;
}

const SuggestionsContext = createContext<SuggestionsContextType | undefined>(undefined);

// Initial state
const initialState: SuggestionsState = {
  data: null,
  status: 'idle',
  error: null,
  hasAppliedBudget: false,
  pollingCount: 0,
  lastPolled: null
};

export const SuggestionsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, setState] = useState<SuggestionsState>(() => {
    // Try URL params first
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const suggestionsState = urlParams.get('suggestionsState');
      
      if (suggestionsState) {
        const parsedState = JSON.parse(decodeURIComponent(suggestionsState));
        console.log(`Loaded parsed State: [${suggestionsState}]`);
        return { ...initialState, ...parsedState };
      }
    } catch (error) {
      console.error('Failed to load suggestions state from URL:', error);
    }
    
    // Default to initial state
    return initialState;
  });
  
  // Use a ref for state access in callbacks to prevent stale closures
  const stateRef = useRef(state);
  stateRef.current = state;
  
  // Poll timer reference
  const pollTimerRef = useRef<NodeJS.Timeout | null>(null);
  
  // Track if component is mounted
  const isMountedRef = useRef(true);
  
  // Fetch suggestions function - memoized to prevent recreation
  const fetchSuggestions = useCallback(async (domainUrl: string) => {
    if (!domainUrl || !isMountedRef.current) return;
    
    // Guard against redundant fetches
    if (state.status === 'loading') {
      return;
    }
    
    // Update status to loading
    setState(prev => ({
      ...prev,
      status: 'loading',
      error: null,
      lastPolled: Date.now()
    }));
    
    console.log(`Polling for suggestions (attempt ${state.pollingCount + 1}/${MAX_POLLING_ATTEMPTS})`);
    
    try {
      const response = await axios.get(`${baseUrl}/suggestions?url=${encodeURIComponent(domainUrl)}`);
      
      if (!isMountedRef.current) return;
      
      if (response.status === 200 && response.data?.suggestions) {
        // Check if we have valid data in the response
        const hasLocationData = !!(
          response.data.suggestions.location_name && 
          response.data.suggestions.location_geotarget_id
        );
        
        // Only update state if component is still mounted
        setState(prev => ({
          ...prev,
          data: response.data.suggestions,
          status: 'success',
          error: null,
          pollingCount: prev.pollingCount + 1
        }));
        
        // Log if we have data
        console.log('Suggestions received:', response.data.suggestions);
        
        // If we have valid location data, stop polling
        if (hasLocationData) {
          console.log('Valid location data received, stopping polling');
        }
      } else {
        setState(prev => ({
          ...prev,
          status: 'error',
          error: 'Failed to retrieve suggestions',
          pollingCount: prev.pollingCount + 1
        }));
      }
    } catch (err) {
      if (!isMountedRef.current) return;
      
      setState(prev => ({
        ...prev,
        status: 'error',
        error: `Error fetching suggestions: ${err instanceof Error ? err.message : String(err)}`,
        pollingCount: prev.pollingCount + 1
      }));
    }
  }, [state.status, state.pollingCount]); // Only include the specific dependencies needed
  
  // Refetch suggestions (can be called externally)
  const refetchSuggestions = useCallback(() => {
    const domain = localStorage.getItem(DOMAIN_KEY);
    if (domain && isMountedRef.current) {
      // Reset polling count and status
      setState(prev => ({
        ...prev,
        status: 'idle',
        pollingCount: 0,
        lastPolled: null
      }));
      
      // Fetch suggestions
      setTimeout(() => {
        if (isMountedRef.current) {
          fetchSuggestions(domain);
        }
      }, 0);
    }
  }, [fetchSuggestions]);
  
  // Track component mount status
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);
  
  // Initial fetch when domain is set and component mounts
  useEffect(() => {
    const domain = localStorage.getItem(DOMAIN_KEY);
    if (domain && state.status === 'idle' && isMountedRef.current) {
      fetchSuggestions(domain);
    }
    
    // Cleanup on unmount
    return () => {
      if (pollTimerRef.current) {
        clearTimeout(pollTimerRef.current);
        pollTimerRef.current = null;
      }
    };
  }, [fetchSuggestions, state.status]);
  
  // Effect for polling logic - separated from the fetch logic
  useEffect(() => {
    const domain = localStorage.getItem(DOMAIN_KEY);
    
    // Check if we should stop polling based on having valid data
    const hasValidData = !!(
      state.data && 
      state.data.location_name && 
      state.data.location_geotarget_id
    );
    
    // Only continue polling if we don't have valid data yet
    if (
      domain && 
      isMountedRef.current &&
      state.pollingCount < MAX_POLLING_ATTEMPTS && 
      state.status !== 'loading' &&
      !hasValidData // Stop if we have valid data
    ) {
      // Clear any existing timer
      if (pollTimerRef.current) {
        clearTimeout(pollTimerRef.current);
        pollTimerRef.current = null;
      }
      
      // Set new timer for next poll only if we need to continue polling
      if (state.status !== 'error' && state.pollingCount < MAX_POLLING_ATTEMPTS) {
        pollTimerRef.current = setTimeout(() => {
          if (isMountedRef.current) {
            fetchSuggestions(domain);
          }
        }, POLLING_INTERVAL);
      }
    }
    
    // Cleanup timer on unmount or when conditions change
    return () => {
      if (pollTimerRef.current) {
        clearTimeout(pollTimerRef.current);
        pollTimerRef.current = null;
      }
    };
  }, [
    fetchSuggestions,
    state.pollingCount,
    state.status,
    state.data
  ]);
  
  // Suggestion retrieval methods using the ref to avoid stale closures
  const getLocationSuggestion = useCallback((): LocationSelection | null => {
    const currentState = stateRef.current;
    if (
      !currentState.data || 
      !currentState.data.location_geotarget_id || 
      !currentState.data.location_name
    ) {
      return null;
    }
    
    return {
      type: 'LOCATION',
      canonical_name: currentState.data.location_name,
      geo_target_constant: currentState.data.location_geotarget_id,
    };
  }, []);
  
  const getBudgetSuggestion = useCallback((): number | null => {
    const currentState = stateRef.current;
    if (
      !currentState.data || 
      !currentState.data.suggested_budget
    ) {
      return null;
    }
    
    return currentState.data.suggested_budget;
  }, []);
  
  // Action methods
  const markLocationSuggestionApplied = useCallback(() => {
    // We don't need to track this in the context anymore
    console.log('Location suggestion applied');
  }, []);
  
  const markBudgetSuggestionApplied = useCallback(() => {
    setState(prev => ({
      ...prev,
      hasAppliedBudget: true
    }));
  }, []);
  
  // Derived state calculations
  const isFetching = state.status === 'loading';
  const hasSuggestions = state.status === 'success' && !!state.data;
  const isMaxPollingReached = state.pollingCount >= MAX_POLLING_ATTEMPTS;
  
  // Context value
  const contextValue = useMemo(() => ({
    // Status
    isFetching,
    error: state.error,
    hasSuggestions,
    
    // Getters
    getLocationSuggestion,
    getBudgetSuggestion,
    
    // Actions
    markLocationSuggestionApplied,
    markBudgetSuggestionApplied,
    
    // Polling state
    pollingCount: state.pollingCount,
    isMaxPollingReached,
    
    // Force refetch
    refetchSuggestions
  }), [
    isFetching,
    state.error,
    hasSuggestions,
    getLocationSuggestion,
    getBudgetSuggestion,
    markLocationSuggestionApplied,
    markBudgetSuggestionApplied,
    state.pollingCount,
    isMaxPollingReached,
    refetchSuggestions
  ]);
  
  return (
    <SuggestionsContext.Provider value={contextValue}>
      {children}
    </SuggestionsContext.Provider>
  );
};

// Hook to use suggestions context
export const useSuggestions = () => {
  const context = useContext(SuggestionsContext);
  if (context === undefined) {
    throw new Error('useSuggestions must be used within a SuggestionsProvider');
  }
  return context;
};