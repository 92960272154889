import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { cn } from "@/utils/utils";
import { useSearchParams } from 'react-router-dom';
import ManageKeywords from './ManageKeywords';
import GoogleAdsBudgetPage from './ManageBudgetPage';
import GoogleAdsLocationPage from './ManageLocationsPage';
import SettingsPage from './SettingsPage';
import ManageAdContent from './ManageAdContent';
import { Card } from '@/components/ui/card';
import AnalyticsDashboard from '@/components/AnalyticsDashboard';
import OnboardingOverlay from './overlay/OnboardingOverlay';
import PageSkeletons from '@/components/ui/skeleton-loader';
import { navigationItems, NavigationItem } from '@/types/navigation';
import { useCampaign } from '@/contexts/CampaignContext';
import { useAuth } from '@/utils/AuthProvider';
import SearchTermsComponent from '@/components/SearchTerms';
import { BillingApi, FetchBillingMetadataResponse } from '@/services/BilllingApi';
import { toast } from 'react-toastify';

// Separate AdPreview component to handle ad navigation
const AdPreview: React.FC<{
  headlines: string[];
  descriptions: string[];
  domain: string;
  currentIndex: number;
  onNavigate: (direction: 'next' | 'prev') => void;
}> = React.memo(({ headlines, descriptions, domain, currentIndex, onNavigate }) => {
  if (!headlines.length || !descriptions.length || !domain) {
    return <p className="text-gray-500">No ad creative found</p>;
  }

  return (
    <div className="space-y-4">
      <p className="text-sm text-gray-500">Ad {currentIndex + 1} of {headlines.length}</p>
      <div>
        <p className="text-sm text-green-600">{domain}</p>
        <h4 className="text-blue-600 font-medium">
          {headlines[currentIndex]}
        </h4>
        <p className="text-sm text-gray-600">
          {descriptions[currentIndex]}
        </p>
      </div>
      <div className="flex justify-between mt-4">
        <button
          onClick={() => onNavigate('prev')}
          className="px-3 py-1 text-sm border rounded hover:bg-gray-50 transition-colors"
          disabled={headlines.length <= 1}
        >
          Previous
        </button>
        <button
          onClick={() => onNavigate('next')}
          className="px-3 py-1 text-sm border rounded hover:bg-gray-50 transition-colors"
          disabled={headlines.length <= 1}
        >
          Next
        </button>
      </div>
    </div>
  );
});

// Separate Analytics Content component
const AnalyticsContent: React.FC<{
  homepageData: any;
  adData: {
    headlines: string[];
    descriptions: string[];
    domain: string;
    currentIndex: number;
    onNavigate: (direction: 'next' | 'prev') => void;
  };
}> = React.memo(({ homepageData, adData }) => {
  if (!homepageData) return null;

  return (
    <div className="space-y-6">
      <AnalyticsDashboard 
        data={homepageData}
        adPreviews={{
          headlines: adData.headlines,
          descriptions: adData.descriptions,
          domain: adData.domain,
          currentIndex: adData.currentIndex,
          onNext: () => adData.onNavigate('next'),
          onPrevious: () => adData.onNavigate('prev')
        }}
      /> 
      <h2 className="text-xl font-semibold mb-4">Campaign overview</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <Card className="p-6">
          <h3 className="text-lg font-semibold mb-2">Ad previews</h3>
          <div className="border p-4 rounded">
            <AdPreview 
              headlines={adData.headlines}
              descriptions={adData.descriptions}
              domain={adData.domain}
              currentIndex={adData.currentIndex}
              onNavigate={adData.onNavigate}
            />
          </div>
        </Card>

        <SearchTermsComponent />
      </div>
    </div>
  );
});

// Memoized Navigation component
const SideNavigation = React.memo(({ selectedNav, onNavChange, isMobile = false }: {
  selectedNav: NavigationItem;
  onNavChange: (nav: NavigationItem) => void;
  isMobile?: boolean;
}) => (
  <div className={cn(
    "bg-white border-r flex flex-col z-10",
    isMobile ? 
      "fixed inset-y-0 left-0 w-64 transform transition-transform duration-300 ease-in-out" :
      "fixed left-0 top-[73px] w-64 h-[calc(100vh-73px)] hidden md:flex"
  )}>
    <div className="flex-1 overflow-y-auto">
      <ul className="py-4">
        {navigationItems.map((item) => (
          <li key={item.id}>
            <button
              onClick={() => onNavChange(item.id)}
              className={cn(
                "w-full text-left px-4 py-2 flex items-center space-x-2",
                selectedNav === item.id ? "bg-blue-100 text-blue-600" : "hover:bg-gray-100"
              )}
            >
              <span>{item.icon}</span>
              <span>{item.label}</span>
            </button>
          </li>
        ))}
      </ul>
    </div>
    <div className="flex-shrink-0 p-4 border-t text-xs text-gray-500">
      <a href="/privacy-policy" className="hover:underline">Privacy Policy</a>
      <span className="mx-2">·</span>
      <a href="/terms-of-service" className="hover:underline">Terms of Service</a>
    </div>
  </div>
));


const ManageCampaignPage: React.FC = () => {
  const {
    activeCampaign,
    campaignStatusLoading,
    homepageData,
    fetchHomepageDataIfNeeded,
    homepageDataLoading,
    fetchKeywordsIfNeeded,
    keywordsLoading,
    fetchBudgetIfNeeded,
    budgetLoading,
    headlines,
    descriptions,
    domain,
    fetchPreviewIfNeeded,
    previewLoading,
    fetchLocationDataIfNeeded,
    locationDataLoading,
    fetchAdCreativeIfNeeded,
    adCreativeLoading,
    handleFetchCampaignsIfNeeded,
    showOnboarding,
    setShowOnboarding,
    searchTermsLoading,
    fetchSearchTermsIfNeeded,
    fetchCampaignStatusIfNeeded
  } = useCampaign();

  const {
    fetchUserStatus,
    userStatus,
    session
  } = useAuth();

  const [selectedNav, setSelectedNav] = useState<NavigationItem>('overview');
  const [currentAdIndex, setCurrentAdIndex] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  
  // Billing state
  const [billingData, setBillingData] = useState<FetchBillingMetadataResponse | undefined>(undefined);
  const [nextBillingDate, setNextBillingDate] = useState<Date | undefined>(undefined);
  const [billingLoading, setBillingLoading] = useState(false);
  const [billingError, setBillingError] = useState<Error | null>(null);

  const isOverlayOpen = searchParams.get('overlayOpen') === '1';

  useEffect(() => {
  }, [])

  const handleCloseOverlay = useCallback(() => {
    searchParams.delete('overlayOpen');
    searchParams.delete('onboardingStep');
    setSearchParams(searchParams);
    setShowOnboarding(false);
  }, [searchParams, setSearchParams, setShowOnboarding]);

  const handleAdNavigation = useCallback((direction: 'next' | 'prev') => {
    setCurrentAdIndex(prev => {
      if (direction === 'next') {
        return prev < (headlines.length || 1) - 1 ? prev + 1 : 0;
      }
      return prev > 0 ? prev - 1 : (headlines.length || 1) - 1;
    });
  }, [headlines.length]);

  // Simple navigation change handler without mobile menu control
  const handleNavChange = useCallback((navItem: NavigationItem) => {
    setSelectedNav(navItem);
  }, []);

  // Billing data fetch function
  const fetchBillingData = useCallback(async () => {
    if (!session?.access_token || !userStatus?.userProfileId) {
      setBillingError(new Error('Missing required information'));
      setBillingLoading(false);
      return;
    }

    setBillingLoading(true);
    
    try {
      const data = await BillingApi.fetchBillingMetadata({
        token: session.access_token,
        userProfileId: userStatus.userProfileId
      });
      setBillingData(data);

      if (!data.next_billing_date) {
        toast.error('Error loading billing date');
        return;
      }

      setNextBillingDate(new Date(data.next_billing_date))
      setBillingError(null);

    } catch (err) {
      setBillingError(err instanceof Error ? err : new Error('Failed to fetch billing data'));
      toast.error('Could not load billing information');
    } finally {
      setBillingLoading(false);
    }
  }, [session?.access_token, userStatus?.userProfileId]);

  // Memoize loading state calculation
  const isLoading = useMemo(() => {
    switch (selectedNav) {
      case 'overview':
        return homepageDataLoading || previewLoading || campaignStatusLoading || searchTermsLoading;
      case 'keywords':
        return keywordsLoading;
      case 'budget':
        return budgetLoading;
      case 'locationTargeting':
        return locationDataLoading;
      case 'adContent':
        return adCreativeLoading;
      case 'settings':
        return billingLoading;
      default:
        return false;
    }
  }, [
    selectedNav,
    homepageDataLoading,
    previewLoading,
    campaignStatusLoading,
    keywordsLoading,
    budgetLoading,
    locationDataLoading,
    adCreativeLoading,
    searchTermsLoading,
    billingLoading
  ]);

  // Memoize ad data
  const adData = useMemo(() => ({
    headlines,
    descriptions,
    domain,
    currentIndex: currentAdIndex,
    onNavigate: handleAdNavigation
  }), [headlines, descriptions, domain, currentAdIndex, handleAdNavigation]);

  useEffect(() => {
    if (showOnboarding) {
      const newParams = new URLSearchParams(searchParams);
      if (!searchParams.has('overlayOpen') || !searchParams.has('onboardingStep')) {
        newParams.set('overlayOpen', '1');
        newParams.set('onboardingStep', '0');
        setSearchParams(newParams);
      }
    }
  }, [showOnboarding, searchParams, setSearchParams]);

  useEffect(() => {
    // Clear overlay params if they exist on page load/refresh
    if (searchParams.has('overlayOpen') || searchParams.has('onboardingStep')) {
      const newParams = new URLSearchParams(searchParams);
      newParams.delete('overlayOpen');
      newParams.delete('onboardingStep');
      setSearchParams(newParams, { replace: true }); // Use replace to avoid adding to history
      setShowOnboarding(false);
    }
  }, []);

  useEffect(() => {
    const initializePage = async () => {
      switch (selectedNav) {
        case 'overview':
          await Promise.all([
            fetchCampaignStatusIfNeeded(),
            fetchHomepageDataIfNeeded(),
            fetchPreviewIfNeeded(),
            fetchSearchTermsIfNeeded()
          ]);
          break;
        case 'keywords':
          await fetchKeywordsIfNeeded();
          break;
        case 'budget':
          await fetchBudgetIfNeeded();
          break;
        case 'locationTargeting':
          await fetchLocationDataIfNeeded();
          break;
        case 'adContent':
          await fetchAdCreativeIfNeeded();
          break;
        case 'settings':
          await fetchBillingData();
          break;
      }
      await handleFetchCampaignsIfNeeded();
    };

    initializePage();
  }, [
    selectedNav, 
    fetchHomepageDataIfNeeded, 
    fetchPreviewIfNeeded, 
    fetchKeywordsIfNeeded,
    fetchBudgetIfNeeded, 
    fetchLocationDataIfNeeded, 
    fetchAdCreativeIfNeeded,
    handleFetchCampaignsIfNeeded, 
    fetchSearchTermsIfNeeded,
    fetchBillingData
  ]);

  if (isLoading) {
    return (
      <>
        {/* Desktop-only sidebar */}
        <SideNavigation selectedNav={selectedNav} onNavChange={handleNavChange} />
        <div className="md:ml-64 p-4 md:p-8 min-h-[calc(100vh-73px)]">
          <PageSkeletons selectedNav={selectedNav} />
        </div>
      </>
    );
  }

  // Calculate formatted date for next payment if billing data exists
  const formattedNextPaymentDate = billingData?.next_billing_date 
    ? new Date(billingData.next_billing_date).toLocaleDateString('en-US', {
        weekday: 'long',
        month: 'long', 
        day: 'numeric',
        year: 'numeric'
      })
    : 'Not available';

  return (
    <>
      {/* Desktop-only sidebar */}
      <SideNavigation selectedNav={selectedNav} onNavChange={handleNavChange} />
      
      {/* Main Content Area */}
      <div className="md:ml-64 p-4 md:p-8 min-h-[calc(100vh-73px)]">
        {selectedNav === 'keywords' ? (
          <ManageKeywords />
        ) : selectedNav === 'adContent' ? (
          <ManageAdContent />
        ) : selectedNav === 'budget' ? (
          <GoogleAdsBudgetPage />
        ) : selectedNav === 'locationTargeting' ? (
          <GoogleAdsLocationPage />
        ) : selectedNav === 'settings' ? (
          <SettingsPage 
            userProfileId={userStatus?.userProfileId} 
            balance={(billingData?.estimated_weekly_bill_in_cents ?? 0) / 100}
            nextPaymentDate={nextBillingDate}
            isLoading={billingLoading}
          />
        ) : (
          <AnalyticsContent 
            homepageData={homepageData}
            adData={adData}
          />
        )}
      </div>
  
      <OnboardingOverlay 
        isOpen={isOverlayOpen}
        onClose={handleCloseOverlay}
        domain={domain}
      />
    </>
  );
};

export default ManageCampaignPage;