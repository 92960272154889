import React, { useEffect, useState } from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { motion } from 'framer-motion';
import axios from 'axios';
import { baseUrl } from '@/services/api-config';
import { toast } from 'react-toastify';
import { StepStatus } from '@/utils/Interfaces';
import { OnboardingPreviewData } from '../page_types/ad_content';

interface GoogleAdPreviewProps {
  url?: string;
  onStepComplete?: (status: StepStatus) => void;
}

const GoogleAdPreviewOverlay: React.FC<GoogleAdPreviewProps> = ({ url, onStepComplete }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [previewData, setPreviewData] = useState<OnboardingPreviewData | null>(null);
  const [currentAdIndex, setCurrentAdIndex] = useState(0);
  const [isDataReady, setIsDataReady] = useState(false);

  // Only notify that data is ready, don't auto-complete the step
  useEffect(() => {
    if (previewData && !isLoading && !isDataReady) {
      setIsDataReady(true);
      onStepComplete?.({
        isComplete: false,
        message: undefined
      });
    } else if (!previewData && !isDataReady) {
      onStepComplete?.({
        isComplete: false,
        message: 'Please wait while we analyze your website'
      });
    }
  }, [previewData, isLoading, isDataReady, onStepComplete]);

  const fetchPreviewData = async () => {
    if (!url) {
      toast.error('No URL provided');
      setIsLoading(false);
      return;
    }
  
    try {
      const { data } = await axios.get(`${baseUrl}/getSiteData`, {
        params: { url },
        headers: {
          'accept': 'application/json'
        }
      });
  
      const { descriptions, headlines, keywords, businessName } = data;
      setPreviewData({
        businessName,
        adHeadings: headlines,
        adDescriptions: descriptions,
        keywords: keywords,
      });
    } catch (error) {
      toast.error('Error analyzing website. Please try again.');
      setIsDataReady(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!url) return;
    setIsLoading(true);
    setIsDataReady(false);
    fetchPreviewData();
  }, [url]);

  if (isLoading || !previewData) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className="w-16 h-16 border-t-4 border-blue-500 border-solid rounded-full animate-spin" />
        </motion.div>
      </div>
    );
  }

  const handleNavigation = (direction: 'next' | 'prev') => {
    setCurrentAdIndex(prev => {
      if (direction === 'next') {
        return prev < (previewData.adHeadings.length - 1) ? prev + 1 : 0;
      }
      return prev > 0 ? prev - 1 : previewData.adHeadings.length - 1;
    });
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="w-full"
    >
      <h1 className="text-3xl font-semibold mb-3">
        Welcome to Multiply, {previewData.businessName}
      </h1>
      <p className="text-gray-600 text-lg mb-8">
        Multiply simplifies your advertising journey by automatically creating, managing, and
        optimizing your Google Ad campaigns. Let's take a look at what Multiply will do for you.
      </p>

      <div className="space-y-8">
        {/* Preview Section */}
        <Card className="w-full">
          <CardContent className="pt-8">
            <h2 className="text-2xl font-semibold mb-4">Preview your Google ads</h2>
            <p className="text-gray-600 text-lg mb-6">
              Here's a sneak peek of an ad that Multiply might run for your business. Multiply is trained on 
              top-performing ad campaigns to generate high-quality ad creative that’s designed to get you new customers.
              You can always add, remove, or modify creative through your personal control dashboard.
            </p>
            
            <div className="border rounded-lg p-8 space-y-4 bg-white shadow-sm">
              <p className="text-base text-gray-600 mb-2">Example Ad for {url}</p>
              <motion.div
                key={currentAdIndex}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.3 }}
                className="space-y-3"
              >
                <h3 className="text-xl text-blue-600 font-medium">
                  {previewData.adHeadings[currentAdIndex]}
                </h3>
                <p className="text-base text-gray-600">
                  {previewData.adDescriptions[currentAdIndex]}
                </p>
              </motion.div>

              <div className="flex items-center justify-between mt-6 pt-4 border-t">
                <span className="text-base text-gray-500">
                  Ad {currentAdIndex + 1} of {previewData.adHeadings.length}
                </span>
                <div className="flex gap-3">
                  <Button variant="outline" size="md" onClick={() => handleNavigation('prev')}>
                    <ChevronLeft className="h-5 w-5" />
                  </Button>
                  <Button variant="outline" size="md" onClick={() => handleNavigation('next')}>
                    <ChevronRight className="h-5 w-5" />
                  </Button>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        {/* Keywords Section */}
        <Card className="w-full">
          <CardContent className="pt-8">
            <h2 className="text-2xl font-semibold mb-4">Suggested Keywords</h2>
            <p className="text-gray-600 text-lg mb-6">
              These keywords will help your ads reach the right audience searching for your products or services.
            </p>
            <motion.div 
              className="flex flex-wrap gap-3"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              {previewData.keywords.map((keyword, index) => (
                <motion.span
                  key={index}
                  className="px-4 py-2 bg-green-50 text-green-700 rounded-full text-base"
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.3, delay: index * 0.05 }}
                >
                  {keyword}
                </motion.span>
              ))}
            </motion.div>
          </CardContent>
        </Card>
      </div>
    </motion.div>
  );
};

export default GoogleAdPreviewOverlay;