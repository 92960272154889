import React from 'react';
import { toast } from 'react-toastify';
import { MapPin, Target, AlertCircle, X, Trash2 } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { LocationType, LocationSelection, LocationData, selectionToLocationData } from '@/pages/page_types/location_data';
import AdLocationSelector from '@/components/AdLocationSelector';

// Shared interfaces
export interface LocationDisplayProps {
  locations: LocationSelection[];
  onRemoveLocation: (index: number) => void;
  className?: string;
}

export interface LocationTargetingTipsProps {
  className?: string;
}

export interface LocationTypesInfoProps {
  className?: string;
}

export interface LocationWarningProps {
  targetingType?: LocationType;
  className?: string;
}

export interface LocationSelectorSectionProps {
  onLocationSelect: (location: LocationSelection) => void;
  onAddLocation: (location: LocationSelection) => void;
  selectedLocation: LocationSelection | null;
  currentTargetingType?: LocationType;
  disabled?: boolean;
  className?: string;
}

// Shared components

// Component to display selected locations
export const LocationDisplay: React.FC<LocationDisplayProps> = ({ 
  locations, 
  onRemoveLocation,
  className = '' 
}) => {
  if (!locations || locations.length === 0) return null;

  return (
    <div className={`space-y-2 ${className}`}>
      {locations.map((location, index) => (
        <div 
          key={`${location.type}-${index}`}
          className="flex justify-between items-start p-4 bg-gray-50 rounded-lg border"
        >
          <div>
            <div className="font-medium">
              {location.type === 'LOCATION' 
                ? `Location Target: ${location.resource_name || location.canonical_name}` 
                : `Proximity Target: ${location.radius} miles radius`}
            </div>
            {location.coordinates && (
              <div className="text-sm text-gray-600">
                Coordinates: {location.coordinates.lat.toFixed(4)}, {location.coordinates.lng.toFixed(4)}
              </div>
            )}
          </div>
          <Button
            variant="ghost"
            size="sm"
            className="text-gray-500 hover:text-red-500"
            onClick={() => onRemoveLocation(index)}
          >
            <X className="h-4 w-4" />
          </Button>
        </div>
      ))}
    </div>
  );
};

// Component for location targeting tips
export const LocationTargetingTips: React.FC<LocationTargetingTipsProps> = ({ 
  className = '' 
}) => {
  return (
    <div className={`bg-gray-50 p-6 rounded-lg border ${className}`}>
      <h4 className="text-lg font-semibold text-gray-900 mb-3">Quick Tips</h4>
      <ul className="space-y-2 text-sm text-gray-600">
        <li className="flex items-start gap-2">
          <span className="h-1.5 w-1.5 rounded-full bg-blue-500 mt-2"></span>
          <span>Use specific location targeting when you want to target entire administrative areas like cities or states</span>
        </li>
        <li className="flex items-start gap-2">
          <span className="h-1.5 w-1.5 rounded-full bg-blue-500 mt-2"></span>
          <span>Use radius targeting when you have a physical location and want to reach people within a specific distance</span>
        </li>
        <li className="flex items-start gap-2">
          <span className="h-1.5 w-1.5 rounded-full bg-blue-500 mt-2"></span>
          <span>You can only use one targeting type at a time - either specific locations or radius targeting</span>
        </li>
      </ul>
    </div>
  );
};

// Component for explaining the two types of location targeting
export const LocationTypesInfo: React.FC<LocationTypesInfoProps> = ({ 
  className = '' 
}) => {
  return (
    <div className={`grid grid-cols-1 md:grid-cols-2 gap-6 ${className}`}>
      <div className="bg-white p-6 rounded-lg shadow-sm border">
        <div className="mb-4">
          <div className="flex items-center gap-2">
            <MapPin className="h-5 w-5 text-blue-500" />
            <h2 className="text-lg font-semibold">Target specific locations</h2>
          </div>
          <p className="text-sm text-gray-600 mt-2">
            Select specific areas to target your ads, including:
          </p>
        </div>
        <div className="mt-4">
          <ul className="space-y-2 text-sm">
            <li className="flex items-center gap-2">
              <span className="h-1.5 w-1.5 rounded-full bg-blue-500"></span>
              Zip/postal codes
            </li>
            <li className="flex items-center gap-2">
              <span className="h-1.5 w-1.5 rounded-full bg-blue-500"></span>
              Neighborhoods
            </li>
            <li className="flex items-center gap-2">
              <span className="h-1.5 w-1.5 rounded-full bg-blue-500"></span>
              Counties and cities
            </li>
            <li className="flex items-center gap-2">
              <span className="h-1.5 w-1.5 rounded-full bg-blue-500"></span>
              States and countries
            </li>
          </ul>
        </div>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-sm border">
        <div className="mb-4">
          <div className="flex items-center gap-2">
            <Target className="h-5 w-5 text-blue-500" />
            <h2 className="text-lg font-semibold">Target by radius</h2>
          </div>
          <p className="text-sm text-gray-600 mt-2">
            Target a specific area around a central point
          </p>
        </div>
        <div className="mt-4">
          <p className="text-sm text-gray-600">
            Define a radius around any location (e.g., 5 miles around Manhattan) to target potential customers in that area. Perfect for:
          </p>
          <ul className="mt-3 space-y-2 text-sm">
            <li className="flex items-center gap-2">
              <span className="h-1.5 w-1.5 rounded-full bg-blue-500"></span>
              Local businesses
            </li>
            <li className="flex items-center gap-2">
              <span className="h-1.5 w-1.5 rounded-full bg-blue-500"></span>
              Service areas
            </li>
            <li className="flex items-center gap-2">
              <span className="h-1.5 w-1.5 rounded-full bg-blue-500"></span>
              Event locations
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

// Component for displaying warning about mixing location types
export const LocationWarning: React.FC<LocationWarningProps> = ({ 
  targetingType,
  className = '' 
}) => {
  if (!targetingType) return null;

  return (
    <div className={`p-4 bg-blue-50 border border-blue-200 rounded-lg flex items-start ${className}`}>
      <AlertCircle className="h-5 w-5 text-blue-500 mt-0.5 mr-2" />
      <div>
        <h4 className="font-semibold text-blue-800">Important: Targeting Type Restriction</h4>
        <p className="text-blue-700">
          Your campaign is currently using {targetingType.toLowerCase()} targeting. 
          You'll need to remove existing targets to switch targeting types.
        </p>
      </div>
    </div>
  );
};

// Component for location selector with add button
export const LocationSelectorSection: React.FC<LocationSelectorSectionProps> = ({
  onLocationSelect,
  onAddLocation,
  selectedLocation,
  currentTargetingType,
  disabled = false,
  className = ''
}) => {
  const handleAddLocation = () => {
    if (!selectedLocation) {
      toast.error('Please select a location');
      return;
    }
    
    onAddLocation(selectedLocation);
  };

  return (
    <div className={`bg-white p-6 rounded-lg shadow-sm border ${className}`}>
      <AdLocationSelector 
        onLocationSelect={onLocationSelect}
        currentTargetingType={currentTargetingType}
        disabled={disabled}
      />
      
      {selectedLocation && (
        <div className="mt-4 flex justify-end">
          <Button
            onClick={handleAddLocation}
            className="bg-blue-500 hover:bg-blue-600 text-white"
            disabled={disabled}
          >
            Add Selected Location
          </Button>
        </div>
      )}
    </div>
  );
};

// Helper function to check if a location can be added based on existing locations
export const canAddLocation = (
  newLocation: LocationSelection,
  existingLocations: LocationSelection[]
): { canAdd: boolean; message?: string } => {
  if (existingLocations.length === 0) {
    return { canAdd: true };
  }
  
  const existingType = existingLocations[0].type;
  if (newLocation.type !== existingType) {
    return {
      canAdd: false,
      message: `Cannot mix ${newLocation.type} and ${existingType} targeting types`
    };
  }
  
  return { canAdd: true };
};