import { Session } from "@supabase/supabase-js";
import { useContext, useState, useEffect, createContext, useCallback, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import supabase from "../utils/supabase";
import { toast } from 'react-toastify';
import axios, { AxiosInstance } from "axios";
import { baseUrl } from "@/services/api-config";
import { ApiSignInResponse } from "@/types/api/CampaignTypes";
import { UserStatus } from "@/pages/page_types/user";
import { ProviderTokens } from "@/types/api/Auth";


function createAuthApiClient(
  signOutFunc: () => Promise<void>
): AxiosInstance {
  const apiClient = axios.create({
    baseURL: baseUrl
  });

  // Response interceptor
  apiClient.interceptors.response.use(
    (response) => response,
    async (error) => {
      console.log(JSON.stringify(error));
      if (axios.isAxiosError(error) && error.response) {
        // Check for the X-Logout-Required header in any response
        if (error.response.headers['X-Logout-Required'] === 'true') {
          // Show a message to the user
          toast.error('Your session has expired. Please sign in again.');
          
          // Logout the user
          await signOutFunc();
          
          // Redirect to login page (using window.location for a full page reload)
          window.location.href = '/login';
          
          // Return a rejected promise to stop the promise chain
          return Promise.reject(error);
        }
        
        // Also handle 307 redirects to homepage which might be auth-related
        if (error.response.status === 307 && error.response.headers['Location'] === '/') {
          // This is likely an auth redirect - check for the header just to be sure
          if (error.response.headers['X-Logout-Required'] === 'true') {
            toast.error('Your session has expired. Please sign in again.');
            await signOutFunc();
          }
          
          // Let the browser handle the redirect
          window.location.href = '/';
          return Promise.reject(error);
        }
      }
      // For other errors, just pass them through
      return Promise.reject(error);
    }
  );
  
  return apiClient;
}

// Define auth states to track the authentication process
export enum AuthState {
  LOADING = 'LOADING',
  AUTHENTICATED = 'AUTHENTICATED',
  UNAUTHENTICATED = 'UNAUTHENTICATED'
}

interface AuthContextType {
  session: Session | null | undefined;
  token: string | null | undefined;
  userStatus?: UserStatus;
  authState: AuthState;
  signIn: (input: { url: string }) => Promise<void>;
  signOut: () => Promise<void>;
  refreshSession: () => Promise<Session | null>;
  fetchUserStatus: () => Promise<void>;
  apiClient: AxiosInstance;
}

const AuthContext = createContext<AuthContextType>({
  session: null,
  token: null,
  userStatus: undefined,
  authState: AuthState.LOADING,
  signIn: async (input: { url: string }) => { },
  signOut: async () => { },
  refreshSession: async () => null,
  apiClient: axios.create(), // Default placeholder, will be replaced
  fetchUserStatus: async () => { }
});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [session, setSession] = useState<Session | null>();
  const [authState, setAuthState] = useState<AuthState>(AuthState.LOADING);
  const [userStatus, setUserStatus] = useState<UserStatus | undefined>(undefined);
  const navigate = useNavigate();

  // Create refs for functions that will be used by the API client
  const getSessionRef = useRef(() => session);
  
  // Update the ref whenever session changes
  useEffect(() => {
    getSessionRef.current = () => session;
  }, [session]);

  // Validate token expiry - pure function with no dependencies
  const isTokenValid = useCallback((token: string): boolean => {
    try {
      const [, payload] = token.split('.');
      if (!payload) return false;
      
      const tokenData = JSON.parse(atob(payload));
      const expirationTime = tokenData.exp * 1000;
      return Date.now() < expirationTime && !!tokenData.sub;
    } catch (error) {
      console.error('Token validation error:', error);
      return false;
    }
  }, []);

  // Handle sign out - depends only on navigate
  const handleSignOut = useCallback(async (message?: string) => {
    // Update state first for immediate UI feedback
    setAuthState(AuthState.UNAUTHENTICATED);
    setSession(null);
    setUserStatus(undefined);
    
    try {
      // Then perform the actual sign out
      const { error } = await supabase.auth.signOut();
      
      if (error) {
        console.error('Error signing out:', error);
      }
    } catch (error) {
      console.error('Exception during sign out:', error);
    }
    
    // Show message if provided
    if (message) {
      toast.error(message);
    }
    
    // Navigate after sign out
    navigate('/');
  }, [navigate]);

  // Refresh the user session - depends only on navigate
  const refreshSession = useCallback(async (): Promise<Session | null> => {
    try {
      // Get current session
      const { data: { session: currentSession } } = await supabase.auth.getSession();
      
      if (!currentSession?.refresh_token) {
        console.warn('No refresh token in current session');
        setAuthState(AuthState.UNAUTHENTICATED);
        setSession(null);
        setUserStatus(undefined);
        
        if (window.location.pathname !== '/') {
          toast.error('Session expired. Please sign in again.');
          navigate('/');
        }
        return null;
      }
  
      // Try to refresh the session
      const { data, error } = await supabase.auth.refreshSession({
        refresh_token: currentSession.refresh_token
      });
  
      if (error || !data.session) {
        console.error('Refresh error:', error);
        setAuthState(AuthState.UNAUTHENTICATED);
        setSession(null);
        setUserStatus(undefined);
        
        if (window.location.pathname !== '/') {
          toast.error('Your session has expired. Please sign in again.');
          navigate('/');
        }
        return null;
      }

      // Update session and auth state
      setSession(data.session);
      setAuthState(AuthState.AUTHENTICATED);
      
      return data.session;
    } catch (error) {
      console.error('Session refresh error:', error);
      setAuthState(AuthState.UNAUTHENTICATED);
      setSession(null);
      setUserStatus(undefined);
      
      if (window.location.pathname !== '/') {
        toast.error('Unable to refresh session. Please sign in again.');
        navigate('/');
      }
      return null;
    }
  }, [navigate]);

  // Memoize signOut function
  const signOut = useCallback(() => handleSignOut(), [handleSignOut]);

  // Create the API client with the functions it needs
  const apiClient = useMemo(() => 
    createAuthApiClient(
      signOut
    ),
  [refreshSession, signOut]);

  const fetchUserStatus = useCallback(async () => {
    if (!session?.access_token || !isTokenValid(session.access_token)) {
      console.log("No active session, can't fetch user status");
      await signOut();
      return;
    }
  
    try {
      console.log("Fetching user status with token");
      
      // Use the apiClient instead of direct axios to leverage interceptors
      const response = await apiClient.get<ApiSignInResponse>(`/getUserStatus`, {
        headers: {
          Authorization: `Bearer ${session.access_token}`,
          'Content-Type': 'application/json',
        }
      });
  
      console.log("User status response:", response.data);
  
      const newUserStatus: UserStatus = {
        userProfileId: response.data.user_profile_id,       
        oauth2Linked: response.data.oauth2_linked,
        accountLinked: response.data.account_linked,
      };
      
      setUserStatus(newUserStatus);
    } catch(error) {
      await signOut();
      navigate('/');
    }
  }, [session, apiClient, signOut, navigate]);

  // Save provider tokens and update user status
  const saveProviderTokens = useCallback(async (tokens: ProviderTokens, currentSession?: Session | null): Promise<UserStatus | null> => {
    try {
      // Use the passed session or the current state
      let sessionToUse = currentSession || session;
      
      // If no valid session, we can't proceed
      if (!sessionToUse?.access_token) {
        console.error('No valid session for API call');
        return null;
      }

      // Use the apiClient for API calls
      const response = await apiClient.post<ApiSignInResponse>(
        `/signIn`,
        {
          provider_access_token: tokens.providerAccessToken,
          provider_refresh_token: tokens.providerRefreshToken,
          expires_at: tokens.expiresAt,
          expires_in: tokens.expiresIn,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionToUse.access_token}`,
            'Content-Type': 'application/json',
          }
        }
      );
      
      const newUserStatus: UserStatus = {
        userProfileId: response.data.user_profile_id,       
        oauth2Linked: response.data.oauth2_linked,
        accountLinked: response.data.account_linked,
      };
      
      setUserStatus(newUserStatus);
      return newUserStatus;
    } catch (error) {
      console.error('Error saving provider tokens:', error);
      toast.error("Failed to authenticate with API");
      return null;
    }
  }, [session, apiClient]);

  // Sign in with Google OAuth
  const signIn = useCallback(async (input: { url: string }) => {
    try {
      const { error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: input.url,
          scopes: [
            'https://www.googleapis.com/auth/adwords', 
            'https://www.googleapis.com/auth/userinfo.email'
          ].join(' '),
          queryParams: {
            access_type: 'offline',
            prompt: 'consent',
          },
        },
      });
  
      if (error) {
        toast.error("Sign in error");
        console.error('Sign in error:', error);
        navigate('/');
      }
    } catch (error) {
      toast.error("Sign in error");
      console.error('Sign in error:', error);
      navigate('/');
    }
  }, [navigate]);

  // Initialize auth state on mount
  useEffect(() => {
    const initAuth = async () => {
      try {
        // Get initial session
        const { data: { session: initialSession } } = await supabase.auth.getSession();
        
        if (initialSession?.access_token) {
          if (!isTokenValid(initialSession.access_token)) {
            const refreshedSession = await refreshSession();
            
            // Handle provider tokens if we have a refreshed session
            if (refreshedSession && refreshedSession?.provider_token && refreshedSession.provider_refresh_token) {
              await saveProviderTokens({
                providerRefreshToken: refreshedSession.provider_refresh_token,
                providerAccessToken: refreshedSession.provider_token,
                expiresAt: refreshedSession.expires_at,
                expiresIn: refreshedSession.expires_in
              }, refreshedSession);
            }
          } else {
            setSession(initialSession);
            setAuthState(AuthState.AUTHENTICATED);
            
            // If we have provider tokens, get user status
            if (initialSession.provider_token && initialSession.provider_refresh_token) {
              await saveProviderTokens({
                providerRefreshToken: initialSession.provider_token,
                providerAccessToken: initialSession.access_token,
                expiresAt: initialSession.expires_at
              }, initialSession);
            }
          }
        } else {
          setAuthState(AuthState.UNAUTHENTICATED);
        }
      } catch (error) {
        console.error('Error initializing auth:', error);
        setAuthState(AuthState.UNAUTHENTICATED);
      }
    };

    initAuth();
    // We only want this to run once on mount, but need these dependencies for the function to work
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Listen for auth state changes
  useEffect(() => {
    const { data: listener } = supabase.auth.onAuthStateChange(
      async (event, currentSession) => {
        if (event === 'SIGNED_OUT') {
          setAuthState(AuthState.UNAUTHENTICATED);
          setSession(null);
          setUserStatus(undefined);
          return;
        }

        if (event === 'SIGNED_IN' && currentSession) {
          setSession(currentSession);
          setAuthState(AuthState.AUTHENTICATED);
          
          // Get user status if we have provider tokens
          if (currentSession.provider_token && currentSession.access_token && currentSession.provider_refresh_token) {
            await saveProviderTokens({
              providerAccessToken: currentSession.provider_token,
              providerRefreshToken: currentSession.provider_refresh_token,
              expiresAt: currentSession.expires_at
            }, currentSession);
          }
        } else if (event === 'TOKEN_REFRESHED' && currentSession) {
          setSession(currentSession);
          setAuthState(AuthState.AUTHENTICATED);
        }
      }
    );

    return () => {
      listener?.subscription.unsubscribe();
    };
  }, [saveProviderTokens]);

  // Memoize the context value to prevent unnecessary rerenders
  const value = useMemo(() => ({
    session,
    token: session?.access_token ?? null,
    userStatus,
    authState,
    signIn,
    signOut,
    refreshSession,
    fetchUserStatus,
    apiClient, // Now include the apiClient in the context
  }), [
    session,
    userStatus,
    authState,
    signIn,
    signOut,
    refreshSession,
    fetchUserStatus,
    apiClient
  ]);

  return (
    <AuthContext.Provider value={value}>
      {authState !== AuthState.LOADING ? children : null}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};